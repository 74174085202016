<div class="container" style="padding: 0; height: 100%;">

    <div class="card" style="height: 100%; max-height: 100%; overflow: hidden;">
        <div class="row" style="margin: 0px; padding-top: 50px; height: 100%; max-height: 100%;">

            <!--Lijeva kolona-->
            <div class="d-none d-md-block col-4 card" style="margin-top: 15px; padding: 16px 15px; height: 100%;">

                <app-obavijesti-sidebar></app-obavijesti-sidebar>

            </div>

            <!-- Desna kolona -->
            <div class="col" style="margin-top: 15px; padding: 0; height: 100%; max-height: 100%;">

                <div class="d-flex flex-column" style="max-height: 100%; overflow-x: hidden;">
                
                    <div>

                        <app-pos-jed-hamburger></app-pos-jed-hamburger>
    
                    </div>

                    <div class="flex-grow-1" style="overflow-y: scroll; margin-right: -15px; background-color: white;">

                        <div *ngIf="srvObavijesti.intVrsta == 1" class="card" style="margin: 5px; padding: 5px;">

                            <div style="border-radius: 5px; background-color: #d5f7d5; margin-bottom: 10px; padding: 5px; text-align: center;">
                                <div *ngIf="info">
                                    {{info.neprocitano + '/' + info.ukupno}}
                                </div>
                                <div *ngIf="!srvObavijesti.objVobavRez">
                                    Nemate obavijesti
                                </div>
        
                            </div>
        
                            <div class="row" *ngFor="let x of srvObavijesti.objVobavRez; let i = index" style="border-bottom: solid 0.5px #af9595; padding: 5px 0px; cursor: pointer;" [ngStyle]="{'color': x.vocStatus == 0 ? 'black' : '#94949f'}">
                                <div class="col col-7" style="padding: 0px 5px;" (click)="modalObavijest(x, i)">
                                    <div style="font-size: 1.1rem; font-weight: bold;">
                                        {{x.Ime + ' ' + x.Prezime}}
                                    </div>
                                    <div>
                                        {{x.Naziv}}
                                    </div>
                                </div>
                                <div class="col col-3 d-flex align-items-center" style="padding: 0;" (click)="modalObavijest(x, i)">
                                    <div>
                                        {{x.VrijemeObv | amDateFormat:'DD.MM.YY. HH:mm'}}
                                    </div>
                                </div>
                                <div class="col col-2 d-flex justify-content-center align-items-center" [matMenuTriggerFor]="menu" style="padding: 0; font-size: 2rem;" (click)="obvMenuClick(x)">
                                    <!-- <i class="fa fa-bars" aria-hidden="true"></i> -->
                                    <i class="fa fa-cog" aria-hidden="true"></i>
                                    <mat-menu #menu="matMenu">
                                        <button mat-menu-item style="font-size: 1.2rem;">
                                            <i class="fa fa-external-link" aria-hidden="true"></i>
                                            <span style="margin-left: 10px;">Prikaži obavijest</span>
                                        </button>
                                        <button *ngIf="x.vocStatus == 1" mat-menu-item style="font-size: 1.2rem;" (click)="oznNeprocitano(x,i)">
                                            <i class="fa fa-undo" aria-hidden="true"></i>
                                            <span style="margin-left: 10px;">Označi kao nepročitano</span>
                                        </button>
                                        <button *ngIf="x.vocStatus == 0" mat-menu-item style="font-size: 1.2rem;" (click)="oznProcitano(x,i)">
                                            <i class="fa fa-undo" aria-hidden="true"></i>
                                            <span style="margin-left: 10px;">Označi kao pročitano</span>
                                        </button>
                                        <button mat-menu-item style="font-size: 1.2rem;">
                                            <i class="fa fa-trash-o" aria-hidden="true"></i>
                                            <span style="margin-left: 10px;">Obriši</span>
                                        </button>
                                    </mat-menu>
                                </div>
                            </div>
        
        
        
                        </div>
        
                        <div *ngIf="srvObavijesti.intVrsta == 2" class="card">
        
                            <div *ngFor="let x of srvObavijesti.objSent" style="border-bottom: solid 0.5px #d7d7d7;">
        
                                <div class="row" style="background-color: #e5e5e5; padding: 5px; border-radius: 5px; cursor: pointer;" (click)="obvDelID = 0; ObavijestID && ObavijestID == x.ObavijestID ? ObavijestID = 0 : ObavijestID = x.ObavijestID">
        
                                    <div class="col col-2 col-md-1 d-flex justify-content-center align-items-center" style="padding: 0; font-size: 2rem; color: #384c5a;">
                                        <i *ngIf="ObavijestID != x.ObavijestID" class="fa fa-caret-right" aria-hidden="true"></i>
                                        <i *ngIf="ObavijestID == x.ObavijestID" class="fa fa-caret-down" aria-hidden="true"></i>
                                    </div>
        
                                    <div class="col" style="padding: 0;">
                                        <div class="d-flex flex-column" style="color: #384c5a;">
        
                                            <div>
                                                {{x.VrijemeObv | amDateFormat:'DD.MM.YY HH:mm'}}
                                            </div>
                                            <div>
                                                {{x.Naziv + (x.primatelji ? ' ' + x.primatelji : '')}}
                                            </div>
                                            <!-- <div style="margin-left: 5px;">
                                                {{x.primatelji}}
                                            </div> -->
                                        </div>
                                    </div>
        
                                    <!-- <div class="col col-3 d-flex justify-content-center align-items-center" style="padding: 0;">
                                        <div class="mybtn-danger w-100" style="font-size: 1rem;">
                                            <i class="fa fa-trash" aria-hidden="true"></i> Obriši
                                        </div>
                                    </div> -->
        
                                </div>
        
        
        
                                <div *ngIf="ObavijestID == x.ObavijestID" style="margin-bottom: 5px; padding: 5px; background-color: #f8feff;">
                                    <div style="white-space: pre-line; padding: 5px;">
                                        {{x.TekstObv}}
                                    </div>
                                    <div *ngIf="obvDelID != x.ObavijestID" class="row">
                                        <div class="col" style="padding: 0 2px 0 0;" (click)="ObavijestID = 0;">
                                            <div class="btn mybtn-cancel w-100">Zatvori</div>
                                        </div>
                                        <div class="col" style="padding: 0 0 0 2px;" (click)="obvDelID = x.ObavijestID">
                                            <div class="btn mybtn-danger w-100">Obriši</div>
                                        </div>
                                    </div>
        
                                    <div *ngIf="obvDelID == x.ObavijestID" class="row">
                                        <div class="col" style="padding: 0 2px 0 0;">
                                            <div class="btn mybtn-danger w-100">Potvrdi brisanje</div>
                                        </div>
                                        <div class="col" style="padding: 0 0 0 2px;" (click)="obvDelID = 0;">
                                            <div class="btn mybtn-cancel w-100">Otkaži</div>
                                        </div>
                                    </div>
                                </div>
        
                            </div>
        
                        </div>
                    
                    </div>
                
                </div>

            

            </div>

        </div>
    </div>



</div>

<!--SIDEBAR-->
<button hidden="true" id="btnSidebar" data-toggle="modal" data-target="#modalPrisutni"></button>

<div class="modal left fade" id="modalPrisutni" tabindex="-1" role="dialog" aria-labelledby="modalPrisutniLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">

            <div class="modal-header posjed-mod-header d-flex justify-content-center">
                Obavijesti
            </div>

            <div class="modal-body posjed-mod-body">

                <app-obavijesti-sidebar></app-obavijesti-sidebar>

            </div>

            <div class="modal-footer bgs-dark">
                <button type="button" id="btnSidebarClose" class="btn btn-outline-secondary" data-dismiss="modal" style="width: 100%;">Zatvori</button>
            </div>
        </div>
    </div>
</div>

<!-- Modal nova obavijest -->
<button hidden id="btnMdlNovaObavijest" type="button" data-toggle="modal" data-target="#mdlNovaObavijest">
    Launch demo modal
</button>

<div class="modal fade" id="mdlNovaObavijest" tabindex="-1" role="dialog" aria-labelledby="mdlNovaObavijestLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content" style="border-radius: 10px;">

            <div class="modal-header" style="padding: 10px;">
                <h6 class="modal-title" id="mdlNovaObavijestLabel">Nova obavijest</h6>
                <button type="button" class="close" data-dismiss="modal" aria-label="Zatvori">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body" style="padding: 0px">
                <app-obavijest-send *ngIf="mod.objKor"></app-obavijest-send>
            </div>

            <div class="modal-footer">
                <button id="btnMdlNovaObavijestClose" type="button" class="btn mybtn-cancel w-100" data-dismiss="modal">Zatvori</button>
            </div>
        </div>
    </div>
</div>

<!-- Modal Obavijest selected -->
<button hidden id="btnMdlObvSel" type="button" data-toggle="modal" data-target="#mdlObvSel">
    Modal odabrana obavijest
</button>

<div class="modal fade" id="mdlObvSel" tabindex="-1" role="dialog" aria-labelledby="mdlObvSel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div *ngIf="objVobavRezSel" class="modal-content" style="border-radius: 10px;">

            <div class="modal-header" style="padding: 10px;">
                <div>
                    {{objVobavRezSel.Ime + ' ' + objVobavRezSel.Prezime}}
                </div>

                <div class="ml-auto">
                    {{objVobavRezSel.VrijemeObv | amDateFormat:'DD.MM.YY. HH:mm'}}
                </div>
                <button type="button" class="close" data-dismiss="modal" aria-label="Zatvori">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body" style="padding: 12px; white-space: pre-line;">

                {{objVobavRezSel.TekstObv}}

            </div>

            <div class="modal-footer">
                <button id="btnObSelClose" type="button" class="btn mybtn-cancel w-100" data-dismiss="modal">Zatvori</button>
            </div>
        </div>
    </div>
</div>

<!-- Modal nova pozivnica -->
<button hidden id="btnMdlNovaPozivnica" type="button" data-toggle="modal" data-target="#mdlNovaPozivnica">
    Launch demo modal
</button>

<div class="modal fade" id="mdlNovaPozivnica" tabindex="-1" role="dialog" aria-labelledby="mdlNovaPozivnica" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content" style="border-radius: 10px;">

            <div class="modal-header" style="padding: 10px;">
                <h6 class="modal-title" id="mdlNovaPozivnicaLabel">Nova pozivnica u grupu</h6>
                <button type="button" class="close" data-dismiss="modal" aria-label="Zatvori">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body" style="padding: 5px 10px">
                <app-pozivnica *ngIf="mod.objKor"></app-pozivnica>
            </div>

            <div class="modal-footer">
                <button id="btnMdlNovaPozivnicaClose" type="button" class="btn mybtn-cancel w-100" data-dismiss="modal">Zatvori</button>
            </div>
        </div>
    </div>
</div>