<div class="container" style="padding: 0; padding-top: 50px; height: 100%;">

    <div class="row" style="height: 100%; max-height: 100%;">

        <!--Lijeva kolona-->
        <div class="col-lg-4 col-md-4 d-none d-md-block card"
            style="margin: 0px !important; padding-bottom: 30px; overflow: hidden; max-height: 100%; min-width: 300px; opacity: 0.9; padding-top: 15px;">

            <app-clan-edit-sidebar></app-clan-edit-sidebar>

        </div>

        <div class="col" style="padding: 0px 0px 0px 0px; max-height: 100%;">

            <div class="d-flex flex-column" style="max-height: 100%; overflow-x: hidden;">

                <div>

                    <app-pos-jed-hamburger></app-pos-jed-hamburger>

                </div>


                <div class="flex-grow-1 card" style="overflow-y: scroll; overflow-x: hidden; scrollbar-width: none; opacity: 0.9;">

                    <div>

                        <h6 style="text-align: center; margin-top: 10px;">Opći podaci</h6>

                        <div class="card clan-edit" style="padding: 10px 5px;">

                            <form *ngIf="srvClan.frmClan" [formGroup]="srvClan.frmClan">

                                <div class="row row-edit" style="margin: 0px;">



                                    <!-- <div class="form-group"> -->

                                    <div class="col">
                                        <!--Ime-->
                                        <mat-form-field>
                                            <mat-label>Ime</mat-label>
                                            <input autocomplete="off" matInput type="text" formControlName="Ime"
                                                placeholder="Ime" required>
                                        </mat-form-field>
                                    </div>

                                    <div class="col">
                                        <!--Prezime-->
                                        <mat-form-field>
                                            <mat-label>Prezime</mat-label>
                                            <input autocomplete="off" matInput type="text" formControlName="Prezime"
                                                placeholder="Prezime">
                                        </mat-form-field>
                                    </div>

                                    <div class="col">
                                        <!--ADRESA I KUĆNI BROJ-->
                                        <mat-form-field>
                                            <mat-label>Adresa</mat-label>
                                            <input autocomplete="off" matInput type="text" formControlName="Adresa"
                                                placeholder="Adresa">
                                        </mat-form-field>
                                    </div>

                                    <div class="col">
                                        <!-- MJESTO -->
                                        <mat-form-field>
                                            <mat-label>Mjesto</mat-label>
                                            <input type="text" placeholder="Mjesto" aria-label="Mjesto" matInput
                                                formControlName="Mjesto" [matAutocomplete]="auto">
                                            <mat-autocomplete #auto="matAutocomplete">
                                                <mat-option *ngFor="let x of srvMjesta.fltMjesta" [value]="x.Mjesto"
                                                    (onSelectionChange)="postaviMjestoID(x.Mjesto_ID)">
                                                    {{x.Mjesto}}
                                                </mat-option>
                                            </mat-autocomplete>
                                        </mat-form-field>
                                    </div>

                                    <div class="col">
                                        <!--Tel-->
                                        <mat-form-field>
                                            <mat-label>tel</mat-label>
                                            <input autocomplete="off" matInput type="text" formControlName="tel"
                                                placeholder="tel">
                                        </mat-form-field>
                                    </div>

                                    <div class="col">
                                        <!--e-mail-->
                                        <mat-form-field>
                                            <mat-label>e mail</mat-label>
                                            <input autocomplete="off" matInput type="text" formControlName="e_mail"
                                                placeholder="e mail">
                                        </mat-form-field>
                                    </div>

                                    <div class="col">
                                        <!--OIB-->
                                        <mat-form-field>
                                            <mat-label>OIB</mat-label>
                                            <input autocomplete="off" matInput type="OIB" formControlName="OIB"
                                                placeholder="OIB">
                                            <mat-error *ngIf="srvClan.frmClan.controls['OIB'].errors?.err">
                                                {{srvClan.frmClan.controls['OIB'].errors.err}}</mat-error>
                                        </mat-form-field>
                                    </div>

                                    <!--  </div> -->
                                    <!-- <div class="col">
                                    <div style="min-width: 250px;"></div>
                                </div>
                
                                <div class="col">
                                    <div style="min-width: 250px;"></div>
                                </div> -->


                                </div>

                            </form>


                            <form *ngIf="srvClan.frmClanstvo && srvClan.clanID == 0" [formGroup]="srvClan.frmClanstvo">

                                <div class="row" style="margin: 0px;">

                                    <!--lista Članstava-->
                                    <div class="col">
                                        <mat-form-field>
                                            <mat-label>Odaberite vrstu članstva</mat-label>
                                            <mat-select formControlName="vrstaIDcl">
                                                <mat-option *ngFor="let x of srvClan.lstClanstva"
                                                    (click)="postaviClanstvoIDfrm(x.vID)"
                                                    [value]="x.vID">{{x.opis_vrsta_cl}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                    <!--DatePicker Datum od Članstvo-->
                                    <div class="col">
                                        <mat-form-field style="margin-right: 15px;">
                                            <input matInput formControlName="PocetakDatum" [max]="danas"
                                                [matDatepicker]="picker" placeholder="Datum učlanjenja">
                                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                            <mat-datepicker touchUi #picker></mat-datepicker>
                                        </mat-form-field>
                                    </div>

                                    <!--Poslovne jedinice-->
                                    <div class="col">
                                        <mat-form-field>
                                            <mat-label>Odaberite poslovnu jedinicu</mat-label>
                                            <mat-select formControlName="pjedIDcl">
                                                <mat-option (click)="postaviPosJed(0)" [value]="0">Sve poslovne
                                                    jedinice</mat-option>
                                                <mat-option *ngFor="let x of objPosJed"
                                                    (click)="postaviPosJed(x.PoslJedID)"
                                                    [value]="x.PoslJedID">{{x.NazivPos}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                    <div class="col">
                                        <div style="min-width: 250px;"></div>
                                    </div>

                                </div>

                            </form>

                            <!--Spremi-->
                            <div class="row" style="margin-bottom: 10px;">
                                <div class="col" style="min-width: 200px; padding: 0;">

                                </div>
                                <div class="col" style="min-width: 200px; padding: 0;">

                                </div>
                                <!-- <div class="col d-flex mybtn-success justify-content-center" (click)="btnSpremi(srvClan.clanID)" style="min-width: 250px;">
                                <i class="material-icons" style="color: white;">save</i>
                                <span style="margin-left: 5px; padding-top: 2px; color: white; font-size: 1.1rem;">SPREMI</span>
                            </div> -->
                                <div class="col col-btn d-flex mybtn-success justify-content-center align-items-center"
                                    (click)="btnSpremi(srvClan.clanID)" style="min-width: 250px;">

                                    <i class="material-icons" style="color: white;">save</i>
                                    <span
                                        style="margin-left: 5px; padding-top: 2px; color: white; font-size: 1.1rem;">SPREMI</span>

                                </div>

                            </div>




                        </div>


                        <div *ngIf="srvClan.clanID != 0" class="row" style="margin: 0;">

                            <div class="col col">
                                <h6 style="margin-top: 15px; text-align: center;">Članstva</h6>
                            </div>
                            <!-- <div class="col col-md-6 col-lg-4" style="padding: 5px 15px 5px 10px;">
                            
                        </div> -->

                        </div>

                        <div class="row">

                            <div class="col" *ngFor="let x of srvClan.objClanstva"
                                style="padding:0px; min-width: 300px;">


                                <div class="card col-clanstva" style="width: 100%;">
                                    <div class="row" style="margin: 0px; padding-top:5px; padding-bottom: 5px;">

                                        <div class="col-3 d-flex" style="align-items: center;">
                                            <i class="material-icons cp-icon"
                                                style="font-size: 3rem; color:rgb(117, 116, 116);">{{iconClanstva(x.vrstaIDcl)}}</i>
                                        </div>

                                        <div class="col-7" style="padding: 0px;">
                                            <span class="tekst" style="margin: 2px;">{{x.opis_vrsta_cl}}</span><br />
                                            <span class="naslov" style="margin: 2px;">{{x.NazivPos ? x.NazivPos : 'Sve poslovne jedinice'}}</span><br />
                                            <span class="tekst" style="margin: 2px;">{{x.PocetakDatum | amDateFormat:
                                                'DD.MM.YYYY.'}}</span>
                                        </div>

                                        <div class="col-2 d-flex flex-column justify-content-center align-items-center"
                                            style="padding: 0px; cursor: pointer;" (click)="clanstEditStart(x)">
                                            <i class="material-icons cp-icon"
                                                style="font-size: 2rem; height: 32px;">edit</i>
                                            <span style="font-size: 0.9rem;">Uredi</span>
                                        </div>

                                    </div>

                                </div>

                            </div>

                            <div class="col" style="padding: 0px; min-width: 300px;"></div>
                            <div class="col" style="padding: 0px; min-width: 300px;"></div>

                            <!-- <div class="col">
                            <button type="button" class="btn btn-outline-dark" style="min-width: 250px; width: 100%;">SPREMI</button>
                        </div> -->

                        </div>

                        <div class="card clan-edit" style="padding: 10px 5px; margin-bottom: 10px;">
                            <div class="row">
                                <div class="col" style="min-width: 200px; padding: 0;">

                                </div>
                                <div class="col" style="min-width: 200px; padding: 0;">

                                </div>
                                <div *ngIf="srvClan.clanID != 0"
                                    class="col col-btn d-flex mybtn-success justify-content-center align-items-center"
                                    style="min-width: 250px;" (click)="novoClanstvo()">
                                    <i class="material-icons" style="color: white;">add</i>
                                    <span
                                        style="margin-left: 5px; padding-top: 2px; color: white; font-size: 1.1rem;">Dodaj
                                        članstvo</span>
                                    <!-- <button type="button" class="btn mybtn-success w-100" style="min-width: 250px; font-size: 1.1rem;">
                                    <i class="fa fa-plus-circle" style="margin-right: 5px;" aria-hidden="true"></i> Dodaj članstvo</button> -->
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </div>

    </div>



</div>

<!--SIDEBAR-->
<button hidden="true" id="btnSidebar" data-toggle="modal" data-target="#modalPrisutni"></button>

<div class="modal left fade" id="modalPrisutni" tabindex="-1" role="dialog" aria-labelledby="modalPrisutniLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document" style="font-size: 1.2rem;">
        <div class="modal-content">

            <div class="modal-header posjed-mod-header d-flex justify-content-center">

            </div>

            <div class="modal-body" style="padding: 5px;">

                <app-clan-edit-sidebar></app-clan-edit-sidebar>

            </div>
            <div class="modal-footer bgs-dark" style="padding: 5px;">
                <button type="button" id="btnSidebarClose" class="btn mybtn-cancel" data-dismiss="modal"
                    style="width: 100%;">Zatvori</button>
            </div>
        </div>
    </div>
</div>

<!-- MODAL uređivanje članstva -->
<button hidden id="btnMdlClanstEdit" data-toggle="modal" data-target="#mdlClanstEdit"></button>
<div class="modal fade" id="mdlClanstEdit" tabindex="-1" role="dialog" aria-labelledby="mdlClanstEdit"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content" style="font-size: 1.2rem; border-radius: 10px;">
            <div class="modal-header">
                <h6 class="modal-title">{{srvClan.frmClanstvo && srvClan.frmClanstvo.get('vClansID').value ? 'Uređivanje članstva' : 'Novo članstvo'}}</h6>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body w-100" style="padding: 0;">

                <div>
                    <form
                        *ngIf="srvClan.frmClanstvo && (srvClan.frmClanstvo.get('vClansID').value || (!srvClan.frmClanstvo.get('vClansID').value && objClPostavke))"
                        [formGroup]="srvClan.frmClanstvo">

                        <div class="row" style="margin: 0; padding-top: 10px;">

                            <div *ngIf="!srvClan.frmClanstvo.get('vClansID').value" class="col col-12">
                                <!--lista Članstava-->
                                <mat-form-field style="width: 100%;">
                                    <mat-label>Odaberite vrstu članstva</mat-label>
                                    <mat-select formControlName="vrstaIDcl">
                                        <mat-option *ngFor="let x of objClPostavke" (click)="ncPosJedOdabir(x.posJed)"
                                            [value]="x.vID">{{x.opis_vrsta_cl}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <!--DatePicker Datum od Članstvo-->
                            <div class="col col-6">
                                <mat-form-field style="min-width: 150px; width: 100%; text-align: center;">
                                    <input matInput formControlName="PocetakDatum" [max]="danas"
                                        [matDatepicker]="picker" placeholder="Datum učlanjenja">
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker touchUi #picker></mat-datepicker>
                                </mat-form-field>
                            </div>

                            <!--DatePicker Datum do Članstvo-->
                            <div class="col col-6">
                                <mat-form-field style="min-width: 150px; width: 100%; text-align: center;">
                                    <input matInput formControlName="KrajDatum" [matDatepicker]="picker1"
                                        placeholder="Datum ispisa">
                                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                    <mat-datepicker touchUi #picker1></mat-datepicker>
                                </mat-form-field>
                            </div>

                            <!--Poslovne jedinice-->
                            <div class="col">
                                <mat-form-field style="width: 100%;">
                                    <mat-label>Odaberite poslovnu jedinicu</mat-label>
                                    <mat-select formControlName="pjedIDcl">
                                        <mat-option (click)="postaviPosJed('0')" [value]="'0'">Sve poslovne
                                            jedinice</mat-option>
                                        <mat-option
                                            *ngFor="let x of srvClan.frmClanstvo.get('vClansID').value ? objPosJed : objClPosJedSel"
                                            (click)="postaviPosJed(x.PoslJedID)"
                                            [value]="x.PoslJedID">{{x.NazivPos}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <div class="col col-12 d-flex justify-content-center align-items-center"
                                style="cursor: pointer; font-size: 1.2rem;" (click)="clAktChk()">
                                <i *ngIf="this.srvClan.frmClanstvo.get('intAkt').value == 0" class="fa fa-square-o"
                                    aria-hidden="true"> </i>
                                <i *ngIf="this.srvClan.frmClanstvo.get('intAkt').value != 0"
                                    class="fa fa-check-square-o" aria-hidden="true"> </i>
                                <span style="margin-left: 5px;">Aktivno</span>
                            </div>

                        </div>

                    </form>
                </div>

                <div *ngIf="srvClan.frmClanstvo && !srvClan.frmClanstvo.get('vClansID').value && !objClPostavke"
                    class="alert alert-info" style="text-align: center;">
                    Nema dostupnih članstava
                </div>

            </div>
            <div class="modal-footer d-block" style="padding: 5px;">
                <div class="row" style="margin: 0;">
                    <div *ngIf="srvClan.frmClanstvo && (srvClan.frmClanstvo.get('vClansID').value || (!srvClan.frmClanstvo.get('vClansID').value && objClPostavke))"
                        class="col col-6" style="padding: 0 1px;">
                        <button type="button" class="btn mybtn-success w-100" style="font-size: 1.2rem;"
                            (click)="spremiClanstvo()">Spremi
                            promjene</button>
                    </div>
                    <div class="col" style="padding: 0 1px;">
                        <button id="btnMdlClanstEditClose" type="button" class="btn mybtn-cancel w-100"
                            style="font-size: 1.2rem;" data-dismiss="modal">Zatvori</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>