<div class="container" style="padding: 0px; height: 100%;">

    <div class="row" style="margin: 0px !important; padding-top: 50px; height: 100%; max-height: 100%;">

        <!-- <button type="button" class="btn btn-primary" (click)="windowSizeTest()">Test screen (x,y)</button> -->

        <!--Lijeva kolona-->
        <div class="col-md-4 d-none d-md-block" style="margin: 0px !important;">

            <div class="lco bgs-dark" style="padding: 5px;">



                <!--Back button -> Grupe-->
                <!-- <div class="card" routerLink="/grupe">

                    <div class="row" style="margin: 0px; padding: 5px 2px 5px 2px; border-radius: 15px;">

                        <div class="col-2 d-flex justify-content-center align-items-center">
                            <i class="material-icons left-icon">
                            keyboard_backspace
                        </i>
                        </div>

                        <div class="col d-flex flex-column" style="align-items: left;">
                            <span class="left-h">Grupe</span>
                            <span class="left-p">povratak na listu grupa</span>
                        </div>

                    </div>

                </div> -->
                <!--Back button-->
                <div class="btn mybtn-cancel w-100" style="margin-bottom: 5px; font-size: 1.3rem;" routerLink="/grupe">
                    <i class="material-icons">keyboard_backspace</i><span style="margin-left: 3px;">Povratak na
                        grupe</span>
                </div>

                <!-- Današnji termini-->
                <div class="card card-btn-sidebar">

                    <div style="padding: 5px 5px; color: #647264; border-bottom: solid #dfdfdf 0.5px;">
                        <div *ngIf="objDanasnjiTermini">
                            Današnji termini
                        </div>
                        <div *ngIf="!objDanasnjiTermini" style="text-align: center;">
                            Nema zakazanih termina za danas
                        </div>
                    </div>

                    <div *ngFor="let x of objDanasnjiTermini"
                        style="padding: 0 5px; border-bottom: solid #dfdfdf 0.5px;"
                        [ngStyle]="{'color': aktivnosColor(x.PocetakAkt, x.KrajAkt, x.statusAkt)}">

                        <div class="row" style="margin: 0; padding: 0 5px;">
                            <div class="col d-flex align-items-center" style="padding: 0;">
                                <div>
                                    <div>
                                        {{x.PocetakAkt | amDateFormat:'HH:mm'}} - {{x.KrajAkt | amDateFormat:'HH:mm'}}
                                    </div>
                                    <div>
                                        {{ x.opisDvo }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-3 d-flex justify-content-center align-items-center" style="padding: 2px;"
                                (click)="urediAktivnost(x)">
                                <i class="material-icons" style="font-size: 2.2rem;">
                                    create
                                </i>
                            </div>
                            <div class="col-3 d-flex justify-content-center align-items-center" style="padding: 0 5px;"
                                (click)="clanoviLoginStart(x)">
                                <i class="material-icons" style="font-size: 2.7rem;">
                                    people
                                </i>
                            </div>
                        </div>

                        <div class="d-flex" style="padding: 0 5px;">
                            <div *ngFor="let y of x.treneri">
                                <div style="margin-right: 10px;">
                                    <!-- <i style="color: green;">*</i> -->
                                    {{y.Ime + ' ' + y.Prezime}}
                                </div>

                            </div>
                        </div>

                    </div>

                </div>

                <!--Novi termin-->
                <div class="card card-btn-sidebar" (click)="noviTermin()">

                    <div class="row" style="margin: 0px; padding: 5px 2px 5px 2px;">

                        <div class="col-2 d-flex justify-content-center align-items-center">
                            <i class="material-icons left-icon">add</i>
                        </div>

                        <div class="col d-flex flex-column" style="align-items: left;">
                            <span class="left-h">Novi termin</span>
                            <span class="left-p">unos novog termina u kalendar</span>
                        </div>

                    </div>
                </div>

                <!-- <div class="btns-blue" style="margin-bottom: 5px;" data-toggle="modal" data-target="#modalNovaAktivnost">
                    <i class="material-icons">
                        insert_invitation
                    </i><span style="margin-left: 3px;"> Unos termina</span>
                </div> -->

                <!--Login člana-->
                <!-- <div class="card card-btn-sidebar">

                    <div class="row" style="margin: 0px;">

                        <div class="col-2 d-flex justify-content-center align-items-center">
                            <i class="material-icons left-icon">
                                input
                            </i>
                        </div>

                        <div class="col d-flex flex-column" style="align-items: left;">
                            <span class="left-h">Prijava člana</span>
                            <span class="left-p">prijavite dolazak članova u evidenciju posjeta</span>
                        </div>

                    </div>
                </div> -->

                <!-- <div class="btns-blue" style="margin-bottom: 5px;">
                    <i class="material-icons">
                        input
                    </i><span style="margin-left: 3px;">Prijava člana</span>
                </div> -->

                <!--Novi član-->
                <div class="card card-btn-sidebar" (click)="slobodniClanovi()">

                    <div class="row" style="margin: 0px;">

                        <div class="col-2 d-flex justify-content-center align-items-center">
                            <i class="material-icons left-icon">person_add</i>
                        </div>

                        <div class="col d-flex flex-column" style="align-items: left;">
                            <span class="left-h">Novi član</span>
                            <span class="left-p">dodajte novog polaznika u grupu</span>
                        </div>

                    </div>
                </div>

                <!-- <div class="btns-blue" style="margin-bottom: 5px;" (click)="slobodniClanovi()">
                    <i class="material-icons">
                        person_add
                    </i> <span style="margin-left: 3px;">Unos novog člana</span>
                </div> -->

                <!--Kalendar-->
                <div class="card mat-kal-grp" style="padding: 10px; margin-bottom: 5px;">
                    <app-kalendar (selectedDate)="odabranDatum($event)"></app-kalendar>
                </div>

            </div>

        </div>

        <div class="col" style="padding: 0px; max-height: 100%;">

            <div class="d-flex flex-column" style="max-height: 100%; overflow-x: hidden;">

                <div>

                    <div class="hamb-card">

                        <div *ngIf="!frmNovaGrupa" class="row" style="font-size: 1.3px; padding: 7px 2px;">

                            <div class="d-flex col col-2 hamb justify-content-center align-items-center d-md-none"
                                style="padding: 0;" data-toggle="modal" data-target="#modalSidebar">
                                <i class="material-icons hamb-btn">menu</i>
                            </div>

                            <div *ngIf="srvGrupe.objGrupa" class="col d-flex justify-content-center hamb-col-10"
                                (click)="grupaEdit()">
                                <span style="font-size: 1.2rem;">{{srvGrupe.objGrupa.grpOpis}}</span>
                                <i class="fa fa-pencil" style="margin-left: 15px; color: orange;"
                                    aria-hidden="true"></i>
                            </div>

                        </div>

                        <div *ngIf="frmNovaGrupa" class="d-flex justify-content-end"
                            style="font-size: 1.5rem; padding: 10px; color: #ed6e6e;" (click)="frmNovaGrupa = null;">
                            <i class="fa fa-window-close" aria-hidden="true"></i>
                        </div>

                        <div *ngIf="frmNovaGrupa">

                            <form [formGroup]="frmNovaGrupa">

                                <div class="row input-slim">

                                    <div class="col-12">
                                        <mat-form-field style="width: 100%;">
                                            <mat-label>Naziv</mat-label>
                                            <input autocomplete="off" matInput type="text" formControlName="grpOpis"
                                                placeholder="Naziv" required>
                                            <mat-error
                                                *ngIf="frmNovaGrupa.controls['grpOpis'].touched && frmNovaGrupa.controls['grpOpis'].errors">
                                                Neispravan unos</mat-error>
                                        </mat-form-field>
                                    </div>

                                    <!-- <div class="col-12">
                                        <mat-form-field style="width: 100%;">
                                            <mat-label>Odaberite poslovnu jedinicu</mat-label>
                                            <mat-select formControlName="grpPosJedID">
                                                <mat-option [value]="'0'">Sve poslovne jedinice</mat-option>
                                                <mat-option *ngFor="let x of objPosJedinice" [value]="x.PoslJedID">
                                                    {{x.NazivPos}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div> -->


                                    <div class="col d-flex align-items-center" style="padding: 10px 15px;">
                                        <span style="margin-right: 10px;">Datum osnivanja:</span>
                                        <mat-form-field style="min-width: 150px;">
                                            <input matInput name="grpDatumOD" formControlName="grpDatumOD"
                                                [matDatepicker]="picker1" style="text-align: center;">
                                            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                            <mat-datepicker touchUi #picker1 disabled="false"></mat-datepicker>
                                        </mat-form-field>
                                        <mat-error
                                            *ngIf="frmNovaGrupa.controls['grpDatumOD'].touched && frmNovaGrupa.controls['grpDatumOD'].errors">
                                            Neispravan unos</mat-error>
                                    </div>

                                    <div class="col d-flex align-items-center" style="padding: 10px 15px;">
                                        <span style="margin-right: 10px;">Datum završetka:</span>
                                        <mat-form-field style="min-width: 150px;">
                                            <input matInput name="grpDatumOD" formControlName="grpDatumDO"
                                                [matDatepicker]="picker2" style="text-align: center;">
                                            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                            <mat-datepicker touchUi #picker2 disabled="false"></mat-datepicker>
                                        </mat-form-field>
                                    </div>

                                    <div class="col-12 d-flex align-items-center" style="padding: 20px;"
                                        (click)="grupaAktChk()">
                                        <i *ngIf="frmNovaGrupa.get('grpAkt').value == 1" class="fa fa-check-square-o"
                                            aria-hidden="true"></i>
                                        <i *ngIf="frmNovaGrupa.get('grpAkt').value == 0" class="fa fa-square-o"
                                            aria-hidden="true"></i>
                                        <span style="margin-left: 5px;">Aktivna</span>
                                    </div>

                                    <!--Iznos članarine-->
                                    <div class="col-6" style="padding: 10px 15px;">
                                        <mat-form-field style="width: 100%; text-align: center;">
                                            <mat-label>Iznos članarine</mat-label>
                                            <input autocomplete="off" matInput type="text"
                                                formControlName="grpClanarina" (blur)="decFormat($event)"
                                                placeholder="Iznos članarine" aria-label="grpClanarina" required>
                                            <mat-error
                                                *ngIf="frmNovaGrupa.controls['grpClanarina'].touched && frmNovaGrupa.controls['grpClanarina'].errors">
                                                Neispravan unos</mat-error>
                                        </mat-form-field>
                                    </div>

                                    <!--Maksimalno članova-->
                                    <div class="col-6" style="padding: 10px 15px;">
                                        <mat-form-field style="width: 100%; text-align: center;">
                                            <mat-label>Max. polaznika (0 = nije određeno)</mat-label>
                                            <input autocomplete="off" matInput type="text" formControlName="maxCl"
                                                (blur)="decFormat($event)"
                                                placeholder="Maksimalno polaznika (0 = nije određeno)"
                                                aria-label="maxCl" required>
                                            <mat-error
                                                *ngIf="frmNovaGrupa.controls['maxCl'].touched && frmNovaGrupa.controls['maxCl'].errors">
                                                Neispravan unos</mat-error>
                                        </mat-form-field>
                                    </div>

                                </div>

                                <div *ngIf="frmNovaGrupa.errors?.err" class="col-12">
                                    <mat-error>{{frmNovaGrupa.errors.err}}</mat-error>
                                </div>

                                <div class="row" style="margin-bottom: 15px;">
                                    <div class="col" style="padding: 0 2px 0 5px;" (click)="spremiGrupaEdit()">
                                        <div class="mybtn-success w-100">
                                            Spremi promjene
                                        </div>
                                    </div>
                                    <div class="col" style="padding: 0 5px 0 2px;" (click)="frmNovaGrupa = null">
                                        <div class="mybtn-cancel w-100">
                                            Zatvori
                                        </div>
                                    </div>
                                </div>

                            </form>

                        </div>

                    </div>

                    <div *ngIf="!frmNovaGrupa" class="cardio"
                        style="border-bottom: solid 0.5px #d3d3d3; padding: 1px 5px 1px 1px ;">
                        <!-- <div class="row" style="margin: 0;">
                        <div class="col col-3 col-md-4" style="padding: 1px;">
                            <button type="button" class="btn mybtn-info w-100" style="background-color: #67a2c3; color: white; border-radius: 5px;">treneri</button>
                        </div>
                        <div class="col" style="padding: 1px;">
                            <button type="button" class="btn mybtn-info w-100" style="background-color: #dce6ed; border-radius: 5px;">neaktivni članovi</button>
                        </div>
                        <div class="col" style="padding: 1px;">
                            <button type="button" class="btn mybtn-info w-100" style="background-color: #dce6ed; border-radius: 5px;">ispisani članovi</button>
                        </div>
                    </div> -->

                        <div class="cardio">
                            <div class="test">
                                <mat-form-field appearance="fill" style="width: 100%; text-align: center;">
                                    <!-- <mat-label>Izbornik:</mat-label> -->
                                    <mat-select [(value)]="intTab">
                                        <mat-option [value]="1">Aktivni članovi</mat-option>
                                        <mat-option [value]="2">Treneri</mat-option>
                                        <mat-option [value]="3">Neaktivni članovi</mat-option>
                                        <mat-option [value]="4">Ispisani članovi</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>


                    </div>

                </div>

                <div class="flex-grow-1" style="overflow-y: scroll; scrollbar-width: none;">

                    <div class="card">

                        <div *ngIf="intTab == 1 && !frmNovaGrupa" class="cardio" style="padding: 0;"
                            [@listFadeIn]="objAktivni?.length">

                            <div *ngFor="let x of objAktivni">

                                <div class="row" style="text-align: center; margin: 0px;">

                                    <div class="col-8" style="width: 100%; text-align: left; padding-top: 5px;">
                                        <h5>{{x.Ime}} {{x.Prezime}}</h5>
                                        <span style="font-size: 0.8rem;">Član od:</span>
                                        <div>{{x.DatumODvcg | amDateFormat: 'DD.MM.YYYY.'}} </div>
                                    </div>

                                    <!--Uređivanje-->
                                    <div class="col d-flex flex-column justify-content-center"
                                        (click)="clanUredjivanje(x)" style="cursor: pointer;">

                                        <i class="material-icons lst-icon">
                                            edit
                                        </i>
                                        <span>Uređivanje</span>

                                    </div>

                                </div>

                                <div class="row d-flex align-items-center auto-cl"
                                    [ngStyle]="{color: x.autoCl == 1 ? '#008d1e' : '#a19bb1'}"
                                    (click)="autoClanarine(x)">
                                    <i *ngIf="x.autoCl == 1" class="material-icons"
                                        style="margin-right: 10px;">check_circle_outline</i> <i *ngIf="x.autoCl == 0"
                                        class="material-icons" style="margin-right: 10px;">alarm_off</i> Automatsko
                                    obnavljanje
                                    članarina
                                </div>

                                <hr style="margin: 0px; margin-bottom: 5px;" />

                            </div>

                            <div *ngIf="!objAktivni || !objAktivni.length"
                                class="row alert alert-success d-flex justify-content-center">
                                Nema aktivnih članova
                            </div>


                        </div>

                        <div *ngIf="intTab == 2 && !frmNovaGrupa" class="cardio">

                            <div style="border-bottom: solid 0.5px #c9c9c9; padding-bottom: 10px; margin-bottom: 5px;">
                                <button class="btn mybtn-success w-100" style="font-size: 1.3rem;"
                                    (click)="dodajTreneraStart()">Dodaj trenera u grupu</button>
                            </div>

                            <div *ngFor="let x of objTreneri"
                                style="border-bottom: solid 0.5px #c9c9c9; padding: 10px 0;">

                                <div class="row">

                                    <div class="col" style="padding: 10px">
                                        <div style="font-size: 1.4rem;"
                                            [ngStyle]="{'color': intTrenDelete == x.vtgID ? 'red' : 'black'}">
                                            {{x.Ime + ' ' + x.Prezime}}
                                        </div>
                                    </div>

                                    <div *ngIf="intTrenDelete != x.vtgID"
                                        class="col col-3 d-flex justify-content-center align-items-center"
                                        style="color: #dd6969; cursor: pointer;" (click)="intTrenDelete = x.vtgID">
                                        <i class="fa fa-minus-square" style="font-size: 2rem;" aria-hidden="true"></i>
                                        <span style="margin-left: 5px;">Obriši</span>
                                    </div>


                                </div>

                                <div *ngIf="intTrenDelete == x.vtgID" class="row">
                                    <div class="col" style="padding: 0 2px">
                                        <button class="btn mybtn-danger w-100" style="font-size: 1.2rem;"
                                            (click)="obrisiTrenera(x.vtgID)">Potvrdi brisanje</button>
                                    </div>
                                    <div class="col" style="padding: 0 2px">
                                        <button class="btn mybtn-cancel w-100" style="font-size: 1.2rem;"
                                            (click)="intTrenDelete = 0">Otkaži</button>
                                    </div>
                                </div>

                            </div>

                            <div *ngIf="!objTreneri" class="alert alert-success" style="margin: 0; text-align: center;">
                                Nema pridruženih trenera
                            </div>


                        </div>

                        <div *ngIf="intTab == 3 && !frmNovaGrupa" class="cardio" style="padding: 0;"
                            [@listFadeIn]="objNeaktivni?.length">

                            <div *ngFor="let x of objNeaktivni">

                                <div class="row" style="text-align: center; margin: 0px;">

                                    <div class="col-8" style="width: 100%; text-align: left; padding-top: 5px;">
                                        <h5>{{x.Ime}} {{x.Prezime}}</h5>
                                        <span style="font-size: 0.8rem;">Član od:</span>
                                        <div>{{x.DatumODvcg | amDateFormat: 'DD.MM.YYYY.'}}</div>
                                    </div>

                                    <!--Uređivanje-->
                                    <div class="col d-flex flex-column justify-content-center"
                                        (click)="clanUredjivanje(x)" style="cursor: pointer;">

                                        <i class="material-icons lst-icon">
                                            edit
                                        </i>
                                        <span>Uređivanje</span>

                                    </div>

                                </div>

                                <hr style="margin: 0px; margin-bottom: 5px;" />

                            </div>

                            <div *ngIf="!objNeaktivni || !objNeaktivni.length"
                                class="row alert alert-success d-flex justify-content-center">
                                Nema članova koji nisu aktivni
                            </div>

                        </div>

                        <div *ngIf="intTab == 4 && !frmNovaGrupa" class="cardio" style="padding: 0;"
                            [@listFadeIn]="objIspisani?.length">

                            <div *ngFor="let x of objIspisani">

                                <div class="row" style="text-align: center; margin: 0px;">

                                    <div class="col-8" style="width: 100%; text-align: left; padding-top: 5px;">
                                        <h5>{{x.Ime}} {{x.Prezime}}</h5>
                                        <span style="font-size: 0.8rem;">Član od:</span>
                                        <div>{{x.DatumODvcg | amDateFormat: 'DD.MM.YYYY.'}}</div>
                                    </div>

                                    <!--Uređivanje-->
                                    <div class="col d-flex flex-column justify-content-center"
                                        (click)="clanUredjivanje(x)" style="cursor: pointer;">

                                        <i class="material-icons lst-icon">
                                            edit
                                        </i>
                                        <span>Uređivanje</span>

                                    </div>

                                </div>

                                <hr style="margin: 0px; margin-bottom: 5px;" />

                            </div>

                            <div *ngIf="!objIspisani || !objIspisani.length"
                                class="row alert alert-success d-flex justify-content-center">
                                Nema ispisanih članova
                            </div>

                        </div>

                    </div>

                </div>

            </div>



        </div>

    </div>

</div>

<!--Modal unos aktivnosti u kalendar-->
<button hidden="true" id="btnModalNovaAktivnost" data-toggle="modal" data-target="#modalNovaAktivnost"></button>

<div class="modal fade" id="modalNovaAktivnost" tabindex="-1" style="z-index: 5000;" role="dialog"
    aria-labelledby="modalNovaAktivnost" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable" role="document">

        <div class="modal-content" style="border-radius: 10px;">

            <div class="modal-header d-flex justify-content-center bgs-dark">
                <h6 style="margin: 0px;">Novi unos u kalendar</h6>
            </div>


            <div class="modal-body mod-body-kal mat-novi-termin" style="overflow-x: hidden; font-size: 1.1rem;">

                <form *ngIf="srvKalendar.frmKalendarUnos" [formGroup]="srvKalendar.frmKalendarUnos">

                    <div>
                        <!--Datum-->
                        <div class="card card-mod-termin">
                            <div class="card-body card-mod-nova-akt input-slim">
                                <div class="row d-flex justify-content-center align-items-center">
                                    <div class="col" style="align-items: left;">
                                        <div style="color: #5c5b58;">
                                            {{srvKalendar.frmKalendarUnos.controls['ponavljanje'].value ? 'Početni datum:' : 'Datum:'}}
                                        </div>
                                        <div>
                                            <span
                                                style="color: green;">{{srvKalendar.frmKalendarUnos.controls['datumOD'].value
                                                | amDateFormat: 'dddd'}}</span>
                                        </div>
                                    </div>
                                    <div class="col text-center">
                                        <div>
                                            <mat-form-field style="width: 100%;">
                                                <input matInput name="dat1" formControlName="datumOD"
                                                    [matDatepicker]="picker1">
                                                <mat-datepicker-toggle matSuffix [for]="picker1">
                                                </mat-datepicker-toggle>
                                                <mat-datepicker touchUi #picker1 disabled="false"></mat-datepicker>
                                            </mat-form-field>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <!--Vrijeme / Trajanje-->
                        <div class="card card-mod-termin input-slim" style="padding-bottom: 5px;">

                            <div class="row d-flex justify-content-center align-items-center">

                                <div class="col">
                                    <div style="text-align: center;">
                                        <span style="color: #5c5b58">Početak</span>
                                    </div>
                                    <div style="text-align: center;">
                                        <mat-form-field style="width: 60px;">
                                            <mat-label>Sati</mat-label>
                                            <input autocomplete="off" formControlName="hOD" matInput type="tel"
                                                style="text-align: center;" required>
                                        </mat-form-field> :
                                        <mat-form-field style="width: 60px;">
                                            <mat-label>Min.</mat-label>
                                            <input autocomplete="off" formControlName="mOD" matInput type="tel"
                                                style="text-align: center;" required>
                                        </mat-form-field>
                                        <mat-error *ngIf="srvKalendar.frmKalendarUnos.get('hOD').errors?.err"
                                            style="text-align: center;">{{srvKalendar.frmKalendarUnos.get('hOD').errors.err}}
                                        </mat-error>
                                        <mat-error *ngIf="srvKalendar.frmKalendarUnos.get('mOD').errors?.err"
                                            style="text-align: center;">{{srvKalendar.frmKalendarUnos.get('mOD').errors.err}}
                                        </mat-error>
                                        <mat-error *ngIf="srvKalendar.frmKalendarUnos.get('hOD').errors?.required"
                                            style="text-align: center;">obavezan unos</mat-error>
                                        <mat-error *ngIf="srvKalendar.frmKalendarUnos.get('mOD').errors?.required"
                                            style="text-align: center;">obavezan unos</mat-error>
                                    </div>
                                </div>

                                <div class="col">

                                    <div style="text-align: center;">
                                        <span style="color: #5c5b58;">Trajanje</span>
                                    </div>

                                    <div style="text-align: center;">
                                        <!-- <mat-form-field style="width: 60px;">
                                            <mat-label>Sati</mat-label>
                                            <input autocomplete="off" formControlName="hDO" matInput type="tel"
                                                style="text-align: center;" required>
                                        </mat-form-field> : -->
                                        <mat-form-field style="width: 120px;">
                                            <mat-label>Minuta</mat-label>
                                            <input autocomplete="off" formControlName="mDO" matInput type="tel"
                                                style="text-align: center;" required>
                                        </mat-form-field>

                                        <mat-error *ngIf="srvKalendar.frmKalendarUnos.get('hDO').errors?.err"
                                            style="text-align: center;">{{srvKalendar.frmKalendarUnos.get('hDO').errors.err}}
                                        </mat-error>
                                        <mat-error *ngIf="srvKalendar.frmKalendarUnos.get('mDO').errors?.err"
                                            style="text-align: center;">{{srvKalendar.frmKalendarUnos.get('mDO').errors.err}}
                                        </mat-error>
                                        <mat-error *ngIf="srvKalendar.frmKalendarUnos.get('hDO').errors?.required"
                                            style="text-align: center;">obavezan unos</mat-error>
                                        <mat-error *ngIf="srvKalendar.frmKalendarUnos.get('mDO').errors?.required"
                                            style="text-align: center;">obavezan unos</mat-error>
                                    </div>

                                </div>

                            </div>

                        </div>

                        <!-- Dvorana -->
                        <div class="card card-mod-termin input-slim">

                            <div class="card-body card-mod-nova-akt">
                                <div style="color: #5c5b58;">
                                    Dvorana
                                </div>
                                <div>
                                    <mat-form-field style="width: 100%;">
                                        <mat-select formControlName="dvoranaIdAkt">
                                            <mat-option [value]="'0'">Nije određeno</mat-option>
                                            <mat-option *ngFor="let x of objDvorane" [value]="x.dvoID">{{x.opisDvo}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>

                        </div>

                        <!-- Treneri -->
                        <div class="card card-mod-termin akt-treneri">

                            <div style="padding: 0 10px; color: #5c5b58;">
                                Treneri
                            </div>

                            <div class="d-flex flex-wrap">
                                <div class="d-inline-flex" *ngFor="let x of objTreneriTmp">

                                    <div class="row d-flex" style="cursor: pointer;" (click)="x.sel = !x.sel">
                                        <div class="col col-2" style="min-width: 40px;">
                                            <i *ngIf="!x.sel" class="fa fa-square-o" style="align-items: center;"
                                                aria-hidden="true"></i>
                                            <i *ngIf="x.sel" class="fa fa-check-square-o" aria-hidden="true"></i>
                                        </div>
                                        <div class="col d-flex flex-column align-items-start">
                                            <div>
                                                {{x.Ime + ' ' + x.Prezime}}
                                                <!-- <span *ngIf="!x.vtgID" style="margin-left: 5px; color: orange;">! trener više nije u grupi</span> -->
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>

                        <!--Ponavljanje-->
                        <div class="card card-mod-termin input-slim">
                            <div class="card-body card-mod-nova-akt">

                                <div class="d-flex align-items-center" style="min-width: 300px;"
                                    (click)="ponavljanjeClick()">
                                    <div style="font-size: 1.5rem;">
                                        <i *ngIf="!srvKalendar.frmKalendarUnos.controls['ponavljanje'].value"
                                        class="fa fa-square-o" aria-hidden="true"></i>
                                    <i *ngIf="srvKalendar.frmKalendarUnos.controls['ponavljanje'].value"
                                        class="fa fa-check-square-o" aria-hidden="true"></i>
                                    </div>
                                    
                                    <span style="margin-left: 5px; color: #5c5b58;">Ponavljanje</span>
                                </div>

                                <div *ngIf="srvKalendar.frmKalendarUnos.controls['ponavljanje'].value"
                                    class="d-flex justify-content-between" style="margin-top: 10px;">
                                    <div class="d-flex flex-wrap align-items-center justify-content-center"
                                        *ngFor="let x of objDani" style="font-size: 1.5rem; margin-right: 15px;"
                                        (click)="danIdMySql == x.id ? x.chk = x.chk : x.chk = !x.chk">
                                        <i style="font-size: 1.2rem; margin-right: 5px">
                                            {{x.naziv |shortName}}
                                        </i>
                                        <i *ngIf="!x.chk" class="fa fa-square-o" aria-hidden="true"></i>
                                        <i *ngIf="x.chk" class="fa fa-check-square-o" aria-hidden="true"></i>
                                    </div>
                                </div>

                                <div class="row" style="margin: 15px 0px;">
                                    <div class="col">
                                        <div *ngIf="srvKalendar.frmKalendarUnos.controls['ponavljanje'].value"
                                            class="mat-sel-termin">
                                            <mat-form-field style="width: 100%;">
                                                <!-- <mat-label>Odaberite interval</mat-label> -->
                                                <mat-select formControlName="interval" required>
                                                    <!-- <mat-option [value]="1">Svaki dan</mat-option>
                                                    <mat-option [value]="2">Svaki drugi dan</mat-option> -->
                                                    <mat-option [value]="3">Svaki tjedan</mat-option>
                                                    <mat-option [value]="4">Svaki drugi tjedan</mat-option>
                                                    <mat-option [value]="41">Svaki treći tjedan</mat-option>
                                                    <mat-option [value]="5">Jednom mjesečno</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <!--Datum DO-->
                        <div class="card card-mod-termin input-slim"
                            *ngIf="srvKalendar.frmKalendarUnos.controls['ponavljanje'].value">
                            <div class="card-body card-mod-nova-akt">
                                <div class="row d-flex justify-content-center align-items-center">
                                    <div class="col">
                                        <div>
                                            <span style="color: #5c5b58;">Završni datum:</span>
                                        </div>
                                        <div>
                                            <span
                                                style="color: green;">{{srvKalendar.frmKalendarUnos.controls['datumDO'].value
                                                | amDateFormat: 'dddd'}}</span>
                                        </div>
                                    </div>
                                    <div class="col text-center" style="min-width: 150px;">
                                        <mat-form-field style="width: 100%; margin-right: 15px;">
                                            <input matInput name="dat2" formControlName="datumDO"
                                                [matDatepicker]="picker2">
                                            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                            <mat-datepicker touchUi #picker2 disabled="false"></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </form>
            </div>

            <div class="row" style="margin: 0px;">
                <div *ngIf="srvKalendar.frmKalendarUnos && srvKalendar.frmKalendarUnos.errors?.err" class="col"
                    style="padding: 5px;">

                    <mat-error style="text-align: center;">
                        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                        {{srvKalendar.frmKalendarUnos.errors.err}} !
                    </mat-error>
                </div>
            </div>

            <div class="modal-footer d-flex justify-content-center bgs-dark" style="padding: 2px 2px;">

                <div class="row" style="margin: 0px; width: 100%; padding: 5px 0px;">
                    <div class="col-6" style="padding: 1px;">
                        <!-- <button type="submit" id="btnUnesiAktivnost" (click)="unesiAktivnost()" class="btn btn-outline-success" style="width: 100%;">Potvrdi unos</button> -->
                        <div class="mybtn-success" id="btnUnesiAktivnost" style="font-size: 1.2rem;"
                            (click)="unesiAktivnost()">
                            <span>Potvrdi unos</span>
                        </div>
                    </div>
                    <div class="col-6" style="padding: 1px;">
                        <!-- <button type="button" id="btnNovaAktivnostClose" class="btn btn-outline-secondary" style="width: 100%;" data-dismiss="modal" (click)="otvoriSidebar()">Zatvori</button> -->
                        <div class="mybtn-cancel" id="btnNovaAktivnostClose" data-dismiss="modal"
                            style="font-size: 1.2rem;" (click)="otvoriSidebar()">
                            <span>Zatvori</span>
                        </div>
                    </div>
                </div>

            </div>



        </div>
    </div>
</div>

<!--SIDEBAR-->
<button #btnSidebar hidden="true" data-toggle="modal" data-target="#modalSidebar"></button>

<div class="modal left fade" style="height: 100%;" id="modalSidebar" tabindex="-1" role="dialog"
    aria-labelledby="modalSidebarLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">

            <div *ngIf="srvGrupe.objGrupa" class="naslov-m bgs-dark">
                {{srvGrupe.objGrupa.grpOpis}}
            </div>

            <div class="modal-body bgs-dark" style="padding: 5px; overflow:scroll;">

                <!--Back button -> Grupe-->
                <!-- <div class="card" data-dismiss="modal" routerLink="/grupe">

                    <div class="row" style="margin: 0px;">

                        <div class="col-2">
                            <i class="material-icons left-icon">
                                keyboard_backspace
                            </i>
                        </div>

                        <div class="col" style="align-items: center;">
                            <span class="left-h">Grupe</span>
                            <p class="left-p">povratak na listu grupa</p>
                        </div>

                    </div>

                </div> -->

                <div class="btn mybtn-cancel w-100" style="margin: 10px 0; font-size: 1.3rem;" routerLink="/grupe">
                    <i class="material-icons">
                        keyboard_backspace
                    </i>Povratak na grupe
                </div>

                <!-- Današnji termini-->
                <div class="card card-btn-sidebar">
                    <div style="padding: 5px 5px; color: #647264; border-bottom: solid #dfdfdf 0.5px;">
                        <div *ngIf="objDanasnjiTermini">
                            Današnji termini
                        </div>
                        <div *ngIf="!objDanasnjiTermini" style="text-align: center;">
                            Nema zakazanih termina za danas
                        </div>
                    </div>

                    <div *ngFor="let x of objDanasnjiTermini" style="border-bottom: solid #dfdfdf 0.5px;" 
                        [ngStyle]="{'color': aktivnosColor(x.PocetakAkt, x.KrajAkt, x.statusAkt)}">

                        <div class="row" style="margin: 0; padding: 0 5px;">
                            <div class="col d-flex align-items-center"
                                style="padding: 0 5px;">
                                <div>
                                    <div>
                                        {{x.PocetakAkt | amDateFormat:'HH:mm'}} - {{x.KrajAkt | amDateFormat:'HH:mm'}}
                                    </div>
                                    <div>
                                        {{ x.opisDvo }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-3 d-flex justify-content-center align-items-center"
                                style="padding: 2px;" (click)="urediAktivnost(x)">
                                <!-- <mat-icon style="width: 40px; height: 40px; color: grey;">create</mat-icon> -->
                                <!-- <mat-icon>brightness_1</mat-icon> -->
                                <i class="material-icons" style="font-size: 2.2rem;">
                                    create
                                </i>
                            </div>
                            <div class="col-3 d-flex justify-content-center align-items-center"
                                style="padding: 0 5px;"
                                (click)="clanoviLoginStart(x)">
                                <i class="material-icons" style="font-size: 2.7rem;">
                                    people
                                </i>
                            </div>

                            <div class="d-flex" style="padding: 0 5px;">
                                <div *ngFor="let y of x.treneri">
                                    <div style="margin-right: 10px;">
                                        <!-- <i style="color: green;">*</i> -->
                                        {{y.Ime + ' ' + y.Prezime}}
                                    </div>

                                </div>
                            </div>

                        </div>



                    </div>

                </div>

                <!--Raspored-->
                <div class="card card-btn-sidebar" data-dismiss="modal" (click)="noviTermin()">
                    <!-- <app-pos-jedinice></app-pos-jedinice> -->
                    <div class="row">

                        <!--Ikona-->
                        <div class="col-2 d-flex justify-content-center align-items-center">
                            <i class="material-icons left-icon">
                                <!-- insert_invitation -->
                                add
                            </i>
                        </div>

                        <div class="col d-flex flex-column" style="align-items: left; ">
                            <span class="left-h">Novi termin</span>
                            <span class="left-p">unos novog termina u kalendar</span>
                        </div>

                    </div>
                </div>

                <!--Login-->
                <!-- <div class="card card-btn-sidebar">
                    <div class="row" style="margin: 0px;">

                        <div class="col-2 d-flex justify-content-center align-items-center">
                            <i class="material-icons left-icon">
                                input
                            </i>
                        </div>

                        <div class="col d-flex flex-column" style="align-items: left;">
                            <span class="left-h">Prijava</span>
                            <span class="left-p">prijavite se na aktivnost kao trener</span>
                        </div>

                    </div>
                </div> -->

                <!--Login člana-->
                <!-- <div class="card card-btn-sidebar">
                    <div class="row" style="margin: 0px;">

                        <div class="col-2 d-flex justify-content-center align-items-center">
                            <i class="material-icons left-icon">
                                input
                            </i>
                        </div>

                        <div class="col d-flex flex-column" style="align-items: left;">
                            <span class="left-h">Prijava člana</span>
                            <span class="left-p">prijavite dolazak članova u evidenciju posjeta</span>
                        </div>

                    </div>
                </div> -->




                <!--Novi član-->
                <div class="card card-btn-sidebar" (click)="slobodniClanovi()">
                    <!-- <app-pos-jedinice></app-pos-jedinice> -->
                    <div class="row" style="margin: 0px;">

                        <!--Ikona-->
                        <div class="col-2 d-flex justify-content-center align-items-center">
                            <i class="material-icons left-icon">
                                person_add
                            </i>
                        </div>

                        <div class="col d-flex flex-column" style="align-items: left;">
                            <span class="left-h">Novi član</span>
                            <span class="left-p">dodajte novog polaznika u grupu</span>
                        </div>

                    </div>
                </div>

                <!--kalendar-->
                <div class="card mat-kal-grp" style="margin-bottom: 3px;">
                    <app-kalendar (selectedDate)="odabranDatum($event)"></app-kalendar>
                </div>


            </div>
            <div class="modal-footer bgs-dark d-block" style="padding: 10px 2px; margin: 0">
                <button type="button" id="btnSidebarClose" class="btn mybtn-cancel" style="width: 100%;"
                    data-dismiss="modal">Zatvori</button>
            </div>
        </div>
    </div>
</div>

<!--Modal novi član u grupi-->
<button hidden="true" id="btnModalNoviCl" data-toggle="modal" data-target="#modalNoviCl"></button>

<div class="modal fade" id="modalNoviCl" tabindex="-1" role="dialog" aria-labelledby="modalNoviClLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content" style="border-radius: 10px;">

            <div class="modal-header bgs-dark" style="padding: 7px;">
                <div *ngIf="strPretraga == null" class="w-100 row" style="font-size: 1.2rem;">
                    <div *ngIf="srvGrupe.objSlobodniCl"
                        class="col col-4 d-flex justify-content-center align-items-center"
                        style="padding: 5px; font-size: 1.5rem; border-radius: 5px; color: #427d7e; cursor: pointer; border: solid 0.5px #d9d9d9;"
                        (click)="pretragaStart()">
                        <i class="fa fa-search" aria-hidden="true"></i> <span
                            style="margin-left: 5px; font-size: 1.2rem;">Pretraga</span>
                    </div>
                    <div class="col d-flex justify-content-center align-items-center"
                        style="font-size: 1rem; color: #253d3e;">
                        Odabranih članova: {{zbrojClSel()}}
                    </div>
                </div>
                <div *ngIf="strPretraga != null" class="w-100">
                    <mat-form-field style="width: 100%;">
                        <!-- <button mat-button matPrefix mat-icon-button aria-label="Clear" (click)="pretragaClear()">X</button> -->
                        <input id="txtPretraga" autocomplete="off" type="search" name="pretraga"
                            [(ngModel)]="strPretraga" (ngModelChange)="filtrirajClanove(strPretraga)" matInput
                            placeholder="pretraga..">

                    </mat-form-field>
                </div>
            </div>

            <div class="modal-body" style="padding: 2px 2px 0 2px;">

                <div class="row" *ngFor="let x of srvGrupe.fltSlobodniCl"
                    style="cursor: pointer; border-bottom: solid 0.5px #d7d4d4; font-size: 1.2rem;"
                    (click)="x.sel = !x.sel">

                    <div class="col col-2 d-flex justify-content-center align-items-center"
                        style="font-size: 1.2rem; cursor: pointer;">
                        <i *ngIf="!x.sel" class="fa fa-square-o" aria-hidden="true"></i>
                        <i *ngIf="x.sel" class="fa fa-check-square-o" aria-hidden="true"></i>
                    </div>

                    <!--(click)="odabirClanaZaUnos(x)"-->
                    <div class="col" style="width: 100%; padding: 10px; font-size: 1.4rem;">
                        {{x.Ime}} {{x.Prezime}}
                    </div>

                </div>

                <div *ngIf="!srvGrupe.objSlobodniCl" class="d-flex justify-content-center align-items-center"
                    style="border-radius: 5px; background-color: #d0f5e6; padding: 10px; margin: 5px 0;">
                    Nema podataka za prikaz
                </div>

            </div>
            <div class="modal-footer d-block" style="padding: 4px; border: none;">
                <div class="row">
                    <div *ngIf="zbrojClSel()" class="col" style="padding: 0 2px 0 0;">
                        <button type="button" id="btnNoviClClose" class="btn mybtn-success w-100"
                            (click)="odabirClanaZaUnos()">POTVRDI</button>
                    </div>
                    <div class="col" style="padding: 0 0 0 2px;;">
                        <button type="button" id="btnNoviClClose" class="btn mybtn-cancel w-100"
                            data-dismiss="modal">ZATVORI</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<!--Modal Potvrdi unos novog člana-->
<button hidden="true" id="btnModalPotvrdiCl" data-toggle="modal" data-target="#modalPotvrdiCl"></button>

<div class="modal fade" id="modalPotvrdiCl" tabindex="-1" role="dialog" aria-labelledby="modalPotvrdiCl"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content" style="border-radius: 10px;">

            <div *ngIf="clanUnosTmp" class="modal-header">
                Datum učlanjenja - {{clanUnosTmp.ime}} {{clanUnosTmp.prezime}}
            </div>

            <div class="modal-body mat-kal-grp" style="padding: 20px;">

                <app-kalendar (selectedDate)="potvrdiUnosClana($event)"></app-kalendar>

            </div>
            <div class="modal-footer">
                <button type="button" id="btnNoviClClose2" class="btn btn-secondary"
                    data-dismiss="modal">Zatvoi</button>
            </div>
        </div>
    </div>
</div>

<!--Modal Uređivanje VezGrupaClan-->
<button hidden="true" id="btnModalClUredjivanje" data-toggle="modal" data-target="#modalClUredjivanje"></button>

<div class="modal fade" id="modalClUredjivanje" tabindex="-1" role="dialog" aria-labelledby="modalClUredjivanje"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content" style="border-radius: 10px; font-size: 1.2rem;">

            <div class="modal-header d-flex justify-content-center bgs-dark">
                {{clanEditTmp.ime}} {{clanEditTmp.prezime}}
            </div>

            <div class="modal-body bgs-dark" style="padding: 0px 5px;">

                <div *ngIf="!booClBrisanje" class="bgs-light obrisi-cl">
                    <!-- <a id="btnObrisiClana"
                        style="width: 100%; border-radius: 15px; cursor: pointer; margin-right: 10px;"
                        data-dismiss="modal">Obriši člana iz grupe</a> -->
                    <button type="button" class="btn mybtn w-100 align-items-center" style="font-size: 1.2rem; color: darkred;"
                        (click)="booClBrisanje = true;">Obriši člana iz grupe <i class="fa fa-times" aria-hidden="true" style=" margin-left: 10px"></i></button>
                </div>

                <form *ngIf="frmVezGrupaClan" [formGroup]="frmVezGrupaClan">

                    <div class="row input-slim" style="padding: 0px;">

                        <!--Datepicker Datum Učlanjenja-->
                        <div class="col-12 col-frm-edit">
                            <span style="margin-right: 10px;">Datum upisa:</span>
                            <mat-form-field style="min-width: 150px;">
                                <input matInput name="DatumODvcg" formControlName="DatumODvcg"
                                    [matDatepicker]="DatumODvcg">
                                <mat-datepicker-toggle matSuffix [for]="DatumODvcg"></mat-datepicker-toggle>
                                <mat-datepicker touchUi #DatumODvcg></mat-datepicker>
                                <mat-error *ngIf="frmVezGrupaClan.controls['DatumODvcg'].errors?.required"
                                    style="text-align: center;">
                                    Odaberite datum</mat-error>
                            </mat-form-field>
                        </div>

                        <!--CheckBox Aktivan-->
                        <div class="col-12 d-flex col-frm-edit justify-content-center" (click)="chkClAkt()"
                            style="padding-top: 12px; cursor: pointer;">
                            <!-- <mat-checkbox formControlName="AktivanVcg" labelPosition="before" color="primary">Aktivan
                                član </mat-checkbox> -->
                            <i *ngIf="!frmVezGrupaClan.controls['AktivanVcg'].value" class="fa fa-square-o"
                                aria-hidden="true"></i>
                            <i *ngIf="frmVezGrupaClan.controls['AktivanVcg'].value" class="fa fa-check-square-o"
                                aria-hidden="true"></i>
                            <span style="margin: 0 5px;">Aktivan član</span>

                        </div>

                        <!--Datepicker Datum Ispisa-->
                        <div class="col-12 col-frm-edit">
                            <span style="margin-right: 10px;">Datum ispisa:</span>
                            <mat-form-field style="min-width: 150px;">
                                <input matInput name="DatumDOvcg" formControlName="DatumDOvcg"
                                    [matDatepicker]="DatumDOvcg">
                                <mat-datepicker-toggle matSuffix [for]="DatumDOvcg"></mat-datepicker-toggle>
                                <mat-datepicker touchUi #DatumDOvcg></mat-datepicker>
                                <button mat-button *ngIf="frmVezGrupaClan.controls['DatumDOvcg']?.value" matSuffix
                                    mat-icon-button aria-label="Clear"
                                    (click)="frmVezGrupaClan.controls['DatumDOvcg'].setValue('')">X</button>
                            </mat-form-field>
                        </div>

                    </div>


                    <mat-error *ngIf="frmVezGrupaClan.errors?.err" style="text-align: center;">
                        {{frmVezGrupaClan.errors.err}} !</mat-error>

                </form>

                

                <div *ngIf="booClBrisanje" class="bgs-light">
                    <div class="row" style="margin: 0">
                        <div class="col" style="padding: 5px;">
                            <button type="button" class="btn mybtn-danger w-100" style="font-size: 1.2rem;"
                                (click)="obrisiClana(this.frmVezGrupaClan.controls['vcgID'].value)">Potvrdi
                                brisanje</button>
                        </div>
                        <div class="col" style="padding: 5px">
                            <button type="button" class="btn mybtn-cancel w-100" style="font-size: 1.2rem;"
                                (click)="booClBrisanje = false">Otkaži</button>
                        </div>
                    </div>

                </div>

            </div>

            <!--Modal Footer uređivanje veza član grupa-->
            <div class="modal-footer bgs-dark" style="padding: 10px 2px;">
                <div *ngIf="!booClBrisanje" class="row" style="margin: 0px; width: 100%;">
                    <div class="col-6" style="padding: 0px;">
                        <div id="btnNoviClSpremi" class="btns-green" (click)="spremiClEdit()">
                            Spremi</div>
                    </div>

                    <div class="col-6" style="padding: 0px;">
                        <div id="btnNoviClClose3" class="btns-l-grey" data-dismiss="modal">Zatvori</div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<!--Modal termin izbornik-->
<button hidden="true" id="btnModalTermin" data-toggle="modal" data-target="#modalTermin"></button>

<div class="modal fade" id="modalTermin" tabindex="-1" role="dialog" aria-labelledby="modalTerminLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content" style="border-radius: 10px;">

            <div class="modal-header">
                <div>
                    <h5>{{(datTermin | amDateFormat: 'DD.MM.YYYY.') + ' ' + (datTermin | amDateFormat: 'dddd')}}</h5>
                </div>

            </div>

            <div class="modal-body">

                <!--btn lst Lista termina za odabrani datum-->
                <div *ngIf="!blEdit && !blDelete" style="font-size: 1.5rem;">

                    <div *ngFor="let x of objDnevniTermini" class="btn-termin-lst">

                        <div class="row">

                            <div class="col d-flex align-items-center" style="color: rgb(60, 73, 66);">
                                <div>
                                    <div>
                                        {{x.PocetakAkt | amDateFormat: 'HH:mm'}} - {{x.KrajAkt | amDateFormat: 'HH:mm'}} <i
                                        class="fa fa-info-circle" aria-hidden="true" style="margin-left: 5px;"
                                        [ngStyle]="{'color': x.statusAkt == 1 ? '#ffabab' : '#92e4c8'}"></i>
                                    </div>
                                    <div style="font-size: 1.2rem;">
                                        {{x.opisDvo}}
                                    </div>
                                </div>
                            </div>
    
                            <!-- <div class="col col-2 d-flex justify-content-center align-items-center" [ngStyle]="{'color': x.statusAkt == 1 ? '#ffabab' : '#92e4c8'}">
                                <i class="fa fa-info-circle" aria-hidden="true"></i>
                            </div> -->
    
                            <!--Ikona-->
                            <div class="col-2 d-flex justify-content-center align-items-center" style="padding: 0;"
                                (click)="clanoviLoginStart(x)" data-dismiss="modal">
                                <i class="material-icons left-icon" style="font-size: 2.7rem; color: #80978f;">
                                    people
                                </i>
                            </div>
    
                            <!--Ikona-->
                            <div class="col-2 d-flex justify-content-center align-items-center" style="padding: 0;"
                                (click)="odabraniTermin(x)">
                                <i class="material-icons left-icon" style="color:rgb(199, 204, 203); font-size: 2.7rem;">
                                    edit
                                </i>
                            </div>
    
                            <!--Ikona-->
                            <div class="col-2 d-flex justify-content-center align-items-center" style="padding: 0;"
                                (click)="obrisiTermin(x)">
                                <i class="material-icons left-icon" style="color: rgb(172, 3, 3); font-size: 2.7rem;">
                                    clear
                                </i>
                            </div>
    
                        </div>

                        <div class="d-flex" style="margin-top: 5px; color: rgb(60, 73, 66); font-size: 1.2rem;">
                            <div *ngFor="let y of x.treneri">
                                <div style="margin-right: 10px;">
                                    {{y.Ime + ' ' + y.Prezime}}
                                </div>
                            </div>
                        </div>

                    </div>

                </div>


                <!--btn Novi termin-->
                <div class="btns-green" *ngIf="!blEdit && !blDelete" (click)="noviTermin()" data-dismiss="modal"
                    style="margin-top: 15px; border-color: rgb(84, 221, 176);">
                    <i class="material-icons left-icon">add</i>
                    <span>Unos novog termina</span>
                </div>

                <!--Edit - datum-->
                <form [formGroup]="srvKalendar.frmKalendarUnos" *ngIf="blEdit" style="font-size: 1.5rem;">

                    <!-- <div class="row" style="font-size: 1rem;">
                        Odabrani termin: {{srvKalendar.frmKalendarUnos.controls['hOD'].value}} : {{srvKalendar.frmKalendarUnos.controls['mOD'].value}}
                    </div> -->


                    <div class="row" style="font-size: 1rem;">
                        <div class="col col-6">
                            Početak
                        </div>
                        <div class="col col-6">
                            Trajanje
                        </div>
                    </div>

                    <div class="row row-vrijeme input-slim" style="padding-top: 5px;">

                        <div class="col col-6">
                            <mat-form-field style="width: 45%;">
                                <mat-label>Sati</mat-label>
                                <input matInput type="tel" formControlName="hOD" placeholder="Sati" required>
                            </mat-form-field> :

                            <mat-form-field style="width: 45%;">
                                <mat-label>Min.</mat-label>
                                <input matInput type="tel" formControlName="mOD" placeholder="Min." required>
                            </mat-form-field>
                        </div>


                        <div class="col col-6">
                            <!-- <mat-form-field style="width: 45%;">
                                <mat-label>Sati</mat-label>
                                <input matInput type="tel" formControlName="hDO" placeholder="Sati" required>
                            </mat-form-field> : -->

                            <mat-form-field style="width: 100%;">
                                <mat-label>Minuta</mat-label>
                                <input matInput type="tel" formControlName="mDO" placeholder="Min." required>
                            </mat-form-field>
                        </div>
                    </div>

                    <div style="font-size: 1.2rem;">
                        <div>
                            Status:
                        </div>
                        <div class="mat-sel-termin">
                            <mat-form-field style="text-align: center; width: 100%;">
                                <!-- <mat-label>Izbornik:</mat-label> -->
                                <mat-select formControlName="statusAkt">
                                    <mat-option [value]="0">Na rasporedu</mat-option>
                                    <mat-option [value]="1">Otkazano</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>

                    <div style="font-size: 1.2rem;">
                        <div>
                            Dvorana
                        </div>
                        <div>
                            <mat-form-field style="font-size: 1.5rem; width: 100%;">
                                <mat-select formControlName="dvoranaIdAkt">
                                    <mat-option [value]="'0'">Nije određeno</mat-option>
                                    <mat-option *ngFor="let x of objDvorane" [value]="x.dvoID">{{x.opisDvo}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                    </div>

                    <!-- Treneri -->
                    <div class="card card-mod-termin akt-treneri" style="font-size: 1.2rem;">

                        <div style="padding: 0 20px">
                            Treneri
                        </div>

                        <div class="d-flex flex-wrap">
                            <div class="d-inline-flex" *ngFor="let x of objTreneriTmp">

                                <div class="row d-flex" style="cursor: pointer;" (click)="x.sel = !x.sel">
                                    <div class="col col-2" style="min-width: 40px;">
                                        <i *ngIf="!x.sel" class="fa fa-square-o" style="align-items: center;"
                                            aria-hidden="true"></i>
                                        <i *ngIf="x.sel" class="fa fa-check-square-o" aria-hidden="true"></i>
                                    </div>
                                    <div class="col d-flex flex-column align-items-start">
                                        <div>
                                            {{x.Ime + ' ' + x.Prezime}}
                                            <!-- <span *ngIf="!x.vtgID" style="margin-left: 5px; color: orange;">! trener više nije u grupi</span> -->
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                </form>

                <!-- Ponavljanja -->
                <div *ngIf="blEdit">

                    <div *ngIf="objPonDelTmp?.length > 0" class="row"
                        style="cursor: pointer; padding: 5px 9px; margin-bottom: 5px;"
                        (click)="blPonavljanje = !blPonavljanje">
                        <div class="col d-flex align-items-center" style="min-width: 40px; font-size: 1.5rem;">
                            <div style="margin-right: 10px;">
                                <i *ngIf="!blPonavljanje" class="fa fa-square-o" style="align-items: center;"
                                    aria-hidden="true"></i>
                                <i *ngIf="blPonavljanje" class="fa fa-check-square-o" aria-hidden="true"></i>
                            </div>

                            <div style="font-size: 1.2rem;">
                                Ažuriraj ponavljanja
                            </div>
                        </div>
                    </div>

                    <div *ngIf="blPonavljanje">
                        <div class="row" [ngClass]="{'pon-selected': danIdMySql == x.danID}"
                            style="padding: 2px 9px; cursor: pointer;" *ngFor="let x of objPonDelTmp"
                            (click)="danIdMySql == x.danID ? x.selected = x.selected : x.selected = !x.selected">
                            <div class="col d-flex align-items-center">
                                <div style="margin-right: 10px; font-size: 1.5rem;">
                                    <i *ngIf="!x.selected" class="fa fa-square-o" aria-hidden="true"></i>
                                    <i *ngIf="x.selected" class="fa fa-check-square-o" aria-hidden="true"></i>
                                </div>
                                <div style="font-size: 1.2rem;">
                                    {{x.danID | danNazivMysql}}
                                </div>

                            </div>
                        </div>
                    </div>

                    <div *ngIf="blPonavljanje" class="input-slim" style="margin-top: 5px;">
                        <mat-form-field style="font-size: 1.5rem; width: 100%;">
                            <!-- <mat-label>Odaberite interval</mat-label> -->
                            <mat-select [(ngModel)]="intPonavljanje" required>
                                <!-- <mat-option [value]="1">Svaki dan</mat-option>
                                <mat-option [value]="2">Svaki drugi dan</mat-option> -->
                                <mat-option [value]="3">Svaki tjedan</mat-option>
                                <mat-option [value]="4">Svaki drugi tjedan</mat-option>
                                <mat-option [value]="41">Svaki treći tjedan</mat-option>
                                <mat-option [value]="5">Jednom mjesečno</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div *ngIf="blPonavljanje" class="row" style="cursor: pointer; padding: 9px;"
                        (click)="bolTrenEdit = !bolTrenEdit">
                        <div class="col d-flex align-items-center" style="min-width: 40px; font-size: 1.5rem;">
                            <div style="margin-right: 10px;">
                                <i *ngIf="!bolTrenEdit" class="fa fa-square-o" style="align-items: center;"
                                    aria-hidden="true"></i>
                                <i *ngIf="bolTrenEdit" class="fa fa-check-square-o" aria-hidden="true"></i>
                            </div>

                            <div style="font-size: 1.2rem;">
                                Ažurirati trenere
                            </div>
                        </div>
                    </div>

                    <div *ngIf="blPonavljanje" style="margin-bottom: 10px;">
                        <div class="row input-slim">
                            <div class="col d-flex align-items-center">
                                <div>
                                    Završni datum:
                                </div>
                            </div>
                            <div class="col">
                                <mat-form-field style="width: 100%;">
                                    <input matInput name="grpDatumOD" [(ngModel)]="datDoPonavljanje"
                                        [min]="srvKalendar.stringToMoment(objTerminTmp.datumOD)"
                                        [matDatepicker]="picker4" style="text-align: center;">
                                    <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
                                    <mat-datepicker touchUi #picker4 disabled="false"></mat-datepicker>
                                    <mat-error>Neispravan unos</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                </div>


                <!--Delete datum-->
                <div *ngIf="blDelete">

                    <div class="row row-delete">
                        <div class="col">
                            <span>
                                {{'Odabrani termin za brisanje: ' + objTerminTmp.hOD + ':' + objTerminTmp.mOD + ' - ' +
                                objTerminTmp.hDO + ':' + objTerminTmp.mDO}}</span>
                        </div>

                    </div>

                    <div *ngIf="objPonDelTmp?.length > 0" class="row row-delete">
                        <div class="col">
                            <mat-checkbox color="warn" name="blPonavljanje" [(ngModel)]="blPonavljanje">Obriši
                                ponavljanja
                            </mat-checkbox>
                        </div>
                    </div>

                    <div *ngIf="blPonavljanje">
                        <div class="row" *ngFor="let x of objPonDelTmp">
                            <div class="col">
                                <mat-checkbox color="warn" name="selected" [(ngModel)]="x.selected">
                                    {{x.danID | danNazivMysql}}</mat-checkbox>

                            </div>
                        </div>
                    </div>

                    <div *ngIf="blPonavljanje">
                        <mat-form-field style="font-size: 1.5rem; width: 100%;">
                            <!-- <mat-label>Odaberite interval</mat-label> -->
                            <mat-select [(ngModel)]="intPonavljanje" required>
                                <!-- <mat-option [value]="1">Svaki dan</mat-option>
                                <mat-option [value]="2">Svaki drugi dan</mat-option> -->
                                <mat-option [value]="3">Svaki tjedan</mat-option>
                                <mat-option [value]="4">Svaki drugi tjedan</mat-option>
                                <mat-option [value]="41">Svaki treći tjedan</mat-option>
                                <mat-option [value]="5">Jednom mjesečno</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div *ngIf="blPonavljanje" style="margin-bottom: 10px;">
                        <div class="row input-slim">
                            <div class="col d-flex align-items-center">
                                <div>
                                    Završni datum:
                                </div>
                            </div>
                            <div class="col">
                                <mat-form-field style="width: 100%;">
                                    <input matInput name="grpDatumOD" [(ngModel)]="datDoPonavljanje"
                                        [min]="srvKalendar.stringToMoment(objTerminTmp.datumOD)"
                                        [matDatepicker]="picker3" style="text-align: center;">
                                    <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                                    <mat-datepicker touchUi #picker3 disabled="false"></mat-datepicker>
                                    <mat-error>Neispravan unos</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>


                    <div class="row">
                        <div class="col">
                            <button type="button" class="btn btn-outline-danger btns-delete"
                                (click)="obrisiTerminKraj()">Obriši</button>
                        </div>
                        <div class="col">
                            <button type="button" class="btn btn-outline-secondary btns-delete"
                                (click)="obrisiTerminCancel()">Odustani</button>
                        </div>
                    </div>

                </div>


                <!--btn Spremi-->
                <div class="row btn-termin-lst" *ngIf="blEdit" style="border-color: rgb(84, 221, 176);">

                    <div class="col" style="padding: 2px;"
                        (click)="spremiAktivnost(srvKalendar.frmKalendarUnos.controls['datumOD'].value)">
                        <button type="submit" class="btn mybtn-success w-100">Spremi <i
                                class="material-icons">save</i></button>
                    </div>

                    <div class="col" style="padding: 2px;">
                        <button type="button" class="btn mybtn-cancel w-100" (click)="blEdit = false;">Odustani</button>
                    </div>

                </div>

            </div>

            <div class="modal-footer">
                <button *ngIf="!blEdit && !blDelete" style="width: 100%; border-radius: 15px;" type="button"
                    class="btn btn-outline-secondary" (click)="resetDialogEdit()">Zatvori</button>
                <button hidden="true" data-dismiss="modal" id="btnModalTerminClose"></button>
            </div>

        </div>
    </div>
</div>

<!--Modal Lista članova za prijavu-->
<button hidden="true" id="btnMdlListaClPrijava" data-toggle="modal" data-target="#mdlListaClPrijava"></button>

<div class="modal fade" id="mdlListaClPrijava" tabindex="-1" role="dialog" aria-labelledby="mdlListaClPrijava"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content" style="border-radius: 10px;">

            <div class="modal-header d-block" style="font-size: 1.3rem; color: #1d4c6c; padding: 15px 15px 5px;">
                <div *ngIf="aktivnostTmp">
                    <i *ngIf="bolGrpGost" class="material-icons" style="cursor: pointer;"
                        (click)="bolGostSearch = !bolGostSearch">search</i> {{srvGrupe.objGrupa.grpOpis + ' ' +
                    (aktivnostTmp.PocetakAkt | amDateFormat:'HH:mm') + ' - ' + (aktivnostTmp.KrajAkt
                    | amDateFormat:'HH:mm')}}
                </div>

                <!--PRETRAGA-->
                <div *ngIf="bolGostSearch" class="row" style="margin: 0px;">
                    <!--Input-->
                    <div class="col w-100" style="text-align: center; padding: 0;">
                        <mat-form-field style="width: 100%;">
                            <input autocomplete="off" type="search" name="pretraga"
                                [(ngModel)]="srvClanovi.strPretraga2"
                                (ngModelChange)="srvClanovi.filtrirajNeprijavljene(srvClanovi.strPretraga2)" matInput
                                placeholder="pretraga..">
                            <!-- <button mat-button *ngIf="srvClanovi.strPretraga2" matSuffix mat-icon-button aria-label="Clear" (click)="pretragaClear()">X</button> -->
                        </mat-form-field>
                    </div>
                </div>

            </div>

            <div class="modal-body" style="padding: 10px 7px; font-size: 1.5rem;">

                <!--članovi grupe-->
                <div *ngIf="!bolGrpGost">
                    <div class="row" *ngFor="let x of srvGrupe.objClanoviZaPrijavu; let i = index"
                        [ngClass]="{'cl-nepr': !x.trenID}" style="cursor: pointer;" (click)="clanChk(x, i)">
                        <div class="col col-2 d-flex justify-content-center align-items-center"
                            style="font-size: 2.5rem;">
                            <div *ngIf="!x.trenID" class="fa fa-square-o" style="align-items: center;"
                                aria-hidden="true">
                            </div>
                            <div *ngIf="x.trenID" class="fa fa-check-square-o" aria-hidden="true"></div>
                        </div>
                        <div class="col d-flex align-items-center">
                            <div>
                                {{x.Ime + ' ' + x.Prezime}}
                            </div>
                            <div class="d-flex align-items-center"
                                style="margin-left: 10px; color:#6ea1ff; font-size: 1.3rem;">
                                {{x.vcgID ? '' : ' - Gost'}}
                            </div>

                        </div>
                    </div>
                </div>

                <!--Gosti-->
                <div *ngIf="bolGrpGost">
                    <div *ngFor="let x of srvClanovi.fltNeprijavljeni" (click)="potvrdiGosta(x)"
                        style="cursor: pointer; border-bottom: solid 0.5px #dbdbdb;">
                        <div class="row" style="margin: 0; padding: 8px;">
                            <div class="col" style="padding: 2px;">
                                {{x.Ime}} {{x.Prezime}}
                            </div>
                        </div>
                    </div>
                </div>


            </div>

            <div class="modal-footer d-block" style="padding: 2px;">

                <button hidden id="btnMdlListaClPrijavaClose" data-dismiss="modal"></button>
                <div *ngIf="!bolGrpGost" class="row" style="margin: 0; padding: 0;">
                    <div class="col col-6" style="padding: 2px;">
                        <button type="button" class="btn btns-lo-grey w-100" style="font-size: 1.3rem;"
                            data-dismiss="modal">Zatvori</button>
                    </div>
                    <div class="col col-6" style="padding: 2px;">
                        <button type="button" class="btn btns-info w-100" style="font-size: 1.3rem;"
                            (click)="prijaviGosta(aktivnostTmp)">Svi članovi</button>
                    </div>
                </div>

                <div *ngIf="bolGrpGost" class="row" style="margin: 0; padding: 0;">
                    <!-- <div class="col col-6" style="padding: 2px;">
                        <button type="button" class="btn btns-info w-100" style="font-size: 1.3rem;" (click)="prijaviGosta(aktivnostTmp)">Prijavi</button>
                    </div> -->
                    <div class="col" style="padding: 2px;">
                        <button type="button" class="btn btns-lo-grey w-100" style="font-size: 1.3rem;"
                            (click)="bolGrpGost = false; bolGostSearch = false; srvClanovi.strPretraga2 = ''">Otkaži</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<!--Modal Lista slobodnih trenera-->
<button hidden="true" id="btnMdlListaSlobTren" data-toggle="modal" data-target="#mdlListaSlobTren"></button>

<div class="modal fade" id="mdlListaSlobTren" tabindex="-1" role="dialog" aria-labelledby="mdlListaSlobTren"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content" style="border-radius: 10px;">

            <div class="modal-header justify-content-center" style="font-size: 1.2rem; color: #6c1d1d;">
                <div>
                    Lista trenera
                </div>
            </div>

            <div class="modal-body" style="padding: 10px 7px; font-size: 1.5rem;">

                <div class="row" *ngFor="let x of objSlobodniTreneri; let i = index"
                    style="cursor: pointer; padding: 5px 0;" (click)="x.sel = !x.sel">
                    <div class="col col-2 d-flex justify-content-center align-items-center" style="font-size: 2.5rem;">
                        <div *ngIf="!x.sel" class="fa fa-square-o" style="align-items: center;" aria-hidden="true">
                        </div>
                        <div *ngIf="x.sel" class="fa fa-check-square-o" aria-hidden="true"></div>
                    </div>
                    <div class="col">
                        {{x.Ime + ' ' + x.Prezime}}
                    </div>
                </div>

                <div *ngIf="!objSlobodniTreneri" class="d-flex justify-content-center align-items-center"
                    style="border-radius: 5px; background-color: #7ed1b6; color: white; font-size: 1.3rem;">
                    Nema dostupnih trenera
                </div>

            </div>

            <div class="modal-footer d-block" style="padding: 5px 0;">
                <div class="row">
                    <div *ngIf="zbrojTrenSel()" class="col" style="padding: 0 2px;">
                        <button class="btn mybtn-success w-100" style="margin: 0; font-size: 1.2rem;"
                            (click)="dodajTreneraEnd()">Potvrdi</button>
                    </div>
                    <div class="col" style="padding: 0 2px;">
                        <button id="btnMdlListaSlobTrenClose" class="btn mybtn-cancel w-100"
                            style="margin: 0; font-size: 1.2rem;" data-dismiss="modal">Zatvori</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!--Modal Članarina (new / edit)-->
<button hidden="true" id="btnMdlClanarina" data-toggle="modal" data-target="#mdlClanarina"></button>

<div class="modal fade" id="mdlClanarina" tabindex="-1" role="dialog" aria-labelledby="mdlClanarina" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content" style="border-radius: 10px;">

            <div class="modal-header justify-content-center" style="font-size: 1.2rem; color: #6c1d1d;">
                <div>
                    Članarina
                </div>
            </div>

            <div class="modal-body" style="padding: 10px 5px; font-size: 1.5rem;">

                <div style="text-align: center; padding-bottom: 5px; color: chocolate">
                    Unesite prvu članarinu!
                </div>

                <app-clanarine-edit></app-clanarine-edit>

            </div>

            <div class="modal-footer d-block" style="padding: 0;">
                <div class="row">
                    <div class="col" style="padding: 5px;">
                        <button type="button" class="btn mybtn-success w-100"
                            (click)="spremiClanarinu()">Spremi</button>
                    </div>
                    <div class="col" style="padding: 5px;">
                        <button type="button" id="btnMdlClanarinaClose" class="btn mybtn-cancel w-100"
                            data-dismiss="modal">Zatvori</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<!-- Modal - uređivanje termina -->
<button hidden="true" id="btnAktEdit" class="btn btn-info" data-toggle="modal" data-target="#mdlAktEdit">Uređivanje
    termina aktivnosti</button>

<div class="modal fade" id="mdlAktEdit" tabindex="-1" role="dialog" aria-labelledby="Modal uređivanje aktivnosti"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content" style="border-radius: 10px;">
            <div class="modal-header">
                <h6 class="modal-title" id="mdlAktEditLabel">Uređivanje termina</h6>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" style="text-align: center;">
                <app-aktivnost-edit></app-aktivnost-edit>
            </div>
            <div class="modal-footer d-block">
                <div class="row">
                    <div class="col">
                        <button type="button" class="btn mybtn-success w-100"
                            (click)="spremiAktivnost2()">SPREMI</button>
                    </div>
                    <div class="col">
                        <button id="btnMdlAktEditClose" type="button" class="btn mybtn-cancel w-100"
                            data-dismiss="modal">Zatvori</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>