import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Clanarina, ClanarinaFilterRez, ClanarineService } from '../clanarine.service';
import { Clan, ClanoviService } from '../clanovi.service';

@Component({
  selector: 'app-clanarine-pregled-sidebar',
  templateUrl: './clanarine-pregled-sidebar.component.html',
  styleUrls: ['./clanarine-pregled-sidebar.component.scss']
})
export class ClanarinePregledSidebarComponent implements OnInit {

  constructor(
    public srvClanarine: ClanarineService,
    public srvClanovi: ClanoviService
  ) { }

  ngOnInit(): void {
    
  }

  //Učitavanje članova
  modalClanovi() {

    document.getElementById('btnMdlClanovi').click();

    this.srvClanovi.clanoviByKorisnikID((rez: Clan[]) => {
      this.srvClanovi.objClanovi = rez;
      this.srvClanovi.fltClanovi = rez;
    });

  }

  //Prikaz podataka
  prikazi() {
    this.srvClanarine.filterClanarine(this.srvClanarine.frmClanarineFilter.value,(rez: ClanarinaFilterRez[]) => {
      console.log(rez);
      this.srvClanarine.objClanarinaFilterRez = rez;
      this.srvClanarine.fltClanarinaFilterRez = rez;
      this.srvClanarine.filtrirajClanarine(this.srvClanarine.strPretragaClanarine);
    });
  }

  //Unos članarine
  unosClanarine() {

    this.srvClanarine.intUnosTmp = 1;


    if (this.srvClanarine.frmClanarineFilter.get('clanID').value) {

      document.getElementById('btnMdlClanarina').click();
      this.srvClanarine.frmClanarina.get('claClanID').setValue(this.srvClanarine.frmClanarineFilter.get('clanID').value);

        this.srvClanarine.istekClanarine(this.srvClanarine.frmClanarineFilter.get('clanID').value, (rez: Clanarina) => {

          if (rez) {

            let dTmp: moment.Moment = rez.claVrijediDO;

            this.srvClanarine.frmClanarina.get('claVrijediOD').setValue(moment(dTmp).add(1, 'days').utc(true));
            this.srvClanarine.frmClanarina.get('claVrijediDO').setValue(moment(this.srvClanarine.frmClanarina.get('claVrijediOD').value).add(1, 'month').subtract(1, 'days').utc(true));
            this.srvClanarine.frmClanarina.get('claIznos').setValue(rez.claIznos);
            this.srvClanarine.frmClanarina.get('claGrupaID').setValue(rez.claGrupaID);

            if (this.srvClanarine.frmClanarina.get('claVrijediOD').value.format('D') == '1') {
              let dLastTmp: string = moment(this.srvClanarine.frmClanarina.get('claVrijediOD').value).endOf('month').format('DD');
              this.srvClanarine.frmClanarina.get('claVrijediDO').setValue(moment(moment(this.srvClanarine.frmClanarina.get('claVrijediOD').value.format('YYYY') + '-' + this.srvClanarine.frmClanarina.get('claVrijediOD').value.format('MM') + '-' + dLastTmp)).utc(true));
              console.log(this.srvClanarine.frmClanarina.get('claVrijediDO').value);
            }

          }

          this.srvClanarine.intUnosTmp = 0;

        });
    } else {
      this.modalClanovi();
    }

  }

}
