<div class="container" style="opacity: 0.9; padding: 0; font-size: 1.2rem; height: 100%;">

    <div class="row" style="padding-top: 50px; height: 100%; max-height: 100%;">

        <!-- Lijeva kolona -->
        <div class="d-none d-md-block col col-4 col-left">

            <div class="card" style="padding: 20px 5px;">

                <app-termini-pregled-sidebar></app-termini-pregled-sidebar>

            </div>

            <!-- <button type="button" class="btn btn-secondary w-100" (click)="prikaziSession()">test</button> -->

        </div>

        <!-- Desna kolona -->
        <div class="col card col-right" style="padding-bottom: 30px; height: 100%; max-height: 100%;">

            <div class="d-flex flex-column" style="max-height: 100%; overflow-x: hidden;">
                <div class="d-block d-md-none" style="position: -webkit-sticky; position: sticky; z-index: 800;"
                    data-toggle="modal" data-target="#mdlSidebar">

                    <app-pos-jed-hamburger></app-pos-jed-hamburger>

                </div>

                <div class="flex-grow-1" style="overflow-y: scroll; margin-right: -15px; background-color: white;">

                    <div *ngFor="let x of srvTermini.objTermini">

                        <div style="padding: 10px; background-color: #e4eeed; border-radius: 5px;">
                            {{x.datum | amLocal | amDateFormat:'dddd'}} <span style="color: #00504a;">{{x.datum |
                                amDateFormat:'DD.MM.YYYY.'}}</span>
                        </div>

                        <div *ngFor="let y of x.termini" style="border-bottom: dashed grey 0.5px;">
                            <div class="row" [ngStyle]="{'color': y.statusAkt == 0 ? 'black' : '#ff6a6a'}">
                                <div class="col" style="padding: 5px;">
                                    <div>
                                        {{y.PocetakAkt | amDateFormat:'HH:mm'}} - {{y.KrajAkt | amDateFormat:'HH:mm'}}
                                        <span style="font-weight:500;">{{y.grpOpis}}</span>
                                    </div>
                                    <div style="font-size: 0.9rem;">
                                        <span style="color: rgb(39, 59, 59);"> [{{y.NazivPos}}]</span>
                                    </div>
                                    <div>
                                        <span *ngIf="y.statusAkt != 0"
                                            style="color: #ff6a6a; margin-left: 5px;">OTKAZANO</span>
                                    </div>
                                    <div>
                                        {{y.opisDvo}}
                                    </div>
                                    <div>
                                        <div *ngFor="let z of y.treneri">
                                            {{z.Ime + ' ' + z.Prezime}}
                                        </div>
                                    </div>
                                </div>
                                <div class="col col-2 d-flex align-items-center justify-content-center" style="padding: 5px 15px 5px 5px;cursor: pointer;" (click)="urediAktivnost(y)">
                                    <i class="material-icons" style="font-size: 2.5rem;">
                                        create
                                    </i>
                                </div>
                            </div>
                        </div>





                    </div>

                    <div>
                        <div *ngIf="!srvTermini.objTermini || !srvTermini.objTermini.length" class="alet alert-info"
                            style="padding: 5px; text-align: center; font-size: 1.2rem;">
                            Nema termina u zadanom periodu
                        </div>
                    </div>

                </div>

            </div>


        </div>


    </div>

</div>

<!--SIDEBAR-->
<button hidden="true" id="btnSidebar" data-toggle="modal" data-target="#modalPrisutni"></button>

<div class="modal left fade" id="modalPrisutni" tabindex="-1" role="dialog" aria-labelledby="modalPrisutniLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document" style="font-size: 1.2rem;">
        <div class="modal-content">

            <div class="modal-header posjed-mod-header d-flex justify-content-center">
                Pregled termina
            </div>

            <div class="modal-body posjed-mod-body">

                <app-termini-pregled-sidebar></app-termini-pregled-sidebar>

            </div>
            <div class="modal-footer bgs-dark" style="padding: 5px;">
                <button type="button" id="btnSidebarClose" class="btn mybtn-cancel" data-dismiss="modal"
                    style="width: 100%;">Zatvori</button>
            </div>
        </div>
    </div>
</div>

<!-- Modal - uređivanje termina -->
<button hidden="true" id="btnAktEdit" class="btn btn-info" data-toggle="modal" data-target="#mdlAktEdit">Uređivanje
    termina aktivnosti</button>

<div class="modal fade" id="mdlAktEdit" tabindex="-1" role="dialog" aria-labelledby="Modal uređivanje aktivnosti"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content" style="border-radius: 10px;">
            <div class="modal-header">
                <h6 class="modal-title" id="mdlAktEditLabel">Uređivanje termina</h6>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" style="text-align: center;">
                <app-aktivnost-edit></app-aktivnost-edit>
            </div>
            <div class="modal-footer d-block">
                <div class="row">
                    <div class="col">
                        <button type="button" class="btn mybtn-success w-100"
                            (click)="spremiAktivnost()">SPREMI</button>
                    </div>
                    <div class="col">
                        <button id="btnMdlAktEditClose" type="button" class="btn mybtn-cancel w-100"
                            data-dismiss="modal">Zatvori</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>