import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-stat-klijent',
  templateUrl: './stat-klijent.component.html',
  styleUrls: ['./stat-klijent.component.scss']
})
export class StatKlijentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
