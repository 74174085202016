import { Injectable } from '@angular/core';
import { Chk, ModulService } from './modul.service';
import { HttpClient } from '@angular/common/http';
import { Moment } from 'moment';
import * as moment from 'moment';
import { FormBuilder, Validators, FormGroup, AbstractControlOptions } from '@angular/forms';
import * as validatori from './validatori';
import { Dvorana } from './dvorane.service';
import { Clan } from './clanovi.service';

export interface DanUtjednu {
  id: number,
  naziv: string
}

export interface DaniUtjednuChk extends Chk {

}

export interface Aktivnost {
  aktGrpID: number,
  GrupaIDakt: number,
  PocetakAkt: Moment,
  KrajAkt: Moment,
  statusAkt: number,
  dvoranaIdAkt: number
}

export interface Vaktivnost extends Aktivnost, Dvorana {

}

@Injectable({
  providedIn: 'root'
})

export class KalendarService {

  constructor(
    public mod: ModulService,
    private http: HttpClient,
    public frmBuilder: FormBuilder
  ) { }

  daniUtjednu: DanUtjednu[] = [
    { id: 2, naziv: 'ponedjeljak' },
    { id: 3, naziv: 'utorak' },
    { id: 4, naziv: 'srijeda' },
    { id: 5, naziv: 'četvrtak' },
    { id: 6, naziv: 'petak' },
    { id: 7, naziv: 'subota' },
    { id: 1, naziv: 'nedjelja' }
  ]

  objAktivnosti: Aktivnost[] = [];
  frmKalendarUnos: FormGroup;
  objDvorane: Dvorana[];

  //Napravi frm Kalendar Unos
  fnKalendarUnos(grpID: number, callbac) {

    this.frmKalendarUnos = this.frmBuilder.group({
      aktGrpID: [0],
      datumOD: [moment().locale('hr').utc(true), Validators.required],
      grpID: [grpID],
      hOD: [moment().format('HH'), [Validators.required, validatori.maxH]],
      mOD: ['00', [Validators.required, validatori.maxMin]],
      hDO: ['00', [Validators.required, validatori.isNum]],
      mDO: ['60', [Validators.required, validatori.isNum]],
      ponavljanje: [false],
      interval: [3],
      datumDO: [moment().locale('hr').utc(true)],
      statusAkt: [0],
      dvoranaIdAkt: ['0']
    }, {
      validator: validatori.datumVrijemeCompare
    } as AbstractControlOptions);

    callbac(this.frmKalendarUnos.value);

  }

  //dan u tjednu moment to mysql
  danUtjMomentToMySql(id: number): number {

    let rez: number;

    switch (id) {

      case 1:
        rez = 2;
        break;

      case 2:
        rez = 3;
        break;

      case 3:
        rez = 4;
        break;

      case 4:
        rez = 5;
        break;

      case 5:
        rez = 6;
        break;

      case 6:
        rez = 7;
        break;

      case 7:
        rez = 1;
        break;

    }

    return rez;

  }

  //Datum: string to moment
  stringToMoment(dat: string): Moment {
    return moment(dat);
  }

  //Kalendar aktivnosti
  ucitajAktivnosti(grpID: number) {

    let urlTmp: string = this.mod.webUrl + '/php/aktivnosti.php?token=' + this.mod.objKor.token + '&grpID=' + grpID + '&funk=ucitajAktivnosti'
    let obj: string = JSON.stringify({ fingerprint: this.mod.fingerprint() });

    this.http.post(urlTmp, obj).subscribe((rez: Aktivnost[]) => {
      this.objAktivnosti = rez;
      //alert(JSON.stringify(rez));
    });

  }

  //Unos aktivnosti
  unesiAktivnost(treneri: number[], daniUtjednu: number[], callback) {

    console.log(treneri);

    let urlTmp: string = this.mod.webUrl + '/php/aktivnosti.php?token=' + this.mod.objKor.token + '&funk=insert';

    //this.frmKalendarUnos.controls['datumOD'].setValue(moment(this.frmKalendarUnos.controls['datumOD'].value).utc(false));

    let obj = this.frmKalendarUnos.value;
    obj['treneri'] = treneri;
    obj['daniUtjednu'] = daniUtjednu;
    obj['fingerprint'] = this.mod.fingerprint();
    let frmTmp: string = JSON.stringify(obj);

    //alert(JSON.stringify(frmTmp));

    this.http.post(urlTmp, frmTmp).subscribe((rez: Aktivnost[]) => {

      console.log(rez);

      //alert(JSON.stringify(rez));
      this.objAktivnosti = rez;

      document.getElementById('btnModalNovaAktivnost').click();

      this.mod.msgBox(1, "Spremljeno!", 2000);

      callback();

    });

  }
  //Aktivnost Edit - vrijeme
  spremiAktivnost(bolTrenEdit: boolean, treneri: number[], objPonDelTmp: number[], interval: number, datDoPonavljanje: Moment, callback) {

    datDoPonavljanje = moment(datDoPonavljanje, 'YYYY-MM-DD');

    let urlTmp: string = this.mod.webUrl + '/php/aktivnosti.php?token=' + this.mod.objKor.token + '&funk=aktivnostUpdate';

    let obj = this.frmKalendarUnos.value;
    obj['treneri'] = treneri;
    obj['objPonDelTmp'] = objPonDelTmp;
    obj['interval'] = interval;
    obj['datDoPonavljanje'] = datDoPonavljanje;
    obj['bolTrenEdit'] = bolTrenEdit;
    obj['fingerprint'] = this.mod.fingerprint();
    let frmTmp: string = JSON.stringify(obj);

    this.http.post<Aktivnost[]>(urlTmp, frmTmp).subscribe(rez => {

      this.objAktivnosti = rez;

      callback(rez);

    });

  }

  //Aktivnost Edit - vrijeme
  aktivnostQuickUpdate(callback) {

    let urlTmp: string = this.mod.webUrl + '/php/aktivnosti.php?token=' + this.mod.objKor.token + '&funk=aktivnostQuickUpdate';

    let obj = this.frmKalendarUnos.value;
    obj['fingerprint'] = this.mod.fingerprint();
    let frmTmp: string = JSON.stringify(obj);

    this.http.post<Aktivnost[]>(urlTmp, frmTmp).subscribe(rez => {

      //alert(JSON.stringify(rez));
      /* if (rez == 'ok') {
        this.mod.openSnackBar('Spremljeno!', '!');
      } else {
        this.mod.openSnackBar('Greška prilikom spremanja!', '!');
      } */

      /* this.objAktivnosti = rez; */

      callback(rez);

      //alert(JSON.stringify(this.objAktivnosti));

    });

  }



}
