import { Component, OnInit } from '@angular/core';
import { FrmFilterRez, StatKlijentService } from '../stat-klijent.service';

@Component({
  selector: 'app-stat-klijent-sidebar',
  templateUrl: './stat-klijent-sidebar.component.html',
  styleUrls: ['./stat-klijent-sidebar.component.scss']
})
export class StatKlijentSidebarComponent implements OnInit {

  constructor(
    public srvStatKlijent: StatKlijentService
  ) { }

  ngOnInit(): void {
  }

  //Prikaži
  prikazi() {

    this.srvStatKlijent.brojPosjeta(this.srvStatKlijent.frmFilter.value, (rez: FrmFilterRez[]) => {
      console.log(rez);
      this.srvStatKlijent.ucitajLineChart(rez);
    });

  }
}
