import { Component, OnInit, ViewChild, DoCheck, Output, EventEmitter } from '@angular/core';
import { KalendarService, Aktivnost } from '../kalendar.service';
import { MatCalendarCellCssClasses } from '@angular/material/datepicker';
import { MatCalendar } from '@angular/material/datepicker';
import { Moment } from 'moment';
import * as moment from 'moment';

export interface DatTmp {
  datum: Moment,
  akt: boolean
}

@Component({
  selector: 'app-kalendar',
  templateUrl: './kalendar.component.html',
  styleUrls: ['./kalendar.component.scss']
})

export class KalendarComponent implements OnInit, DoCheck {

  constructor(
    public srvKalendar: KalendarService
  ) { }

  @ViewChild('calendar') calendar: MatCalendar<Moment>;

  @Output() selectedDate = new EventEmitter<DatTmp>();

  objAktTmp: Aktivnost[];

  ngOnInit() {
    this.objAktTmp = this.srvKalendar.objAktivnosti;
  }

  ngDoCheck() {

    if (this.objAktTmp != this.srvKalendar.objAktivnosti) {
      
      this.calendar.updateTodaysDate();
      this.objAktTmp = this.srvKalendar.objAktivnosti;
      //alert(JSON.stringify(this.objAktTmp));
    }

  }

  //Označavanje datuma
  dateClass() {

    return (date: Moment): MatCalendarCellCssClasses => {

      /* if (this.srvKalendar.objAktivnosti.some(e => moment(e.PocetakAkt).format('YYYY-MM-DD') == moment(date).format('YYYY-MM-DD'))) {

        if (moment(date) > moment()) {
          return 'na-cekanju';
        }

        
      } else {
        return;
      } */

      let strClass: string;

      this.srvKalendar.objAktivnosti?.forEach((val, ind) => {

        if (moment(val.PocetakAkt).format('YYYY-MM-DD') == date.utc(false).format('YYYY-MM-DD')) {

          if (moment(date) >= moment()) {
            strClass = 'na-cekanju';
          } 
          if (moment(date) < moment()) {
            strClass = 'prosao';
          }
          if (val.statusAkt == 1) {
            strClass = 'otkazan';
          }

        } 

      });

      return strClass;

    };

  }

  //Datum click
  odabraniDatum(dat: Moment) {

    let objTmp: DatTmp = {akt: false, datum: moment()};
    
    objTmp.datum = dat;

    console.log(objTmp);

    if (this.srvKalendar.objAktivnosti.some(e => moment(e.PocetakAkt).format('YYYY-MM-DD') == moment(objTmp.datum).format('YYYY-MM-DD'))) {
      objTmp.akt = true;
    } else {
      objTmp.akt = false;
    }

    //Emitter - odabran datum
    this.selectedDate.emit(objTmp);
    

  }

}
