import { Component, OnInit } from '@angular/core';
import { ClanoviService } from '../clanovi.service';
import { ModulService, PosJedinice } from '../modul.service';
import { PozivniceService } from '../pozivnice.service';

@Component({
  selector: 'app-pozivnica',
  templateUrl: './pozivnica.component.html',
  styleUrls: ['./pozivnica.component.scss']
})
export class PozivnicaComponent implements OnInit {

  constructor(
    private mod: ModulService,
    public srvPozivnice: PozivniceService,
    public srvClanovi: ClanoviService
  ) { }

  objPosJed: PosJedinice[]

  ngOnInit(): void {

    let tok: string;
    if (localStorage.getItem('token')) {
      tok = localStorage.getItem('token');
    } else {
      tok = this.mod.objKor.token;
    }

    //Provjera tokena
    this.mod.provjeraTokena(tok, () => {

      this.mod.provjeraPrijaveTrenOper(() => {

        this.mod.ucitajPosJed(() => {

          if (this.mod.objPosJed) {
            this.objPosJed = this.mod.objPosJed;
          } else {

            this.srvClanovi.posjedByTrenOper(this.mod.session.KlijentIDtren, this.mod.session.KoriniskIDtren, this.mod.session.clanVrstaIDtren, (rez: PosJedinice[]) => {
              this.objPosJed = rez;
            });

          }

        });
      });
    });

  }

  cmbCheck(chkID: number) {



  }

}
