import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { Moment } from 'moment';
import { Grupa } from './grupe.service';
import { Aktivnost } from './kalendar.service';
import { ModulService, PosJedinice } from './modul.service';

export interface TerminiFilter {
  kliID: number,
  datOd: Moment,
  datDo: Moment,
  pjedID: number,
  grpID: number
}

export interface PosJed {
  PoslJedID: number,
  NazivPos: string
}

export interface TerminiRez extends Aktivnost, Grupa {
  NazivPos: string
}

export interface TerminiRezGrp {
  datum: Moment,
  termini: TerminiRez[];
}

@Injectable({
  providedIn: 'root'
})
export class TerminiService {

  constructor(
    private mod: ModulService,
    private http: HttpClient,
    public frmBuilder: FormBuilder
  ) { 
    moment.locale('hr');
  }

  frmTerminFilter: FormGroup
  objPosJed: PosJedinice[];
  objTermini: TerminiRezGrp[];

  //napravi frmTerminFilter
  napraviFrmTerminFilter(callback) {

    let klTmp: number

    if (this.mod.session) {
      klTmp = this.mod.session.KlijentIDtren;
    } else {
      klTmp = this.mod.objKor.korisnikID
    }

    this.frmTerminFilter = this.frmBuilder.group({
      kliID: [klTmp],
      datOd: [moment()],
      datDo: [moment()],
      danUtjednu: ['0'],
      pjedID: ['0'],
      grpID: ['0']
    });

    callback(this.frmTerminFilter.value);

  }

  //filterTermini
  filterTermini(val: TerminiFilter, callback) {

    val.datOd = moment(val.datOd, "YYYY-MM-DD");

    let url: string = this.mod.webUrl + '/php/aktivnosti.php?token=' + this.mod.objKor.token + '&funk=filterTermini';
    val['fingerprint'] = this.mod.fingerprint();
    let obj: string = JSON.stringify(val);

    this.http.post(url, obj).subscribe((rez: TerminiRezGrp[]) => {
      callback(rez);
    });

  }

}
