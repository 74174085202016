import { AfterViewInit, Component } from '@angular/core';
import { ModulService } from '../modul.service';
import { ClanarineService, Vclanarina } from '../clanarine.service';
import { ClanoviService } from '../clanovi.service';
import * as moment from 'moment';
import { ClanEditService } from '../clan-edit.service';

@Component({
  selector: 'app-clan-edit-sidebar',
  templateUrl: './clan-edit-sidebar.component.html',
  styleUrl: './clan-edit-sidebar.component.scss'
})
export class ClanEditSidebarComponent implements AfterViewInit {

  constructor(
    public mod: ModulService,
    public srvClanovi: ClanoviService,
    public srvClanarine: ClanarineService,
    public srvClanEdit: ClanEditService
  ) { }

  ngAfterViewInit(): void {
    
    
  }


}
