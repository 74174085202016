<p *ngIf="srvClanovi.frmOdjava" style="font-size: 1.3rem;">{{srvClanovi.frmOdjava.controls['ime'].value}}</p>
<span style="margin-left: 10px; font-size: 1.1rem; color: brown;">Prijava:</span>
<p *ngIf="srvClanovi.frmOdjava" style="margin-left: 10px; font-size: 1.3rem;">
    {{srvClanovi.frmOdjava.controls['vrijemePrijava'].value | amDateFormat: 'DD.MM.YYYY. HH:mm'}}</p>

<span style="margin-left: 10px; font-size: 1.1rem; color: brown;">Odjava:</span>

<form *ngIf="srvClanovi.frmOdjava" [formGroup]="srvClanovi.frmOdjava" style="font-size: 1.3rem;">

    <!--Datepicker Datum DO-->
    <mat-form-field style="width: 170px; margin-right: 15px; text-align: center;">
        <mat-label>Datum</mat-label>
        <input matInput name="dat1" formControlName="odjDat" [min]="minDate" [matDatepicker]="picker"
            placeholder="Datum">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker touchUi #picker></mat-datepicker>
    </mat-form-field>

    <mat-form-field style="width: 60px; text-align: center;">
        <mat-label>Sati</mat-label>
        <input matInput type="tel" formControlName="odjH" placeholder="Sati" required>
    </mat-form-field> :

    <mat-form-field style="width: 60px; text-align: center;">
        <mat-label>Min.</mat-label>
        <input matInput type="tel" formControlName="odjMin" placeholder="Min." required>
    </mat-form-field>

    <mat-error
        *ngIf="(srvClanovi.frmOdjava.controls['odjDat'].errors || srvClanovi.frmOdjava.controls['odjH'].errors || srvClanovi.frmOdjava.controls['odjMin'].errors)">
        Sva polja moraju biti ispravno popunjena</mat-error>
    <mat-error
        *ngIf="srvClanovi.frmOdjava.errors?.veci && !srvClanovi.frmOdjava.controls['odjDat'].errors && !srvClanovi.frmOdjava.controls['odjH'].errors && !srvClanovi.frmOdjava.controls['odjMin'].errors">
        Vrijeme odjave ne može biti manje od vremena prijave
    </mat-error>
</form>

<div *ngIf="srvClanovi.frmOdjava && srvClanovi.frmOdjava.valid && trajanjePosjeta()">
    <span style="margin-left: 10px; font-size: 1.1rem; color: brown;">Trajanje: </span>
    <p style="margin-left: 10px; color: green; font-size: 1.3rem;">{{trajanjePosjeta()}}</p>
</div>

<div>
    <button type="submit"
        class="btn {{srvClanovi.frmOdjava && srvClanovi.frmOdjava.valid ? 'mybtn-success' : 'mybtn-danger'}}"
        (click)="odjaviClana(srvClanovi.frmOdjava.controls['trenID'].value)"
        style="width: 100%; font-size: 1.2rem;">ODJAVI</button>
</div>