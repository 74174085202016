<form *ngIf="srvClanarine.frmClanarina" [formGroup]="srvClanarine.frmClanarina">

    <div class="row">
        <!--Datum OD-->
        <div class="col-6" style="padding: 5px;">
            <mat-form-field style="width: 100%; text-align: center;">
                <input autocomplete="off" matInput formControlName="claVrijediOD" [matDatepicker]="picker1" placeholder="Datum od">
                <mat-error *ngIf="srvClanarine.frmClanarina.get('claVrijediOD').errors?.required" style="font-size: 1rem;">Obavezan unos</mat-error>
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker touchUi #picker1></mat-datepicker>
            </mat-form-field>
        </div>
        <!--Datum DO-->
        <div class="col-6" style="padding: 5px;">
            <mat-form-field style="width: 100%; text-align: center;">
                <input autocomplete="off" matInput formControlName="claVrijediDO" [matDatepicker]="picker2" placeholder="Datum do">
                <mat-error *ngIf="srvClanarine.frmClanarina.get('claVrijediDO').errors?.required" style="font-size: 1rem;">Obavezan unos</mat-error>
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker touchUi #picker2></mat-datepicker>
            </mat-form-field>
        </div>
    </div>
    <mat-error *ngIf="srvClanarine.frmClanarina.errors?.err" class="w-100 text-center" style="font-size: 1rem;">{{srvClanarine.frmClanarina.errors.err}}</mat-error>

    <div class="row">
        <!--Grupa select-->
        <div class="col-12" style="padding: 5px;">
            <mat-form-field style="width: 100%;">
                <mat-label>Grupa</mat-label>
                <mat-select formControlName="claGrupaID" (selectionChange)="odabirGrupe($event.value)">
                    <mat-option *ngFor="let x of srvGrupe.objGrupe" [value]="x.grpID" [attr.gluvi]="x.grpClanarina">{{x.grpOpis}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <div class="row">
        <!--Iznos-->
        <div class="col-12 justify-content-center" style="padding: 5px;">
            <mat-form-field class="w-100 text-center">
                <mat-label>Iznos</mat-label>
                <input autocomplete="off" matInput formControlName="claIznos" aria-label="claIznos" placeholder="Iznos" (blur)="srvClanarine.decFormat($event)" required>
            </mat-form-field>
        </div>
    </div>

    <div class="row">
        <!--Chk Plaćena-->
        <div class="col-6 d-flex align-items-center" style="cursor: pointer;" (click)="placanjeChk()">
            <i *ngIf="srvClanarine.frmClanarina.get('intPlacena').value == 0" class="fa fa-square-o" aria-hidden="true"></i>
            <i *ngIf="srvClanarine.frmClanarina.get('intPlacena').value == 1" class="fa fa-check-square" aria-hidden="true"></i>
            <span style="margin-left: 5px;">Plaćena</span>
        </div>
        <!--Datum uplate-->
        <div class="col-6" style="padding: 5px;">
            <mat-form-field *ngIf="srvClanarine.frmClanarina.get('intPlacena').value == 1" style="width: 100%; text-align: center;">
                <input autocomplete="off" matInput formControlName="claDatumUplate" [matDatepicker]="picker3" placeholder="Datum plaćanja">
                <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                <mat-datepicker touchUi #picker3></mat-datepicker>
            </mat-form-field>
        </div>
    </div>

</form>