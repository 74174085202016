import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { ClanoviService } from '../clanovi.service';
import { Grupa, GrupeService } from '../grupe.service';
import { ModulService, PosJedinice } from '../modul.service';
import { ObavijestiSidebarComponent } from '../obavijesti-sidebar/obavijesti-sidebar.component';
import { Obavijest, ObavijestiService, VobavRez } from '../obavijesti.service';

interface Info {
  neprocitano: number,
  ukupno: number
}

@Component({
  selector: 'app-obavijesti-panel',
  templateUrl: './obavijesti-panel.component.html',
  styleUrls: ['./obavijesti-panel.component.scss']
})
export class ObavijestiPanelComponent implements OnInit, OnDestroy {

  constructor(
    public mod: ModulService,
    public srvObavijesti: ObavijestiService,
    public srvClanovi: ClanoviService,
    public srvGrupe: GrupeService
  ) { }


  //Timer 2 sec
  s: Subscription;
  t = timer(1000, 2000);

  ObavijestID: number; //Odabrana obavijest
  obvDelID: number; //Odabrana obavijest za brisanje

  info: Info;

  objVobavRezSel: VobavRez;

  ngOnInit() {

    let tok: string;
    if (localStorage.getItem('token')) {
      tok = localStorage.getItem('token');
    } else {
      tok = this.mod.objKor.token;
    }

    this.srvObavijesti.objVobavRez = null;
    this.srvObavijesti.objSent = null;

    this.mod.provjeraTokena(tok, () => {

      //Provjera prijave trener / Operater
      this.mod.provjeraPrijaveTrenOper((rez) => {

        //Učitavanje poslovnih jedinica
        this.mod.ucitajPosJed(() => {

          if (this.mod.objKor.vrstaID == 1) {
            this.srvObavijesti.intVrsta = 1;
          } else {
            this.srvObavijesti.intVrsta = 2;
          }
          this.ucitajPodatke();
          this.subscribeTimer();
        });
      });

    });

  }

  //Učitavanje
  ucitajPodatke() {

    if (this.mod.objKor) {

      if (this.mod.objKor.vrstaID == 1) {


        if (this.mod.session) {

          //Primljene by Član ID
          this.srvObavijesti.fillByClanID(this.mod.session.KoriniskIDtren, (rez: VobavRez[]) => {
            if (rez && JSON.stringify(rez) != JSON.stringify(this.srvObavijesti.objVobavRez)) {
              this.srvObavijesti.objVobavRez = rez;
              this.fnInfo();
            }
          });

          //Poslane by Član ID (treneri i operateri)
          this.srvObavijesti.fillByClanIDsent(this.mod.session.KoriniskIDtren, (rez: Obavijest[]) => {
            if (rez && JSON.stringify(rez) != JSON.stringify(this.srvObavijesti.objSent)) {
              this.srvObavijesti.objSent = rez;
            }
          });

        } else {

          //Primljene by fitNet ID
          this.srvObavijesti.fillByKorIdPrivatni(this.mod.objKor.korisnikID, (rez: VobavRez[]) => {
            if (rez && JSON.stringify(rez) != JSON.stringify(this.srvObavijesti.objVobavRez)) {
              this.srvObavijesti.objVobavRez = rez;
              this.fnInfo();
            }
          });

          //Poslane by korisnik ID - privatni i poslovni
          this.srvObavijesti.fillByKorIdPrivSent(this.mod.objKor.korisnikID, (rez: Obavijest[]) => {
            if (rez && JSON.stringify(rez) != JSON.stringify(this.srvObavijesti.objSent)) {
              this.srvObavijesti.objSent = rez;
            }
          });

        }

      } else {

        //Poslane by korisnik ID - privatni i poslovni
        this.srvObavijesti.fillByKorIdPoslSent(this.mod.objKor.korisnikID, (rez: Obavijest[]) => {
          if (rez && JSON.stringify(rez) != JSON.stringify(this.srvObavijesti.objSent)) {
            this.srvObavijesti.objSent = rez;
          }
        });

      }

      this.fnInfo();

    }

  }


  //Subscribe timer
  subscribeTimer() {
    this.s = this.t.subscribe(t => {
      this.ucitajPodatke();
    });
  }

  //Info
  fnInfo() {

    if (this.srvObavijesti.objVobavRez) {

      let neprocitano: number = 0;
      let ukupno: number = 0;

      this.srvObavijesti.objVobavRez.forEach(val => {
        ukupno += 1;
        if (val.vocStatus == 0) {
          neprocitano += 1;
        }
      })

      this.info = { ukupno: ukupno, neprocitano: neprocitano }

    }

  }


  //Click - obavijest menu
  obvMenuClick(x: VobavRez) {
    this.objVobavRezSel = x;
  }

  //Modal obavijest
  modalObavijest(x: VobavRez, i: number) {
    this.objVobavRezSel = x;

    this.srvObavijesti.oznProcitano(x.vocID, (rez: string) => {

      this.s.unsubscribe();

      if (rez == 'ok') {

        this.srvObavijesti.objVobavRez[i].vocStatus = 1;

        this.fnInfo();

        document.getElementById('btnMdlObvSel').click();
      }

      this.subscribeTimer();

    })

  }

  //Označi kao nepročitano
  oznNeprocitano(x: VobavRez, i: number) {
    this.s.unsubscribe();
    this.srvObavijesti.oznNeprocitano(x.vocID, (rez: string) => {
      if (rez == 'ok') {
        this.srvObavijesti.objVobavRez[i].vocStatus = 0;
        this.fnInfo();
        this.subscribeTimer();
      }
    });
  }

  //Označi kao pročitano
  oznProcitano(x: VobavRez, i: number) {
    this.s.unsubscribe();
    this.srvObavijesti.oznProcitano(x.vocID, (rez: string) => {
      if (rez == 'ok') {
        this.srvObavijesti.objVobavRez[i].vocStatus = 1;
        this.fnInfo();
        this.subscribeTimer();
      }
    });
  }

  ngOnDestroy() {
    this.s.unsubscribe();
    document.getElementById('btnSidebarClose').click();
    document.getElementById('btnMdlNovaObavijestClose').click();
    if (this.objVobavRezSel) {
      document.getElementById('btnObSelClose').click();
    }
    document.getElementById('btnMdlNovaPozivnicaClose').click();
  }


}
