import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Clanarina, ClanarineService, VclanarinaGrupa } from '../clanarine.service';
import { ClanoviService } from '../clanovi.service';
import { Grupa, GrupeService } from '../grupe.service';
import { ModulService } from '../modul.service';

@Component({
  selector: 'app-clanarine',
  templateUrl: './clanarine.component.html',
  styleUrls: ['./clanarine.component.scss']
})
export class ClanarineComponent implements OnInit, OnDestroy {

  constructor(
    public mod: ModulService,
    public srvClanovi: ClanoviService,
    public srvClanarine: ClanarineService,
    public srvGrupe: GrupeService,
    private router: Router
  ) { }

  @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
    localStorage.setItem('clanID', this.srvClanovi.clanID.toString());
  }

  @ViewChild('btnSidebar') btnSidebar: ElementRef;

  bolBrisanje: boolean;

  ngOnInit(): void {

    this.srvClanarine.intUnosTmp = 0;

    moment.locale('hr');

    let tok: string;
    if (localStorage.getItem('token')) {
      tok = localStorage.getItem('token');
    } else {
      tok = this.mod.objKor.token;
    }

    this.mod.provjeraTokena(tok, () => {

      this.mod.provjeraPrijaveTrenOper(() => {

        this.mod.ucitajPosJed(() => {

          if (this.srvClanovi.clanID || localStorage.getItem('clanID')) {

            if (localStorage.getItem('clanID')) {
              this.srvClanovi.clanID = Number(localStorage.getItem('clanID'));
              localStorage.removeItem('clanID')
            }

            this.srvGrupe.grupeByClan(this.srvClanovi.clanID, (rez: Grupa[]) => {
              console.log(this.srvClanovi.clanID);
              console.log(rez);
              this.srvGrupe.objGrupe = rez;
            });

            this.srvClanarine.clanarineByClanID(this.srvClanovi.clanID, (rez: VclanarinaGrupa[]) => {

              if (rez) {
                this.srvClanarine.objClanarine = rez;
                this.srvClanarine.objClanarinaLast = rez[0];
              } else {
                this.srvClanarine.objClanarine = null;
                this.srvClanarine.objClanarinaLast = null;
              }

            });

          } else {
            this.router.navigate(['clanovi']);
          }
        });

      });
    });
  }

  //Uređivanje članarine
  urediClanarinu(x: Clanarina) {

    this.bolBrisanje = false;
    x.claKorClID = this.mod.objPosJedSelected.KlijentIDpos;
    this.srvClanarine.napraviFrmClanarina(x, () => {
      console.log(x);
      document.getElementById('btnMdlClanarina').click();
    });

  }

  //Spremanje nove članarine
  spremiClanarinu() {

    if (this.srvClanarine.frmClanarina.valid) {

      this.srvClanarine.spremiClanarinu(this.srvClanarine.frmClanarina.value, (rez: VclanarinaGrupa) => {

        if (rez) {

          document.getElementById('btnMdlClanarinaClose').click();

          if (this.srvClanarine.frmClanarina.get('claID').value) {

            this.srvClanarine.objClanarine.forEach((val, ind) => {
              if (val.claID == rez.claID) {
                this.srvClanarine.objClanarine.splice(ind, 1, rez);
              }
            });

          } else {

            if (!this.srvClanarine.objClanarine) {
              this.srvClanarine.objClanarine = [];
            }

            this.srvClanarine.objClanarine.unshift(rez);


          }

          console.log(this.srvClanarine.objClanarine);
          this.srvClanarine.objClanarinaLast = this.srvClanarine.objClanarine[0];

          this.mod.msgBox(1, "Spremljeno", 1000);
        } else {
          this.mod.msgBox(2, "Greška prilikom spremanja!", 3000);
        }
      });

    } else {
      this.mod.msgBox(2, "Popunite ispravno sva polja!", 3000);
    }

  }

  //Brisanje članarine
  obrisiClanarinu() {

    let claID: number = this.srvClanarine.frmClanarina.get('claID').value;

    this.srvClanarine.obrisiClanarinu(this.srvClanarine.frmClanarina.value, (rez: string) => {

      if (rez == 'ok') {

        this.srvClanarine.objClanarine.forEach((val, ind) => {

          document.getElementById('btnMdlClanarinaClose').click();

          if (val.claID == claID) {
            this.srvClanarine.objClanarine.splice(ind, 1);
          }

        });

        this.mod.msgBox(1, "Članarina je uspješno obrisana! :)", 2000);

      } else {
        this.mod.msgBox(2, "Greška! članarina nije obrisna. :(", 2000);
      }

    });

  }

  //Sidebar
  openSidebar() {
    /* document.getElementById('btnSidebar').click(); */
    this.btnSidebar.nativeElement.click();
  }

  ngOnDestroy() {
    this.srvClanarine.objClanarine = null;
  }

}
