import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { ModulService, PosJedinice } from '../modul.service';
import { Grupa, GrupeService } from '../grupe.service';
import { Router } from '@angular/router';
import { KalendarService } from '../kalendar.service';
import { FormGroup } from '@angular/forms';
import { listFadeIn } from '../animacije';
import { ClanoviService } from '../clanovi.service';
import * as moment from 'moment';


@Component({
  selector: 'app-grupe',
  templateUrl: './grupe.component.html',
  styleUrls: ['./grupe.component.scss'],
  animations: [listFadeIn]
})
export class GrupeComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(
    public mod: ModulService,
    private router: Router,
    /* public mod: ModulService, */
    public srvGrupe: GrupeService,
    public srvKalendar: KalendarService,
    public srvClanovi: ClanoviService
  ) { }

  @ViewChild('btnModalNoviCl') btnModalNoviCl: ElementRef;
  @ViewChild('btnMdlNovaGrupaClose') btnMdlNovaGrupaClose: ElementRef;
  /* @ViewChild('btnModalNovaGrupa', {static: false}) btnModalNovaGrupa: ElementRef; */

  intTab: number = 1;

  frmNovaGrupa: FormGroup;

  pjedTmp: number;
  objPosJedinice: PosJedinice[];

  objAktivne: Grupa[];
  objNeaktivne: Grupa[];
  objArhiva: Grupa[];

  objGrupaShow: Grupa[];

  ngOnInit() {

    let tok: string;
    if (localStorage.getItem('token')) {
      tok = localStorage.getItem('token');
    } else {
      tok = this.srvGrupe.mod.objKor.token;
    }

    this.srvGrupe.mod.provjeraTokena(tok, () => {

      /* this.srvGrupe.mod.session = null; */
      this.srvGrupe.mod.provjeraPrijaveTrenOper(() => {

        this.srvGrupe.mod.ucitajPosJed(() => {
          this.pocetnePostavke();
        });

      });

    });

  }

  //Početne postavke
  pocetnePostavke() {

    this.pjedTmp = this.srvGrupe.mod.objPosJedSelected.PoslJedID;

    this.srvGrupe.objGrupe = null;

    this.frmNovaGrupa = this.srvGrupe.fnNovaGrupa();

    if (this.mod.objKor.vrstaID == 2) {
      this.srvGrupe.ucitajGrupe((rez) => {
        this.srvGrupe.objGrupe = rez;
        this.razvrstajGrupe();
      });
    } else {
      this.srvGrupe.fillByKorisnikID((rez) => {
        this.srvGrupe.objGrupe = rez;
        this.razvrstajGrupe();
      })
    }

    this.postaviPosJedinice();

  }

  //Razvrstavanje grupa po objektima (aktivne, neaktivne, arhiva)
  razvrstajGrupe() {

    this.objAktivne = [];
    this.objNeaktivne = [];
    this.objArhiva = [];

    if (this.srvGrupe.objGrupe) {

      this.srvGrupe.objGrupe.forEach(val => {

        if (val.grpAkt == 1 && (!val.grpDatumDO || moment(val.grpDatumDO) >= moment())) {
          this.objAktivne.push(val);
        }

        if (val.grpAkt == 0 && (!val.grpDatumDO || moment(val.grpDatumDO) >= moment())) {
          this.objNeaktivne.push(val);
        }

        if (moment(val.grpDatumDO) < moment()) {
          this.objArhiva.push(val);
        }

      });

      if (this.intTab == 1) {
        this.objGrupaShow = this.objAktivne;
      } else if (this.intTab == 2) {
        this.objGrupaShow = this.objNeaktivne;
      } else if (this.intTab == 3) {
        this.objGrupaShow = this.objArhiva;
      } else {
        this.objGrupaShow = null;
      }

    }

  }

  //Postavljanje poslovnih jedinica
  postaviPosJedinice() {

    if (this.mod.objKor.vrstaID == 1) {
      this.srvClanovi.posjedByTrenOper(this.mod.session.KlijentIDtren, this.mod.session.KoriniskIDtren, this.mod.session.clanVrstaIDtren, (rez: PosJedinice[]) => {
        this.objPosJedinice = rez;
      })
    } else {
      this.objPosJedinice = this.mod.objPosJed;
    }



  }

  ngAfterViewInit() {

  }

  //Odabrana poslona jedinica - emitter
  odabranaPosJed(obj: PosJedinice) {

    this.srvGrupe.mod.objPosJedSelected = obj;

    this.pjedTmp = this.srvGrupe.mod.objPosJedSelected.PoslJedID;
    this.srvGrupe.ucitajGrupe((rez) => {
      this.srvGrupe.objGrupe = rez;
      this.razvrstajGrupe();
    });
    document.getElementById('btnSidebarClose').click();

  }

  //Članovi - link
  linkGrupaClanovi(x: Grupa) {

    this.srvGrupe.objGrupa = x;
    this.srvKalendar.fnKalendarUnos(x.grpID, () => {});
    this.srvKalendar.frmKalendarUnos.controls['grpID'].setValue(this.srvGrupe.objGrupa.grpID);

    localStorage.objGrupa = JSON.stringify(this.srvGrupe.objGrupa);

    this.router.navigate(['grupa-clanovi']);

  }

  //Nova grupa
  novaGrupaStart() {

    this.frmNovaGrupa = this.srvGrupe.fnNovaGrupa();

    document.getElementById('btnModalNovaGrupa').click();

  }

  novaGrupaCommit() {

    this.frmNovaGrupa.markAllAsTouched();

    if (this.frmNovaGrupa.valid) {

      this.srvGrupe.novaGrupa(this.frmNovaGrupa.value, (rez) => {

        if (rez) {
          this.pocetnePostavke();
          this.btnMdlNovaGrupaClose.nativeElement.click();
        } else {
          this.frmNovaGrupa.setErrors({ err: 'Greška prilikom spremanja!' })
        }

      });
    }

  }

  //Formatiranje decimalnog broja
  decFormat(event) {
    let strNaziv: string = event.srcElement.ariaLabel;
    if (this.frmNovaGrupa.get(strNaziv).value) {
      this.frmNovaGrupa.get(strNaziv).setValue(this.frmNovaGrupa.get(strNaziv).value.replace(',', '.'));
    }
  }

  ngOnDestroy() {
    this.srvGrupe.objGrupe = null;
    document.getElementById('btnSidebarClose').click();
    document.getElementById('btnNovaGrpClose').click();
    /* this.btnMdlNovaGrupaClose.nativeElement.click(); */
  }

}
