import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ModulService } from './modul.service';
import { MjestaRH } from './modul.service';

@Injectable({
  providedIn: 'root'
})
export class MjestaService {

  constructor(
    private http: HttpClient,
    public mod: ModulService
  ) { }

  //Objekt Mjesta
  objMjestaRH: MjestaRH[];
  fltMjesta: MjestaRH[];

  //UČITAJ MJESTA
  ucitajMjestaRH(callback) {

    let urlTmp: string = this.mod.webUrl + '/php/mjesta.php';

    this.http.get<MjestaRH[]>(urlTmp).subscribe(rez => {
      this.objMjestaRH = rez;
      this.fltMjesta = [];
      localStorage.objMjestaRH = JSON.stringify(rez);

      //alert('mjesta loaded');

      callback();

    })

  }

  //FILTRIRANJE MJESTA 
  filtrirajMjestaRH(strP: string) {
    if (this.objMjestaRH) {
      //this.fltMjesta = this.objMjestaRH.filter((s) => {s.Mjesto.toLowerCase().includes(strP.toLowerCase())});
      this.fltMjesta = this.objMjestaRH.filter(s => s.Mjesto.toLowerCase().includes(strP.toLowerCase()));
    }
  }

}
