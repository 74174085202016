import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { ModulService } from './modul.service';

export interface FrmFilter {
  klijentID: number,
  datOd: moment.Moment,
  datDo: moment.Moment
}

export interface FrmFilterRez {
  countID: number,
  datum: moment.Moment
}

@Injectable({
  providedIn: 'root'
})
export class StatKlijentService {

  constructor(
    private mod: ModulService,
    private http: HttpClient,
    public frmBuilder: FormBuilder
  ) { }

  frmFilter: FormGroup
  lcPosjete;

  napraviFrmFilter() {

    this.frmFilter = this.frmBuilder.group({
      klijentID: [this.mod.objKor.korisnikID],
      datOd: [moment()],
      datDo: [moment()]
    });

  }
  //Učitavanje podataka za line Chart
  ucitajLineChart(rez: FrmFilterRez[]) {

    this.lcPosjete.data = [];

    let rowTmp: number = 0;

    rez.forEach(val => {
      let tmp: FrmFilterRez = val;
      let pTmp = [moment(val.datum).format('DD.MM.YY.'), Number(val.countID)];
      this.lcPosjete.data.push(pTmp);
      rowTmp += 1;
    });

    if (rowTmp <= 7) {
      rowTmp = 700;
    } else {
      rowTmp = rowTmp * 200;
    }

    this.lcPosjete.width = rowTmp;

    console.log(this.lcPosjete.data);

  }

  //Line Chart - posjete
  napraviLcPosjete() {

    this.lcPosjete = {
      type: 'LineChart',
      data: [
        ["Jan", 500, 600],
        ["Feb", 800, 900],
        ["Mar", 400, 600],
        ["Apr", 600, 500],
        ["May", 400, 300],
        ["Jun", 750, 700],
        ["Jul", 800, 710],
        ["Aug", 810, 720],
        ["Sep", 820, 730],
        ["Oct", 900, 840],
        ["Nov", 910, 850],
        ["Dec", 920, 890]
      ],
      options: {
        legend: { position: 'none' },
        chartArea: {
          bottom: 120,
          top: 20,
          left: 50,
          right: 10
        }/* ,
        hAxis: {
          title: 'test'
        } */
      },
      width: 1000,
      height: 600,
      backgroundColor: 'transparent'
    };

  }

  //Broj posjeta by Klijent ID
  brojPosjeta(val: FrmFilter, callback) {

    let url: string = this.mod.webUrl + '/php/stat-klijent.php?token=' + this.mod.objKor.token + '&funk=brojPosjeta';
    val['fingerprint'] = this.mod.fingerprint();
    let obj: string = JSON.stringify(val);

    this.http.post(url, obj).subscribe((rez: FrmFilterRez[]) => {
      callback(rez);
    });

  }

}
