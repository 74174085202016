import { FormControl, FormGroup } from '@angular/forms';
import { Moment } from 'moment';
import * as moment from 'moment';

// Is number validator
export function isNum(v: FormControl) {

    if (isNaN(v.value)) {

        return { err: 'polje mora biti broj' };

    } 

}

//Validator - maxH
export function maxH(v: FormControl) {

    if (!isNaN(v.value)) {

        if (parseInt(v.value) > 23 || parseInt(v.value) < 0) {
            return { err: 'upišite broj od 0-23' };
        } else {
            return null;
        }

    } else {
        return { err: 'polje mora biti broj' };
    }

}

//Validator - maxMin
export function maxMin(v: FormControl) {

    if (!isNaN(v.value)) {

        if (parseInt(v.value) > 59 || parseInt(v.value) < 0) {
            return { err: 'upišite broj od 0-59' };
        } else {
            return null;
        }

    } else {
        return { err: 'polje mora biti broj' };
    }

}

//Must Match
export function MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];

        if (matchingControl.errors && !matchingControl.errors.mustMatch) {
            // return if another validator has already found an error on the matchingControl
            return;
        }

        // set error on matchingControl if validation fails
        if (control.value !== matchingControl.value) {
            control.setErrors({ mustMatch: true });
        } else {
            //control.setErrors(null);
        }
    }
}

//DatumVrijeme - dat1 < dat2
export function datumVrijemeCompare(frm: FormGroup) {

    let d1: Moment = moment(frm.get('datumOD').value);
    let h1: string = frm.get('hOD').value;
    let m1: string = frm.get('mOD').value;
    let h2: string = frm.get('hDO').value;
    let m2: string = frm.get('mDO').value;
    let d2: Moment = frm.get('datumDO').value;
    let ponavljanje: boolean = frm.get('ponavljanje').value;
    let interval: number = frm.get('interval').value;

    let strTmp: string = d1.format('YYYY-MM-DD');
    let dat1: Moment = moment(strTmp);

    let strTmp2: string = d2.format('YYYY-MM-DD');
    let dat2: Moment = moment(strTmp2);

    if (dat1 > dat2 && ponavljanje) {
        return { err: 'Završni datum ne može biti manji od početnog' };

    } else if (parseInt(h2) == 0 && parseInt(m2) == 0) {
        return { err: 'Trajanje ne može biti manje od 1 min.' };

    } else if (ponavljanje && !interval) {
        return { err: 'Odaberite interval ponavljanja' };

    } else {
        return null;
    }

}

//Provjera datuma - drugi ne može biti manji od prvog
export function datumClanarine(frm: FormGroup) {

    let d1: Moment = moment(moment(frm.get('claVrijediOD').value).format('YYYY-MM-DD'));
    let d2: Moment = moment(moment(frm.get('claVrijediDO').value).format('YYYY-MM-DD'));

    if (d2 < d1) {
        return { err: '"Datum od" ne smije biti veći od "Datuma do"'};
    }

}

//Validator - provjera vrijeme prijava - odjava (veće/manje)
export function VrijemeOdjava(frm: FormGroup) {

    // alert(JSON.stringify(frm.value));

    if (frm) {
        //Datum i vrijeme - prijava
        //let vV1 = frm.controls['vrijemePrijava'].value;
        let vV1 = frm.get('vrijemePrijava').value;

        //Datum odjava
        //let vV2 = frm.controls['odjDat'].value;
        let vV2 = frm.get('odjDat').value;

        //H odjavam
        //let vV3 = frm.controls['odjH'].value;
        let vV3 = frm.get('odjH').value;

        //Min odjava
        //let vV4 = frm.controls['odjMin'].value;
        let vV4 = frm.get('odjMin').value;


        let d1 = moment(vV1);
        let d2 = moment(vV2);
        let d3 = moment(moment(vV2).format('YYYY-MM-DD') + ' ' + vV3 + ':' + vV4 + ':00');

        //Uspoređivanje datuma i vremena
        if (d1 > d3) {
            return { veci: true };
        } else {
            return null;
        }

    } else {
        return null;
    }

}

//Validator - Provjera OIB-a
export function oibChk(v: FormControl) {

    let oib: string = v.value.toString();

    if (oib.length == 0) return null;

    if (oib.length != 11) return { err: 'OIB mora sadržavati 11 znamenki' };

    let b: number = parseInt(oib, 10);
    if (isNaN(b)) return { err: 'Svi znakovi moraju biti numerički' };

    let a: number = 10;
    for (let i = 0; i < 10; i++) {
        a = a + parseInt(oib.substr(i, 1), 10);
        a = a % 10;
        if (a == 0) a = 10;
        a *= 2;
        a = a % 11;
    }

    let kontrolni: number = 11 - a;
    if (kontrolni == 10) kontrolni = 0;

    if (kontrolni == parseInt(oib.substr(10, 1))) {
        return null;
    } else {
        return { err: 'Neispravan OIB' };
    }

}

//Validator frmSend - forma nova obavijest
//Must Match
export function FrmSend(frm: FormGroup) {

    if (frm.value.chkID == 2 && frm.value.posJedID == 0) {
        return { err: 'Odaberite poslovnu jedinicu!' };
    } else if (frm.value.chkID == 3 && frm.value.grpID == 0) {
        return { err: 'Odaberite grupu!' };
    }

}

//Decimal validator
export function decimalCheck(v: FormControl) {

    let vTmp = v.value.toString();

    let res = vTmp.match('^-?[0-9]\\d*(\\,\\d{1,})?$');
    let res1 = vTmp.match('^-?[0-9]\\d*(\\.\\d{1,})?$');
    let cDot = (vTmp.match(/,/g) || []).length

    if (v.hasError && v.getError('err')) {
        return null;
    } else {

        if ((res && res.length > 0 || res1 && res1.length > 0) && cDot < 2) {
        
        } else {
            return { err: 'Neispravan unos' };
        }

    }

}