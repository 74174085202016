import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Kor, ModulService, PosJedinice, vAktivnost } from '../modul.service';
import { HttpClient } from '@angular/common/http';
import { timer, Subscription } from 'rxjs';
import { Clan, ClanoviService, maxH, maxMin, TrenutnoPrisutni, VzahtjevKlijent, VzahtjevKorisnik } from '../clanovi.service';
import { Router, ActivatedRoute } from '@angular/router';
import { fadeIn, slideIn, listFadeIn, NavMsg } from '../animacije';
import { ClanstvaComponent, Clanstvo } from '../clanstva/clanstva.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { formatDate } from '@angular/common';
import * as moment from 'moment';
import { Aktivnost, KalendarService, Vaktivnost } from '../kalendar.service';
import { GrupeService, VclanZaPrijavu } from '../grupe.service';
import { AktivnostiService, TreneriByAktID } from '../aktivnosti.service';
import { Dvorana, DvoraneService } from '../dvorane.service';
import { ClanarineService, Vclanarina } from '../clanarine.service';

export interface PosJedClan {
  PoslJedID: number,
  NazivPos: string,
  kapacitet: number,
  naziv: string,
  prisutnih: number
}

@Component({
  selector: 'app-cpanel',
  templateUrl: './cpanel.component.html',
  styleUrls: ['./cpanel.component.scss'],
  animations: [fadeIn, slideIn, listFadeIn, NavMsg]
})

export class CpanelComponent implements OnInit, OnDestroy {

  constructor(
    private router: Router,
    public mod: ModulService,
    private http: HttpClient,
    public srvClanovi: ClanoviService,
    private actRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    public srvGrupe: GrupeService,
    public srvAktivnosti: AktivnostiService,
    public srvDvorane: DvoraneService,
    public srvkalendar: KalendarService,
    public srvClanarine: ClanarineService
  ) { }


  @ViewChild('btnPoslano') btnPoslano: ElementRef;
  @ViewChild('btnClLogin') btnClLogin: ElementRef;
  @ViewChild('clanstva') chlClanstva: ClanstvaComponent;

  objPosJedTrenOper: PosJedinice[];
  pjedTmp: PosJedinice; //privremeni odabir - prijava trener/operater
  //pjedNazivTmp: string = '';

  //info
  brPrisutnih: string = 'grey';
  kapacitet: number = 0;
  prisutnih: number = 0;
  noveObav: number = 0;

  //Timer 2 sec
  s: Subscription;
  t = timer(1000, 1000);

  //Charts
  pchPrisutni; //pie chart

  objClanCentri;

  aktivnostTmp: Aktivnost;
  bolGrpGost: boolean;
  bolGostSearch: boolean;

  objTreneri: Clan[];
  objDvoraneChart;

  aktZaClana: Vaktivnost[];
  tjPosjeta: number;
  clanarineByClan: Vclanarina[];

  ngOnInit() {

    this.srvClanovi.clanID = 0;
    this.srvGrupe.intUnosTermina = 0;

    this.srvClanovi.napraviFrmPrijava(() => {

      this.srvClanovi.frmPrijava.get('priDat').valueChanges.subscribe((rez01) => {

        let hTmp = this.srvClanovi.frmPrijava.get('priH').value;
        let minTmp = this.srvClanovi.frmPrijava.get('priMin').value;
        let datTmp: moment.Moment = moment(moment(this.srvClanovi.frmPrijava.get('priDat').value).format('YYYY-MM-DD') + ' ' + hTmp + ':' + minTmp);
        let clanID: number = this.srvClanovi.frmPrijava.get('KoriniskIDtren').value;
        let vrstaIDcl: number = this.srvClanovi.frmPrijava.get('vrstaIDcl').value;
        let PoslJedID: number = this.srvClanovi.frmPrijava.get('PosJedIDtren').value;

        this.srvClanovi.terminiZaClana(clanID, PoslJedID, datTmp, (rez2) => {
          console.log(rez2);
          this.aktZaClana = rez2;
          /* if (rez01) {
            rez01 = null;
          } */
        });

        this.srvClanovi.tjednihPosjeta(clanID, vrstaIDcl, PoslJedID, datTmp, (rez22) => {
          console.log(rez22);
          this.tjPosjeta = rez22;
        });

      });

    });

    let tok: string;
    if (localStorage.getItem('token')) {
      tok = localStorage.getItem('token');
    } else {
      if (this.mod.objKor) {
        tok = this.mod.objKor.token;
      } else {
        localStorage.clear();
        this.router.navigate['naslovnica'];
      }
    }

    //Provjera tokena
    this.mod.provjeraTokena(tok, () => {

      //Prvi Login poslije registracije
      if (this.mod.prviLogin) {
        this.mod.btnLoginClass = 'Odjava';
        document.getElementById('btnPoslano').click();
        this.mod.prviLogin = false;
      }

      if (this.mod.objKor.vrstaID == 1) {
        this.mod.provjeraPrijaveTrenOper((rez) => {
          this.mod.ucitajPosJed(() => {
            if (this.mod.objKor.vrstaID == 1 && !this.mod.objPosJedSelected) {
              this.cpanelClan();
            } else {
              this.cPanelInfo();
            }
            this.subscribeTimer();
            localStorage.clear();
          });
        });

      } else {
        this.mod.ucitajPosJed(() => {
          if (this.mod.objKor.vrstaID == 1 && !this.mod.objPosJedSelected) {
            this.cpanelClan();
          } else {
            this.cPanelInfo();
          }
          this.subscribeTimer();
          localStorage.clear();
        });
      }

    });



    this.pcPopunjenost(0, 0);

  }

  //Subscribe timer
  subscribeTimer() {
    this.s = this.t.subscribe(t => {
      if (this.mod.objKor.vrstaID == 1 && !this.mod.objPosJedSelected) {
        this.cpanelClan();
      } else {
        this.cPanelInfo();
      }
    });
  }

  //Odabrana poslona jedinica - emitter
  odabranaPosJed(obj: PosJedinice) {

    //this.pjedTmp = this.mod.objPosJedSelected.PoslJedID;
    document.getElementById('btnSidebarClose').click();
    if (this.mod.objKor.vrstaID == 1 && !this.mod.objPosJedSelected) {
      this.cpanelClan();
    } else {
      this.cPanelInfo();
    }

  }

  ngOnDestroy() {
    if (this.s) {
      this.s.unsubscribe();
    }

    document.getElementById('btnSidebarClose').click();
    document.getElementById('btnPoslanoClose').click();
    document.getElementById('mdlQrClose').click();
    document.getElementById('btnClLogin').click();
    document.getElementById('btnPrijavaClose').click();
    document.getElementById('btnModalPosJedTrenOperClose').click();
    document.getElementById('btnZahtjevClose').click();
    document.getElementById('btnMdlListaClPrijavaClose').click();

  }


  //cPanel info
  cPanelInfo() {

    let pIDtmp: number;

    /* if (localStorage.getItem('session')) {
      this.mod.session = JSON.parse(localStorage.getItem('session'));
    } */

    let now: string = moment(moment().format('YYYY-MM-DD HH:mm')).format('YYYY-MM-DD HH:mm:ss');

    if (!this.mod.objPosJedSelected) {
      pIDtmp = 0;
    } else {
      pIDtmp = this.mod.objPosJedSelected.PoslJedID;
    }

    //let token: string = localStorage.getItem('token');

    let url: string = this.mod.webUrl + '/php/cpanel_info.php?token=' + this.mod.objKor.token + '&pjedID=' + pIDtmp + '&funk=cpInfo';
    let vClansID: number;
    if (this.mod.session) {
      vClansID = this.mod.session.vClansID;
    } else {
      vClansID = 0;
    }

    let obj: string = JSON.stringify({ vClansID: vClansID, now: now, fingerprint: this.mod.fingerprint() });

    this.http.post(url, obj).subscribe(rez => {

      this.pcPopunjenost(rez['kapacitet'], rez['prisutnih']);

      if (this.mod.objKor && this.mod.objKor.vrstaID == 1) {

        //Učitavanje nepotvrđenih zahtjeva - privatni korisnik
        if (JSON.stringify(this.srvClanovi.objNepotZahtjevi) != JSON.stringify(rez['objNepotZahtjevi'])) {
          this.srvClanovi.objNepotZahtjevi = rez['objNepotZahtjevi'];
        }

        //Učitavanje članstva - privatni korisnik
        if (JSON.stringify(this.chlClanstva.objClanstva) != JSON.stringify(rez['objClanstva'])) {
          this.chlClanstva.objClanstva = rez['objClanstva'];
        }

        //Odabrana poslovna jedinica
        if (JSON.stringify(this.mod.objPosJedSelected) != JSON.stringify(rez['pjed'])) {
          this.mod.objPosJedSelected = rez['pjed'];
        }

        //session
        if (JSON.stringify(this.mod.session) != JSON.stringify(rez['session'])) {
          this.mod.session = rez['session'];
        }

        //objClanstvo
        if (JSON.stringify(this.mod.objClanstvo) != JSON.stringify(rez['objClanstvo'])) {
          this.mod.objClanstvo = rez['objClanstvo'];
        }

        let clanstTmp: Clanstvo = rez['objClanstvo'];

        if (clanstTmp && clanstTmp.intAkt == 0) {
          this.mod.objPosJedSelected = null;
          this.mod.session = null;
          this.mod.objClanstvo = null;
        }

        //Broj nepročitanih poruka
        let intNo: number = Number(rez['noveObav']);
        if (intNo) {
          if (intNo != this.noveObav) {
            this.noveObav = Number(rez['noveObav']);
          }
        } else {
          this.noveObav = 0;
        }

        //Dnevne aktivnosti (by pos jed)
        if (JSON.stringify(this.srvAktivnosti.objVaktivnost) != JSON.stringify(rez['objVaktivnost'])) {
          this.srvAktivnosti.objVaktivnost = rez['objVaktivnost'];
        }

        //Aktivnosti u tijeku by kor ID (privatni) i klijent ID
        if (JSON.stringify(this.mod.objAktSad) != JSON.stringify(rez['aktivByKorKlID'])) {
          this.mod.objAktSad = rez['aktivByKorKlID'];
        }


      } else if (this.mod.objKor && this.mod.objKor.vrstaID == 2) {

        //Učitavanje nepotvrđenih zahtjeva - poslovni korisnik
        if (JSON.stringify(this.srvClanovi.objNepotZahtjeviKl) != JSON.stringify(rez['objNepotZahtjeviKl'])) {
          this.srvClanovi.objNepotZahtjeviKl = rez['objNepotZahtjeviKl'];
        }

        //Dnevne aktivnosti (by pos jed)
        if (JSON.stringify(this.srvAktivnosti.objVaktivnost) != JSON.stringify(rez['objVaktivnost'])) {
          this.srvAktivnosti.objVaktivnost = rez['objVaktivnost'];
          console.log(this.srvAktivnosti.objVaktivnost);
        }

        //Aktivnosti u tijeku by Klijent ID (poslovni)
        if (JSON.stringify(this.mod.objAktSad) != JSON.stringify(rez['aktivByKlijent'])) {
          this.mod.objAktSad = rez['aktivByKlijent'];
        }

      }

      //Učitavanje trenera za trenutnu poslovnu jedinicu
      if (JSON.stringify(this.objTreneri) != JSON.stringify(rez['objTreneri'])) {
        this.objTreneri = rez['objTreneri'];
        console.log(this.objTreneri);
      }

      //Popunjenost dvorana za trenutnu poslovnu jedinicu
      let dvorTmp = null;
      if (rez['dvorane']) {
        dvorTmp = rez['dvorane'];
        if (dvorTmp) {
          dvorTmp?.forEach((val0, ind0) => {
            val0['data'] = this.pcPopunjenostClan(val0);
            val0['options'] = this.options2(val0, 80, 80);
          });
        }
      }
      if (JSON.stringify(this.objDvoraneChart) != JSON.stringify(dvorTmp)) {
        this.objDvoraneChart= rez['dvorane'];
        if (this.objDvoraneChart) {
          this.objDvoraneChart?.forEach((val0, ind0) => {
            val0['data'] = this.pcPopunjenostClan(val0);
            val0['options'] = this.options2(val0, 80, 80);
          });
        }
        console.log(this.objDvoraneChart);
      }
    });

  }


  //cpnael info - član
  cpanelClan() {


    this.mod.provjeraPrijaveTrenOper((rez) => {

      let url: string = this.mod.webUrl + '/php/cpanel_info.php?token=' + this.mod.objKor.token + '&funk=cpanelClan';
      let obj: string = JSON.stringify({ fingerprint: this.mod.fingerprint() })

      this.http.post(url, obj).subscribe((rez: PosJedClan[]) => {

        if (rez) {

          let posJed: PosJedClan[] = rez['posJed'];
          let objClanstva: Clanstvo[] = rez['objClanstva'];

          if (posJed) {
            posJed.forEach((val0, ind0) => {
              val0['data'] = this.pcPopunjenostClan(val0);
              val0['options'] = this.options2(val0, 150, 150);
            });

            if (JSON.stringify(this.objClanCentri) != JSON.stringify(posJed)) {
              this.objClanCentri = posJed;
              this.objClanCentri.forEach(val => {
                val['data'] = this.pcPopunjenostClan(val);
                val['options'] = this.options2(val, 150, 150);
              });
            }
          }

          //Učitavanje članstva - privatni korisnik
          if (JSON.stringify(this.chlClanstva.objClanstva) != JSON.stringify(rez['objClanstva'])) {
            this.chlClanstva.objClanstva = rez['objClanstva']; //objClanstva;
          }

          //Broj nepročitanih poruka
          let intNo: number = Number(rez['noveObav']);
          if (intNo) {
            if (intNo != this.noveObav) {
              this.noveObav = Number(rez['noveObav']);
            }
          } else {
            this.noveObav = 0;
          }

          //Nepotvrđeni zahttjevi za povezivanjem
          this.srvClanovi.objNepotZahtjevi = rez['objNepotZahtjevi'];

        }

      });

    });

  }

  //Prijava člana
  prijaviClana() {
    this.srvClanovi.prijaviClana();
    document.getElementById('btnClLogin').click();
  }

  //Prijava člana u grupu (glavni modal za prijavu)
  prijavaGrupa(x) {

    console.log(x);
    console.log(this.srvClanovi.frmPrijava.value);

    let obj;
    let vrstaTmp: number = this.srvClanovi.frmPrijava.controls['vrstaIDcl'].value; // this.srvClanovi.frmPrijava.get('vrstaIDc').value;
    
    let dvoranaIDtren: number = this.srvClanovi.frmPrijava.get('dvoranaIDtren').value;
  
    obj = {
      KlijentIDtren: this.mod.objPosJedSelected.KlijentIDpos,
      PosJedIDtren: x.grpPosJedID,
      KoriniskIDtren: x.ClanIDvcg,
      clanVrstaIDtren: vrstaTmp,
      GrupaIDtren: x.aktGrpID,
      vrijemePrijava: x.PocetakAkt,
      vrijemeOdjava: x.KrajAkt,
      dvoranaIDtren: dvoranaIDtren
    }

    this.srvClanovi.prijavaGrupa(obj, (rez) => {
      console.log(rez);
      if (rez == 'ok') {
        document.getElementById('btnPrijavaClose').click();
        this.mod.msgBox(1, "Član je uspješno prijavljen", 1000);
      } else {
        this.mod.msgBox(2, "Greška! pokušajte ponovno!", 1000);
      }
    });

  }

  obavijesti() {
    this.router.navigate(['obavijesti-panel']);
  }

  pretragaClear() {
    this.srvClanovi.strPretraga2 = '';
    this.srvClanovi.filtrirajNeprijavljene('');
  }

  //Pie chart - Popunjenost poslovne jedinice
  pcPopunjenost(kapacitet: number, prisutnih: number) {

    if ((this.prisutnih != prisutnih || this.kapacitet != kapacitet) || this.kapacitet == 0) {

      this.prisutnih = prisutnih;
      this.kapacitet = kapacitet;


      let title = 'Trenutna popunjenost';
      let type = 'PieChart';
      let columnNames = ['Kapacitet', 'Popunjenost'];

      let popunjeno: number;
      let prazno: number;

      if (prisutnih == 0) {
        popunjeno = 0;
        prazno = 100;
      } else {
        popunjeno = Math.round(prisutnih / kapacitet * 100);
        if (popunjeno > 100) {
          popunjeno = 100;
        }
        prazno = 100 - popunjeno;
      }

      if (popunjeno == 0) {
        this.brPrisutnih = 'grey'
      } else if (popunjeno < 70 && popunjeno > 0) {
        this.brPrisutnih = 'rgb(159, 224, 191)';
      } else if (popunjeno >= 70 && popunjeno < 90) {
        this.brPrisutnih = 'rgb(255, 166, 112)';
      } else if (popunjeno >= 90) {
        this.brPrisutnih = 'rgb(255, 91, 91)';
      }

      let options = {
        colors: [this.brPrisutnih, 'ghostwhite', '#e6693e', '#ec8f6e', '#f3b49f', '#f6c7b6'],
        is3D: true,
        legend: 'none',
        chartArea: { width: '100%', height: '100%' },
        backgroundColor: 'transparent',
        width: 200,
        height: 200,
      };

      let data = [
        ['Popunjeno', popunjeno],
        ['Prazno', prazno]
      ];

      this.pchPrisutni = {
        title: title,
        type: type,
        columnNames: columnNames,
        data: data,
        options: options,
      };

    }

  }

  //Chart - popunjenost - član (objekt chart => vidi pchPrisutni)

  options2(x, width: number, height: number) {

    let popunjeno: number;
    let prazno: number;

    if (x.prisutnih == 0) {
      popunjeno = 0;
      prazno = 100;
    } else {
      popunjeno = Math.round(x.prisutnih / x.kapacitet * 100);
      if (popunjeno > 100) {
        popunjeno = 100;
      }
      prazno = 100 - popunjeno;
    }

    let color: string;

    if (popunjeno == 0) {
      color = 'grey'
    } else if (popunjeno < 70 && popunjeno > 0) {
      color = 'rgb(159, 224, 191)';
    } else if (popunjeno >= 70 && popunjeno < 90) {
      color = 'rgb(255, 166, 112)';
    } else if (popunjeno >= 90) {
      color = 'rgb(255, 91, 91)';
    }

    let options2 = {
      colors: [color, 'ghostwhite', '#e6693e', '#ec8f6e', '#f3b49f', '#f6c7b6'],
      is3D: true,
      legend: 'none',
      chartArea: { width: '100%', height: '100%' },
      backgroundColor: 'transparent',
      width: width,
      height: height,
    };

    return options2;

  }


  pcPopunjenostClan(x) {

    let title = 'Trenutna popunjenost';
    let type = 'PieChart';
    let columnNames = ['Kapacitet', 'Popunjenost'];

    let popunjeno: number;
    let prazno: number;

    if (x.prisutnih == 0) {
      popunjeno = 0;
      prazno = 100;
    } else {
      popunjeno = Math.round(x.prisutnih / x.kapacitet * 100);
      if (popunjeno > 100) {
        popunjeno = 100;
      }
      prazno = 100 - popunjeno;
    }

    let color: string;

    if (popunjeno == 0) {
      color = 'grey'
    } else if (popunjeno < 70 && popunjeno > 0) {
      color = 'rgb(159, 224, 191)';
    } else if (popunjeno >= 70 && popunjeno < 90) {
      color = 'rgb(255, 166, 112)';
    } else if (popunjeno >= 90) {
      color = 'rgb(255, 91, 91)';
    }

    let options = {
      colors: [color, 'ghostwhite', '#e6693e', '#ec8f6e', '#f3b49f', '#f6c7b6'],
      is3D: true,
      legend: 'none',
      chartArea: { width: '100%', height: '100%' },
      backgroundColor: 'transparent',
      width: 200,
      height: 200,
    };

    let data = [
      ['Popunjeno', popunjeno],
      ['Prazno', prazno]
    ];

    let pch = {
      title: title,
      type: type,
      columnNames: columnNames,
      data: data,
      options: options,
    };

    return data;

  }


  //modal Prijava Trener / Operater - poslovne jedinice by KlijentID
  modalTrenOperPosJed(val: Clanstvo) {

    this.pjedTmp = null;

    let url1: string = this.mod.webUrl + '/php/clanovi.php?funk=trenOperPosJedByKlijentID&token=' + this.mod.objKor.token;

    let obj0 = {
      ClanIDcl: val.ClanIDcl,
      vrstaIDcl: val.vrstaIDcl,
      KlijentIDcl: val.KlijentIDcl,
      pjedIDcl: val.pjedIDcl,
      fingerprint: this.mod.fingerprint()
    };

    let obj: string = JSON.stringify(obj0);

    this.http.post(url1, obj).subscribe((rez: PosJedinice[]) => {

      this.objPosJedTrenOper = rez;

      this.postaviFrmTrenOper(obj0.pjedIDcl, obj0.ClanIDcl, obj0.KlijentIDcl, obj0.vrstaIDcl);

      if (rez && obj0.pjedIDcl != 0) {
        this.objPosJedTrenOper.forEach((v, i) => {
          if (v.PoslJedID == obj0.pjedIDcl) {
            this.pjedTmp = v;
          }
        });
      }

      document.getElementById('btnModalPosJedTrenOper').click();

    });

  }

  //Postavi početne vrijednosti frmPrijava
  postaviFrmTrenOper(pjedID: number, clanID: number, KlijentID: number, vrstaIDcl: number) {

    this.mod.frmprijavaTrenOper = this.formBuilder.group({

      KlijentIDtren: [KlijentID],
      PosJedIDtren: [pjedID],
      KoriniskIDtren: [clanID],
      priDat: [moment(), [Validators.required]],
      priH: [moment().format('HH'), [Validators.required, maxH]],
      priMin: [moment().format('mm'), [Validators.required, maxMin]],
      vrstaIDcl: [vrstaIDcl]

    });

  }

  //odabir poslovne jedinice - trener / operater
  postaviPosJedTrenOper(x: PosJedinice) {
    this.pjedTmp = x;
  }

  //Prijava Trenera / Operatera u poslovnu jedinicu
  prijavaTrenOper() {
    this.mod.prijavaTrenOper(this.pjedTmp);
    if (this.mod.objKor.vrstaID == 1 && !this.mod.objPosJedSelected) {
      this.cpanelClan();
    } else {
      this.cPanelInfo();
    }
  }

  /*prijavaTrenOper() {
   
    if (this.frmprijavaTrenOper.valid) {
   
      //Formatiranje Datum/Vrijeme za slanje http
      let d1Tmp: Date = new Date(this.frmprijavaTrenOper.controls['priDat'].value);
   
      let godTmp: number = d1Tmp.getFullYear();
      let mjTmp: number = d1Tmp.getMonth();
      let danTmp: number = d1Tmp.getDate();
      let hTmp: number = this.frmprijavaTrenOper.controls['priH'].value;
      let minTmp: number = this.frmprijavaTrenOper.controls['priMin'].value;
   
      let dTmp: string = formatDate(new Date(godTmp, mjTmp, danTmp, hTmp, minTmp, 0), 'yyyy-MM-dd HH:mm:ss', 'en-US');
   
      //JSON body
      let phpBody = {
   
        vClansID: this.mod.objClanstvo.vClansID,
        KlijentIDtren: this.frmprijavaTrenOper.controls['KlijentIDtren'].value,
        PosJedIDtren: this.pjedTmp.PoslJedID,  //this.frmprijavaTrenOper.controls['PosJedIDtren'].value,
        KoriniskIDtren: this.frmprijavaTrenOper.controls['KoriniskIDtren'].value, //this.frmprijavaTrenOper.controls['KoriniskIDtren'].value,
        vrijemePrijava: dTmp,
        vrstaIDcl: this.frmprijavaTrenOper.controls['vrstaIDcl'].value
   
      }
   
      localStorage.setItem('korOdobrenje', this.frmprijavaTrenOper.controls['PosJedIDtren'].value);
   
      //www. url
      let urlTmp: string = this.mod.webUrl + '/php/prisutni.php?token=' + this.mod.objKor.token + '&funk=prijava';
   
      //http.get
      this.http.post(urlTmp, JSON.stringify(phpBody)).subscribe(rez => {
   
        if (rez && rez['rez'] == 'ok') {
   
          this.mod.objPosJedSelected = {
            PoslJedID: this.pjedTmp.PoslJedID,
            KlijentIDpos: this.pjedTmp.KlijentIDpos,
            NazivPos: this.pjedTmp.NazivPos,
            AdresaPos: this.pjedTmp.AdresaPos,
            MjestoIDpos: this.pjedTmp.MjestoIDpos,
            Mjesto: this.pjedTmp.Mjesto,
            kapacitet: this.pjedTmp.kapacitet,
            aktivna: this.pjedTmp.aktivna
          };
   
          if (rez['session']) {
            //localStorage.setItem('session', JSON.stringify(rez['session']));
            this.mod.session = rez['session'];
            
          }
   
          //localStorage.setItem("objPosJedSelected", JSON.stringify(this.mod.objPosJedSelected));
   
          document.getElementById('btnModalPosJedTrenOper').click();
          document.getElementById('btnPrijavaClose').click();
   
          this.mod.ucitajPosJed(() => {
            if (this.mod.objKor.vrstaID == 1 && !this.mod.objPosJedSelected) {
              this.cpanelClan();
            } else {
              this.cPanelInfo();
            }
          });
   
   
          this.mod.msgBox(1, "Uspješno ste prijavljeni!", 2000);
   
        } else {
   
          this.mod.msgBox(2, "Greška prilikom prijave!", 3000);
   
        }
   
      });
   
    }
   
  } */

  //Učitavanje neprijavljenih članova
  objDvorane: Dvorana[];
  ucitajNeprijavljene() {

    this.pretragaClear();

    this.srvDvorane.dvoraneByPosJedId(this.mod.objPosJedSelected.PoslJedID, (rez: Dvorana[]) => {
      this.objDvorane = rez;
    });

    this.srvClanovi.ucitajNeprijavljene(this.mod.objPosJedSelected.PoslJedID, (rez) => {
      console.log(rez);
    });

  }

  //Login članova Start
  clanoviLoginStart(x) {
    this.bolGrpGost = false;
    this.bolGostSearch = false;
    this.srvClanovi.strPretraga2 = "";
    this.srvGrupe.objGrupa = x;
    this.aktivnostTmp = x;
    this.srvGrupe.ucitajClanoveZaPrijavu(x.aktGrpID, (rez) => {
      if (rez) {
        this.srvGrupe.objClanoviZaPrijavu = rez;
        document.getElementById('btnMdlListaClPrijava').click();
      } else {
        this.mod.msgBox(2, "Greška prilikom učitavanja članova!", 3000);
      }

    });
  }

  postaviFrmPrijava(PoslJedID, clanID, ImePrezime, vrstaIDcl) {

    this.srvClanovi.postaviFrmPrijava(PoslJedID, clanID, ImePrezime, vrstaIDcl, (rez) => {

      console.log(rez);

      this.aktZaClana = null;
      this.tjPosjeta = 0;
      this.clanarineByClan = null;

      //Formatiranje vremena prijave
      let hTmp = this.srvClanovi.frmPrijava.get('priH').value;
      let minTmp = this.srvClanovi.frmPrijava.get('priMin').value;
      let datTmp: moment.Moment = moment(moment(this.srvClanovi.frmPrijava.get('priDat').value).format('YYYY-MM-DD') + ' ' + hTmp + ':' + minTmp);

      //Članarine
      this.srvClanarine.vazeceByClan(clanID, datTmp, (rez12: Vclanarina[]) => {
        this.clanarineByClan = rez12;
      });

      //Dostupni grupni termini 
      this.srvClanovi.terminiZaClana(clanID, PoslJedID, datTmp, (rez0) => {
        this.aktZaClana = rez0;
      });

      //broj tjednih posjeta
      this.srvClanovi.tjednihPosjeta(clanID, vrstaIDcl, PoslJedID, datTmp, (rez202) => {
        this.tjPosjeta = rez202;
      });


    });

  }

  fitNetID() {
    return '{"fitnet":"' + this.mod.objKor.korisnikID + '", "klijent":"0"}';
  }

  dateChange(change, event) {
    console.log(change);
    console.log(event);
  }

  claColor(x) {
    return '#348b5f';
  }

  //Označavanje prisutnih članova u grupi
  clanChk(x: VclanZaPrijavu, i: number) {

    this.srvGrupe.clanChk(x, this.aktivnostTmp, (rez) => {
      if (rez) {
        if (rez == 'gost') {
          this.srvGrupe.objClanoviZaPrijavu.splice(i, 1);
          this.mod.msgBox(1, "Član je uspješno odjavljen", 2000);
        } else {
          this.srvGrupe.objClanoviZaPrijavu.splice(i, 1, rez); //: VclanZaPrijavu
        }

      }
    });

  }

  //class Aktivnost u tijeku (sve dnevne aktivnosti)
  clsUtijeku(d1: moment.Moment, d2: moment.Moment) {

    if (moment(d1) <= moment() && moment(d2) >= moment()) {
      return true;
    } else {
      return false;
    }

  }

  //class Aktivnost završila (sve dnevne aktivnosti)
  clsZavrsena(d1: moment.Moment, d2: moment.Moment) {

    if (moment(d2) < moment()) {
      return true;
    } else {
      return false;
    }

  }

  //Prijava gosta u grupu
  prijaviGosta(aktTmp) {
    this.bolGrpGost = true;
    this.ucitajNeprijavljene();
  }

  //Potvrdi prijavu gosta
  potvrdiGosta(x: Clan) {

    this.srvGrupe.prijavaGosta(x.clanID, this.aktivnostTmp, (rez) => {

      if (rez) {

        //provjera jel gost član grupe
        let bolIma: boolean;
        this.srvGrupe.objClanoviZaPrijavu.forEach(val => {
          if (val.clanID == rez.clanID) {
            val.trenID = rez.trenID;
            bolIma = true;
          }
        });

        if (!bolIma) {
          this.srvGrupe.objClanoviZaPrijavu.push(rez);
        }

        this.bolGostSearch = false;
        this.bolGrpGost = false;
        /* document.getElementById('btnMdlListaClPrijavaClose').click(); */
        this.mod.msgBox(1, "Član je uspješno prijavljen!", 2000);
      } else {
        this.mod.msgBox(2, "Greška! Član nije prijavljen. :(", 2000);
      }

    });

  }

  //Uređivanje termina
  urediAktivnost(x: Aktivnost) {

    console.log(x);

    this.srvkalendar.fnKalendarUnos(0, () => {

      //Treneri by aktivnost ID
      this.srvAktivnosti.treneriByAktIdEdit(x.aktGrpID, (rez: TreneriByAktID[]) => {
        this.srvAktivnosti.objTreneriByAktID = rez;
        console.log(rez);
      });

      //Dvorane
      this.srvDvorane.dvoraneByPosJedId(this.mod.objPosJedSelected.PoslJedID, (rez: Dvorana[]) => {

        this.srvkalendar.objDvorane = rez;

        x.statusAkt = Number(x.statusAkt);
        x['datumOD'] = moment(x.PocetakAkt).format('YYYY-MM-DD');
        x['hOD'] = moment(x.PocetakAkt).format('HH');
        x['mOD'] = moment(x.PocetakAkt).format('mm');

        let duration = moment.duration(moment(x.KrajAkt).diff(moment(x.PocetakAkt)));

        let hTmp, mTmp: number;
        mTmp = duration.asMinutes();
        /* hTmp = Math.trunc(mTmp / 60);
        mTmp = mTmp - (hTmp * 60); - depreced */

        /* x['hDO'] = hTmp; - depreced*/
        x['mDO'] = mTmp;

        this.srvkalendar.frmKalendarUnos.patchValue(x);

        document.getElementById('btnAktEdit').click();

      });

    });

  }

  //Spremamnje promjena u terminu
  spremiAktivnost() {

    this.srvkalendar.aktivnostQuickUpdate((rez) => {
      console.log(rez);
      document.getElementById('btnMdlAktEditClose').click();
    });

  }

  test() {
    /* let url: string = 'http://192.168.5.11/LED=OFF';
    this.http.get(url).subscribe(val => {
      console.log(val);
    }); */
  }

}
