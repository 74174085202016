import { Component, OnInit } from '@angular/core';
import { ModulService } from '../modul.service';
import { ObavijestiService } from '../obavijesti.service';
import { PozivniceService } from '../pozivnice.service';

@Component({
  selector: 'app-obavijesti-sidebar',
  templateUrl: './obavijesti-sidebar.component.html',
  styleUrls: ['./obavijesti-sidebar.component.scss']
})
export class ObavijestiSidebarComponent implements OnInit {

  constructor(
    public mod: ModulService,
    public srvObavijesti: ObavijestiService,
    private srvPozivnice: PozivniceService
  ) { }

  ngOnInit(): void {
  }

  //Nova obavijest START
  novaObavijestStart() {

    this.srvObavijesti.novaObavijest();
    document.getElementById('btnMdlNovaObavijest').click();

  }

  //Primljene obavijesti
  primljene() {
    this.srvObavijesti.intVrsta = 1;
  }

  poslane() {
    this.srvObavijesti.intVrsta = 2;
  }

  novaPozivnica() {

    this.srvPozivnice.napraviFrmPozivnica(null,(rez) => {

    });
    document.getElementById('btnMdlNovaPozivnica').click();
  }

}
