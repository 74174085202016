import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { ModulService, MjestaRH, Kor } from '../modul.service';
import { HttpClient } from '@angular/common/http';
import { AbstractControlOptions, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MjestaService } from '../mjesta.service';
import * as validatori from '../validatori';
import { PosJediniceService } from '../pos-jedinice.service';

export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  }
}


@Component({
  selector: 'app-registracija',
  templateUrl: './registracija.component.html',
  styleUrls: ['./registracija.component.scss']
})

/* let korTmp = <Kor>{}; */

export class RegistracijaComponent implements OnInit, OnDestroy {

  constructor(
    public mod: ModulService,
    public srvMjesta: MjestaService,
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private router: Router,
    public srvPosJed: PosJediniceService
  ) { }

  //@ViewChild('btnPoslano', { static: false }) btnPoslano: ElementRef;

  frmRegistracija: FormGroup;
  fltMjesta: MjestaRH[];
  fltMjesta2: MjestaRH[];

  vrstaKorID: number = 1;

  spinner: boolean = false;

  btnUvjetiClass: string = 'btn-outline-dark';

  ngOnInit() {

    this.ucitajMjestaRH();

    //FORMA - PODACI
    this.frmRegistracija = this.formBuilder.group({

      korisnikID: [0],
      vrstaID: ['1', [Validators.required]],
      Ime: ['', [Validators.required]],
      Prezime: [''],
      Adresa: [''],
      Kucni_br: [''],
      MjestoID: [0],
      Mjesto: [''],
      OIB: ['', [validatori.oibChk]],
      korIme: ['', [Validators.required, Validators.email]],
      lozinka: ['', [Validators.required, Validators.minLength(6)]],
      lozinka2: ['', [Validators.required, Validators.minLength(6)]],
      uvjKoristenja: [false, [Validators.requiredTrue]]
    }, {
      validator: MustMatch('lozinka', 'lozinka2')
    } as AbstractControlOptions);

    //Listener za Mjesta autocomplete
    this.frmRegistracija.get('Mjesto').valueChanges.subscribe(value => {

      if (value.length >= 3) {
        this.filtrirajMjestaRH(value);
      } else {
        this.fltMjesta = [];
      }

    });

    this.srvPosJed.napraviFrmPosJed(null, () => {

      //Listener za Mjesta 2 (posl. jed.) autocomplete
      this.srvPosJed.frmPosJed.get('Mjesto').valueChanges.subscribe(value => {

        if (value.length >= 3) {
          this.filtrirajMjestaRH2(value);
        } else {
          this.fltMjesta2 = [];
        }

      });

    });

  }

  //test
  test() {
    //alert(this.frmRegistracija.controls['korIme'].touched);
    //document.getElementById('btnPoslano').click();
    //this.frmRegistracija.controls['korIme'].validator.apply(this.pMaila2(false));
  }

  //UČITAJ MJESTA
  ucitajMjestaRH() {

    let urlTmp: string = this.mod.webUrl + '/php/mjesta.php';

    this.http.get<MjestaRH[]>(urlTmp).subscribe(rez => {
      this.srvMjesta.objMjestaRH = rez;
      localStorage.objMjestaRH = JSON.stringify(rez);
    })

  }

  //FILTRIRANJE MJESTA 
  filtrirajMjestaRH(strP: string) {
    this.fltMjesta = this.srvMjesta.objMjestaRH.filter(s => s.Mjesto.toLowerCase().includes(strP.toLowerCase()));
  }

  //FILTRIRANJE MJESTA  (posl.jed)
  filtrirajMjestaRH2(strP: string) {
    this.fltMjesta2 = this.srvMjesta.objMjestaRH.filter(s => s.Mjesto.toLowerCase().includes(strP.toLowerCase()));
  }

  //SET MjestoID (mod.objKor.MjestoID)
  postaviMjestoID(mjestoID: number) {
    this.frmRegistracija.patchValue({ MjestoID: mjestoID });
  }

  //SET MjestoID (posl. jed.)
  postaviMjestoID2(mjestoID: number) {
    this.srvPosJed.frmPosJed.patchValue({ MjestoIDpos: mjestoID });
  }

  //Odabir vrste korisnika
  odabirVrsteKor(id: number) {

    this.vrstaKorID = id;

    if (id == 2) {
      this.frmRegistracija.controls['Prezime'].setValue('');
      this.srvPosJed.frmPosJed.get('NazivPos').addValidators(Validators.required);
      this.srvPosJed.frmPosJed.get('kapacitet').addValidators(Validators.required);
    } else {
      this.srvPosJed.frmPosJed.get('NazivPos').removeValidators(Validators.required);
      this.srvPosJed.frmPosJed.get('kapacitet').removeValidators(Validators.required);
    }

    console.log(this.srvPosJed.frmPosJed.get('NazivPos').hasValidator(Validators.required));

  }

  //Registracija
  registracija() {

    this.frmRegistracija.markAllAsTouched();
    this.srvPosJed.frmPosJed.markAllAsTouched();

    if (!this.frmRegistracija.controls['uvjKoristenja'].value) {
      this.btnUvjetiClass = 'btn-outline-danger';
    }

    if (!this.dupliEmail() && this.frmRegistracija.valid && this.srvPosJed.frmPosJed.valid) {

      this.spinner = true;

      let urlTmp = this.mod.webUrl + '/php/gmailAPI/korisnik.php?token=novi&upit=registracija';

      let obj0 = {
        registracija: this.frmRegistracija.value,
        posJed: this.srvPosJed.frmPosJed.value
      }

      let obj: string = JSON.stringify(obj0);

      this.http.post(urlTmp, obj).subscribe(rez => {

        console.log(rez);

        let str = rez;

        if (str == 'ok') {
          this.mod.objLogin.kor = this.frmRegistracija.controls['korIme'].value;
          this.mod.objLogin.pass = this.frmRegistracija.controls['lozinka'].value;
          this.mod.poslanEmail = true;
          this.router.navigate(['naslovnica']);

        } else {
          alert('Došlo je do pogreške prilikom obrade podataka! Molimo pokušajte ponovno!');
        }

        this.spinner = false;

      });

    } else {
      //alert('nije valid');
    }

  }

  prihvacamUvjete() {
    this.frmRegistracija.controls['uvjKoristenja'].setValue(true);
    this.btnUvjetiClass = 'btn-outline-success';
  }

  prihvacamUvjeteNot() {
    this.frmRegistracija.controls['uvjKoristenja'].setValue(false);
    this.btnUvjetiClass = 'btn-outline-danger';
  }

  //Provjera zauzetosti e-mail adrese
  dupliEmail(): boolean {

    if (this.frmRegistracija.controls['korIme'].valid) {

      //let urlTmp: string = this.mod.webUrl + '/php/korisnik.php?token=novi&upit=provjeriEmail&email=' + control.value;
      let urlTmp: string = this.mod.webUrl + '/php/gmailAPI/korisnik.php?token=novi&upit=provjeriEmail&email=' + this.frmRegistracija.controls['korIme'].value;

      this.http.get(urlTmp).subscribe(rez => {

        if (rez == 'ok') {
          //this.frmRegistracija.controls['korIme'].setErrors({'dupla': false});
          //alert('nije dupli');
          return false;
        } else {
          this.frmRegistracija.controls['korIme'].setErrors({ 'dupla': true });

          //alert('dupli');
          return true;
        }
      });

    } else {
      //this.frmRegistracija.controls['korIme'].setErrors({'dupla': false});
      //alert('nije još valid');
      return false;
    }

  }

  ngOnDestroy(): void {
    document.getElementById('btnUvjetClose').click();
  }

}