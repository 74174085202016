import { Component, OnInit, OnDestroy, ViewChild, ElementRef, HostListener } from '@angular/core';
import { ModulService, PosJedinice } from '../modul.service';
import { HttpClient } from '@angular/common/http';
//import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from '../dateformats';
import { formatDate } from "@angular/common";
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { timer, Subscription } from 'rxjs';
import * as moment from 'moment';
import { Moment } from 'moment';
import { listFadeIn } from "../animacije";
import * as validatori from '../validatori';
import { Grupa, GrupeService, VclanZaPrijavu } from '../grupe.service';
import { Dvorana, DvoraneService } from '../dvorane.service';
import { Router } from '@angular/router';
import { Clan, ClanoviService } from '../clanovi.service';
import { Aktivnost } from '../kalendar.service';

export function maxH(v: FormControl) {

  if (!isNaN(parseInt(v.value))) {

    if (parseInt(v.value) > 24 || parseInt(v.value) < 0) {
      return { maxMin: true };
    } else {
      return null;
    }

  } else {
    return { notNumb: true };
  }



}

export function maxMin(v: FormControl) {

  if (!isNaN(parseInt(v.value))) {

    if (parseInt(v.value) > 59 || parseInt(v.value) < 0) {
      return { maxMin: true };
    } else {
      return null;
    }

  } else {
    return { notNumb: true };
  }

}

@Component({
  selector: 'app-prisutni',
  templateUrl: './prisutni.component.html',
  styleUrls: ['./prisutni.component.scss'],
  animations: [listFadeIn]
})

export class PrisutniComponent implements OnInit, OnDestroy {

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    /* console.log(this.innerWidth); */
  }

  constructor(
    public mod: ModulService,
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private _snackBar: MatSnackBar,
    public srvGrupe: GrupeService,
    public srvDvorane: DvoraneService,
    private router: Router,
    public srvClanovi: ClanoviService
  ) { }

  /* @ViewChild('btnSidebar', { static: false }) btnSidebar: ElementRef; */
  @ViewChild('btnSidebarClose') btnSidebarClose: ElementRef;
  @ViewChild('pretraga') pretraga: ElementRef;
  @ViewChild('btnOdabirDismiss') btnOdabirDismiss: ElementRef;
  @ViewChild('btnModalPrijava') btnModalPrijava: ElementRef;
  @ViewChild('btnOdjavaClose') btnOdjavaClose: ElementRef;
  @ViewChild('btnPrijavaClose') btnPrijavaClose: ElementRef;

  innerWidth: number;
  bolPretraga: boolean;

  pjedTmp: number = 0;

  //Prisutni Component
  objPrisutni;
  fltPrisutni;
  strPretraga: string = '';

  //Neprijavljeni članovi list
  objNeprijavljeni;
  fltNeprijavljeni;
  strPretraga2: string = '';

  minDate = moment('2018-01-01').format('YYYY-MM-DD'); //new Date(2018, 0, 1);
  dat1: string = moment().format('YYYY-MM-DD'); //formatDate(new Date(), 'yyyy-MM-dd', 'en-US'); //'2019-07-31';

  frmOdjava: FormGroup;
  frmPrijava: FormGroup;

  s: Subscription;
  t = timer(2000, 2000);

  chkGrupe: boolean = false;

  datTest;

  ngOnInit() {

    this.innerWidth = window.innerWidth;
    console.log(this.innerWidth);

    let tok: string;
    if (localStorage.getItem('token')) {
      tok = localStorage.getItem('token');
    } else {
      if (this.mod.objKor) {
        tok = this.mod.objKor.token;
      } else {
        localStorage.clear();
        this.router.navigate['naslovnica'];
      }
    }

    //Provjera tokena
    this.mod.provjeraTokena(tok, () => {


      if (this.mod.objKor.vrstaID == 1) {

        this.mod.provjeraPrijaveTrenOper(() => {

          this.mod.ucitajPosJed(() => {

            /* if (JSON.stringify(this.mod.objPosJedSelected) != localStorage.objPosJedSelected) {
              this.mod.objPosJedSelected = JSON.parse(localStorage.objPosJedSelected);
            }*/


            this.ucitajPrisutne(this.mod.objPosJedSelected.PoslJedID);

            this.s = this.t.subscribe(rez => {
              this.ucitajPrisutne(this.mod.objPosJedSelected.PoslJedID);
              /* console.log('tik'); */
            });

            this.napraviFrmPrijava();
            this.napraviFrmOdjava();


          });

        });

      } else {

        this.mod.ucitajPosJed(() => {

          /* if (JSON.stringify(this.mod.objPosJedSelected) != localStorage.objPosJedSelected) {
            this.mod.objPosJedSelected = JSON.parse(localStorage.objPosJedSelected);
          }*/


          this.ucitajPrisutne(this.mod.objPosJedSelected.PoslJedID);

          this.s = this.t.subscribe(rez => {
            this.ucitajPrisutne(this.mod.objPosJedSelected.PoslJedID);
            /* console.log('tik'); */
          });

          this.napraviFrmPrijava();
          this.napraviFrmOdjava();


        });

      }


    });

  }

  //Odabrana poslona jedinica - emitter
  odabranaPosJed(obj: PosJedinice) {

    this.pjedTmp = this.mod.objPosJedSelected.PoslJedID;
    this.ucitajPrisutne(this.pjedTmp);

    console.log('test');

    document.getElementById('btnSidebarClose').click();

  }


  //Ucitaj trenutno prisutne
  objAktSad;
  ucitajPrisutne(pJed: number) {

    //let token: string = localStorage.getItem('token');

    let urlTmp: string = this.mod.webUrl + '/php/prisutni.php?token=' + this.mod.objKor.token + '&pjedID=' + pJed + '&funk=selectAll';
    let now: string = moment(moment().format('YYYY-MM-DD HH:mm')).format('YYYY-MM-DD HH:mm:ss');
    let obj: string = JSON.stringify({ now: now, fingerprint: this.mod.fingerprint() });

    this.http.post(urlTmp, obj).subscribe(rez => {

      if (rez && rez['objAktSad']) {
        this.objAktSad = rez['objAktSad'];
      } else {
        this.objAktSad = null;
      }

      if (rez && JSON.stringify(rez['prisutni']) != JSON.stringify(this.objPrisutni)) {

        this.objPrisutni = rez['prisutni'];

        if (this.objPrisutni) {
          this.fltPrisutni = rez['prisutni'];
          this.filtrirajClanove(this.strPretraga);
        } else {
          this.fltPrisutni = null;
        }

      } else {
        if (!rez) {
          this.objPrisutni = null;
          this.fltPrisutni = null;
        }
        //console.log('nema promjene');
      }

    });
  }

  //Login članova Start
  bolGrpGost = false;
  bolGostSearch = false;
  aktivnostTmp;

  clanoviLoginStart(x: Aktivnost) {
    this.bolGrpGost = false;
    this.bolGostSearch = false;
    this.srvClanovi.strPretraga2 = "";
    this.aktivnostTmp = x;
    this.srvGrupe.ucitajClanoveZaPrijavu(x.aktGrpID, (rez) => {
      if (rez) {
        this.srvGrupe.objClanoviZaPrijavu = rez;
        document.getElementById('btnMdlListaClPrijava').click();
      } else {
        this.mod.msgBox(2, "Greška prilikom učitavanja članova!", 3000);
      }

    });
  }

  //Prikazati prisutne u grupama
  grupaChk() {
    this.chkGrupe = !this.chkGrupe;
    this.filtrirajClanove(this.strPretraga);
    document.getElementById('btnSidebarClose').click()
  }

  //FILTRIRANJE PRISUTNI
  filtrirajClanove(strP: string) {
    this.fltPrisutni = this.objPrisutni.filter(s => s.Ime.toLowerCase().includes(strP.toLowerCase()) || s.Prezime.toLowerCase().includes(strP.toLowerCase()));

    let obj = [];
    this.fltPrisutni.forEach(e => {

      if (this.chkGrupe == true || !e.grupa) {
        obj.push(e);
      }

    });

    this.fltPrisutni = obj;

    console.log(this.fltPrisutni);
  }

  //Očisti pretragu
  pretragaClear() {
    this.strPretraga = '';
    this.filtrirajClanove('');
  }

  //UČITAJ NEPRIJAVLJENE
  objDvorane: Dvorana[];
  ucitajNeprijavljene(pJed: number) {

    this.srvDvorane.dvoraneByPosJedId(this.mod.objPosJedSelected.PoslJedID, (rez: Dvorana[]) => {
      this.objDvorane = rez;
    });


    let urlTmp: string = this.mod.webUrl + '/php/prisutni.php?token=' + this.mod.objKor.token + '&pjedID=' + pJed + '&funk=odabirClana';
    let obj: string = JSON.stringify({ fingerprint: this.mod.fingerprint() });

    this.http.post(urlTmp, obj).subscribe(rez => {

      this.objNeprijavljeni = rez;
      this.fltNeprijavljeni = rez;
      this.filtrirajNeprijavljene(this.strPretraga2);

      document.getElementById('btnSidebarClose').click();
      document.getElementById('btnModalOdabir').click();

    });

  }

  //FILTRIRANJE NEPRIJAVLJENI
  filtrirajNeprijavljene(strP: string) {
    if (this.objNeprijavljeni) {
      this.fltNeprijavljeni = this.objNeprijavljeni.filter(s => s.Ime.toLowerCase().includes(strP.toLowerCase()) || s.Prezime.toLowerCase().includes(strP.toLowerCase()));
    } else {
      this.fltNeprijavljeni = [];
    }

  }

  //Clear neprijavljene
  pretragaLoginClear() {
    this.strPretraga2 = '';
    this.filtrirajNeprijavljene('');
  }

  //frm Prijava
  napraviFrmPrijava() {

    this.frmPrijava = this.formBuilder.group({

      KlijentIDtren: [0],
      PosJedIDtren: [0],
      KoriniskIDtren: [0],
      ime: ['', [Validators.required]],
      priDat: [this.dat1, [Validators.required]],
      priH: [0, [Validators.required, maxH]],
      priMin: [0, [Validators.required, maxMin]],
      dvoranaIDtren: ['0']

    });

  }

  //Postavi početne vrijednosti frmPrijava
  postaviFrmPrijava(clanID: number, strTren: string) {

    if (this.mod.objKor.vrstaID == 2) {
      this.frmPrijava.controls['KlijentIDtren'].setValue(this.mod.objKor.korisnikID);
    } else {
      this.frmPrijava.controls['KlijentIDtren'].setValue(this.mod.objPosJedSelected.KlijentIDpos);
    }

    this.frmPrijava.controls['PosJedIDtren'].setValue(this.mod.objPosJedSelected.PoslJedID);
    this.frmPrijava.controls['KoriniskIDtren'].setValue(clanID);
    this.frmPrijava.controls['ime'].setValue(strTren);
    this.frmPrijava.controls['priDat'].setValue(this.dat1);
    this.frmPrijava.controls['priH'].setValue(formatDate(new Date(), 'HH', 'en-US'));
    this.frmPrijava.controls['priMin'].setValue(formatDate(new Date(), 'mm', 'en-US'));

  }

  //frm Odjava
  napraviFrmOdjava() {
    this.frmOdjava = this.formBuilder.group({
      trenID: [0],
      ime: ['', [Validators.required]],
      vrijemePrijava: [''],
      odjDat: [this.dat1, [Validators.required]],
      odjH: [0, [Validators.required, maxH, Validators.minLength(2), Validators.maxLength(2)]],
      odjMin: [0, [Validators.required, maxMin, Validators.minLength(2), Validators.maxLength(2)]]
    }, {
      validator: validatori.VrijemeOdjava
    });

  }

  //Postavi početne vrijednosti frmOdjava
  postaviFrmOdjava(trenID: number, strTren: string, vrijemePrijava: string) {

    this.frmOdjava.controls['trenID'].setValue(trenID);
    this.frmOdjava.controls['ime'].setValue(strTren);
    this.frmOdjava.controls['vrijemePrijava'].setValue(vrijemePrijava);
    this.frmOdjava.controls['odjDat'].setValue(this.dat1);
    this.frmOdjava.controls['odjH'].setValue(formatDate(new Date(), 'HH', 'en-US'));
    this.frmOdjava.controls['odjMin'].setValue(formatDate(new Date(), 'mm', 'en-US'));

  }

  trajanjePosjeta() {

    /* //Angular / Javascript
     let d1 = +new Date(this.frmOdjava.controls['vrijemePrijava'].value);
     let d2 = +new Date(this.frmOdjava.controls['odjDat'].value + 'T' + this.frmOdjava.controls['odjH'].value + ':' + this.frmOdjava.controls['odjMin'].value + ':00');
     let diff: number = -(d1 - d2) / 1000 / 60; //Ukupno min
    */

    //Moment.js
    let dd1 = moment(this.frmOdjava.controls['vrijemePrijava'].value);
    let d012 = moment(this.frmOdjava.controls['odjDat'].value).format('YYYY-MM-DD');
    let dd2 = moment(d012 + 'T' + this.frmOdjava.controls['odjH'].value + ':' + this.frmOdjava.controls['odjMin'].value + ':00');

    this.datTest = dd2;

    //alert(dd2);

    let diff: number = -dd1.diff(dd2, 'minutes');

    let rezDana: number = Math.floor(diff / 1440); //Dana

    let rezH: number = Math.floor((diff - (rezDana * 1440)) / 60); //Sati

    let rezMin: number = diff - (rezDana * 1440) - (rezH * 60); //Minuta

    if (isNaN(rezDana) || isNaN(rezH) || isNaN(rezMin)) {
      return

    } else {

      if (rezDana <= 0) {

        if (rezH <= 0) {
          return rezMin + 'min';
        } else {
          return rezH + 'h ' + rezMin + 'min';
        }

      } else {

        if (rezDana == 1) {
          return rezDana + 'dan ' + rezH + 'h ' + rezMin + 'min';
        } else {
          return rezDana + 'dana ' + rezH + 'h ' + rezMin + 'min';
        }

      }

    }
  }

  trajanjePosjetaParams(d1: Moment) {

    //Moment.js
    let dd1 = moment(d1);
    let dd2 = moment();

    let diff: number = -dd1.diff(dd2, 'minutes');


    let rezDana: number = Math.floor(diff / 1440); //Dana

    let rezH: number = Math.floor((diff - (rezDana * 1440)) / 60); //Sati

    let rezMin: number = diff - (rezDana * 1440) - (rezH * 60); //Minuta

    if (isNaN(rezDana) || isNaN(rezH) || isNaN(rezMin)) {
      return

    } else {

      if (rezDana <= 0) {

        if (rezH <= 0) {
          return rezMin + ' min';
        } else {
          return rezH + ' h ' + rezMin + ' min';
        }

      } else {

        if (rezDana == 1) {
          return rezDana + ' dan ' + rezH + ' h ' + rezMin + ' min';
        } else {
          return rezDana + ' dana ' + rezH + ' h ' + rezMin + ' min';
        }

      }

    }
  }

  test() {

    let d1: Date = new Date(this.frmOdjava.controls['vrijemePrijava'].value);
    let d3: Date = new Date(formatDate(this.frmOdjava.controls['odjDat'].value, "yyyy-MM-dd", 'en-US') + ' ' + this.frmOdjava.controls['odjH'].value + ':' + this.frmOdjava.controls['odjMin'].value + ':00');

    if (d1 > d3) {
      alert('veći');
    } else {
      alert(formatDate(d1, 'dd.MM.yyyy. HH:mm:ss', 'en-US') + ' <=> ' + formatDate(d3, 'dd.MM.yyyy. HH:mm:ss', 'en-US'));
    }

  }

  //PRIJAVA ČLANA
  prijaviClana() {

    if (this.frmPrijava.valid) {

      //Formatiranje Datum/Vrijeme za slanje http
      let d1Tmp = moment(this.frmPrijava.controls['priDat'].value);

      //moment.locale('hr');

      let godTmp: string = moment(d1Tmp).year().toString();
      let mjTmp: string = (moment(d1Tmp).month() + 1).toString();
      let danTmp: string = moment(d1Tmp).date().toString();
      let hTmp: string = this.frmPrijava.controls['priH'].value;
      let minTmp: string = this.frmPrijava.controls['priMin'].value;

      //let dTmp = moment(moment(godTmp + '-' + mjTmp + '-' + danTmp).format("YYYY-MM-DD") + 'T' + hTmp + ':' + minTmp + ':' + '00').format("YYYY-MM-DD HH:mm:ss");
      let dTmp = moment(d1Tmp.format("YYYY-MM-DD") + 'T' + hTmp + ':' + minTmp + ':' + '00').format("YYYY-MM-DD HH:mm:ss");

      //alert(dTmp);

      //JSON body
      let phpBody = {

        KlijentIDtren: this.frmPrijava.controls['KlijentIDtren'].value,
        PosJedIDtren: this.frmPrijava.controls['PosJedIDtren'].value,
        KoriniskIDtren: this.frmPrijava.controls['KoriniskIDtren'].value,
        vrstaIDcl: 1,
        vrijemePrijava: dTmp,
        dvoranaIDtren: this.frmPrijava.controls['dvoranaIDtren'].value,
        fingerprint: this.mod.fingerprint()
      }

      //www. url
      let urlTmp: string = this.mod.webUrl + '/php/prisutni.php?token=' + this.mod.objKor.token + '&funk=prijava';

      //http.get
      this.http.post(urlTmp, phpBody).subscribe(rez => {

        if (rez && rez['rez'] == 'ok') {

          document.getElementById('btnPrijavaClose').click();
          document.getElementById('btnOdabirDismiss').click();

          this.ucitajPrisutne(this.mod.objPosJedSelected.PoslJedID);

          this.mod.msgBox(1, "Član je uspješno prijavljen!", 3000)

        } else {

          this.mod.msgBox(2, "Greška prilikom prijave!", 3000)

        }

      });

    }

  }

  //ODJAVA ČLANA
  odjaviClana(trenID: number) {

    if (this.frmOdjava.valid) {

      //Formatiranje Datum/Vrijeme za slanje http
      let d1Tmp: Date = new Date(this.frmOdjava.controls['odjDat'].value);

      let godTmp: number = d1Tmp.getFullYear();
      let mjTmp: number = d1Tmp.getMonth();
      let danTmp: number = d1Tmp.getDate();
      let hTmp: number = this.frmOdjava.controls['odjH'].value;
      let minTmp: number = this.frmOdjava.controls['odjMin'].value;

      let dTmp: string = formatDate(new Date(godTmp, mjTmp, danTmp, hTmp, minTmp, 0), 'yyyy-MM-dd HH:mm:ss', 'en-US');

      //JSON body
      let phpBody = {
        trenID: this.frmOdjava.controls['trenID'].value,
        vrijemeOdjava: dTmp,
        fingerprint: this.mod.fingerprint()
      }

      //www. url
      let urlTmp: string = this.mod.webUrl + '/php/prisutni.php?token=' + this.mod.objKor.token + '&funk=odjava';

      //http.get
      this.http.post(urlTmp, phpBody).subscribe(rez => {

        if (rez == 'ok') {

          document.getElementById('btnOdjavaClose').click();

          this.ucitajPrisutne(this.mod.objPosJedSelected.PoslJedID);

          this.mod.msgBox(1, "Član je uspješno odjavljen!", 2000);

        } else {

          this.mod.msgBox(2, "Greška prilikom odjave!", 3000);

        }

      });

    }

  }

  //Označavanje prisutnih članova u grupi
  clanChk(x: VclanZaPrijavu, i: number) {

    this.srvGrupe.clanChk(x, this.aktivnostTmp, (rez) => {
      if (rez) {
        if (rez == 'gost') {
          this.srvGrupe.objClanoviZaPrijavu.splice(i, 1);
          this.mod.msgBox(1, "Član je uspješno odjavljen", 2000);
        } else {
          this.srvGrupe.objClanoviZaPrijavu.splice(i, 1, rez); //: VclanZaPrijavu
        }

      }
    });

  }

  //Potvrdi prijavu gosta
  potvrdiGosta(x: Clan) {

    this.srvGrupe.prijavaGosta(x.clanID, this.aktivnostTmp, (rez) => {

      if (rez) {

        //provjera jel gost član grupe
        let bolIma: boolean;
        this.srvGrupe.objClanoviZaPrijavu.forEach(val => {
          if (val.clanID == rez.clanID) {
            val.trenID = rez.trenID;
            bolIma = true;
          }
        });

        if (!bolIma) {
          this.srvGrupe.objClanoviZaPrijavu.push(rez);
        }

        this.bolGostSearch = false;
        this.bolGrpGost = false;
        /* document.getElementById('btnMdlListaClPrijavaClose').click(); */
        this.mod.msgBox(1, "Član je uspješno prijavljen!", 2000);
      } else {
        this.mod.msgBox(2, "Greška! Član nije prijavljen. :(", 2000);
      }

    });

  }

  //Prijava gosta u grupu
  prijaviGosta(aktTmp) {
    this.bolGrpGost = true;
    /* this.ucitajNeprijavljene(this.mod.objPosJedSelected.PoslJedID);; */
    this.srvClanovi.ucitajNeprijavljene(this.mod.objPosJedSelected.PoslJedID, (rez) => {
      console.log(rez);
    });
  }




  openSidebar() {
    //document.getElementById('btnSidebar').click();
  }

  //Snackbar Alert
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 4000,
    });
  }

  ngOnDestroy() {
    this.objPrisutni = null;
    if (this.s) {
      this.s.unsubscribe();
    }
    document.getElementById('btnSidebarClose').click();
    document.getElementById('btnOdabirDismiss').click();
    document.getElementById('btnPrijavaClose').click();
    document.getElementById('btnOdjavaClose').click();
  }

}
