<div class="container" style="padding: 0; padding-top: 50px;">

    <div class="card" style="height: Calc(100vh - 52px); padding: 15px;">

        <div class="row">
            <div class="col col-md-3" style="padding: 2px;" routerLink="/stat-kl-posjete">
                <button type="button" class="btn btns-blue w-100" style="height: 50px;"><i class="material-icons" style="margin-right: 10px;">group_add</i> Pregled posjeta</button>
            </div>
        </div>

    </div>

</div>