import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Clanarina, ClanarineService } from '../clanarine.service';
import { ClanoviService } from '../clanovi.service';
import { Grupa, GrupeService } from '../grupe.service';
import { ModulService } from '../modul.service';

@Component({
  selector: 'app-clanarine-sidebar',
  templateUrl: './clanarine-sidebar.component.html',
  styleUrls: ['./clanarine-sidebar.component.scss']
})
export class ClanarineSidebarComponent implements OnInit {

  constructor(
    public mod: ModulService,
    public srvClanarine: ClanarineService,
    public srvClanovi: ClanoviService,
    private srvGrupe: GrupeService
  ) { }

  ngOnInit(): void {
  }

  //Nova članarina start
  novaClanarinaStart() {

    let clTmp: Clanarina = {
      claID: 0,
      claClanID: this.srvClanovi.clanID,
      claGrupaID: 0,
      intPlacena: 0,
      claDatumUplate: moment(),
      claVrijediOD: moment(),
      claVrijediDO: moment().add(1, 'month').subtract(1, 'days'),
      claIznos: 0,
      claKorClID: this.mod.objPosJedSelected.KlijentIDpos
    };

    if (this.srvClanarine.objClanarinaLast) {
      clTmp.claVrijediOD = moment(this.srvClanarine.objClanarinaLast.claVrijediDO).add(1, 'days').utc(true);
      clTmp.claVrijediDO = moment(this.srvClanarine.objClanarinaLast.claVrijediDO).add(1, 'month').utc(true);
      clTmp.claIznos = this.srvClanarine.objClanarinaLast.claIznos;
      clTmp.claGrupaID = this.srvClanarine.objClanarinaLast.claGrupaID;

      if (clTmp.claVrijediOD.format('D') == '1') {
        let dLastTmp: string = moment(clTmp.claVrijediOD).endOf('month').format('DD');
        clTmp.claVrijediDO = moment(moment(clTmp.claVrijediOD.format('YYYY') + '-' + clTmp.claVrijediOD.format('MM') + '-' + dLastTmp)).utc(true);
      }

    }

    //Update cijene članarine (iz grupa)
    this.srvGrupe.grupaByGrpId(clTmp.claGrupaID, (rez: Grupa) => {
      console.log(rez);
      if (rez) {
        this.srvClanarine.frmClanarina.get('claIznos').setValue(rez.grpClanarina);
      }
    });

    this.srvClanarine.napraviFrmClanarina(clTmp, () => {

      /* this.srvClanarine.frmClanarina.get('claGrupaID').setValue(this.srvGrupe.objGrupe[0].grpID.toString());

      console.log(this.srvGrupe.objGrupe[0].grpID.toString());
      console.log(this.srvClanarine.frmClanarina.get('claGrupaID').value); */

    });

    document.getElementById('btnMdlClanarina').click();
  }

  //Postavi Datume calarina
  postaviDatume(dat: moment.Moment) {

  }

}
