import { Injectable } from '@angular/core';
import { ModulService } from './modul.service';
import { HttpClient } from '@angular/common/http';

export interface Dvorana {
  dvoID: number, 
  pjedIDdvo: number, 
  opisDvo: string, 
  napomenaDvo: string, 
  kapacitetDvo: number
}

@Injectable({
  providedIn: 'root'
})
export class DvoraneService {

  constructor(
    private mod: ModulService,
    private http: HttpClient
  ) { }

  //Dvorane by pos Jed ID
  dvoraneByPosJedId(posJedID: number, callback: Function) {

    let urlTmp: string = this.mod.webUrl + '/php/dvorane.php?token=' + this.mod.objKor.token + '&funk=dvoraneByPosJedId';

    let obj0 = { posJedID: posJedID, fingerprint: this.mod.fingerprint() };
    let obj: string = JSON.stringify(obj0);

    this.http.post(urlTmp, obj).subscribe((rez: Dvorana[]) => {
      callback(rez);
    });

  }

}
