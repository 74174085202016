<form *ngIf="srvStatKlijent.frmFilter" [formGroup]="srvStatKlijent.frmFilter" style="font-size: 1.2rem;">

    <!--Datum Od-->
    <mat-form-field style="width: 100%; text-align: center;">
        <input autocomplete="off" matInput formControlName="datOd" [matDatepicker]="picker1" placeholder="Datum od">
        <mat-error *ngIf="srvStatKlijent.frmFilter.get('datOd').errors?.required" style="font-size: 1rem;">Obavezan unos
        </mat-error>
        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
        <mat-datepicker touchUi #picker1></mat-datepicker>
    </mat-form-field>

    <!--Datum Do-->
    <mat-form-field style="width: 100%; text-align: center;">
        <input autocomplete="off" matInput formControlName="datDo" [matDatepicker]="picker2" placeholder="Datum do">
        <mat-error *ngIf="srvStatKlijent.frmFilter.get('datDo').errors?.required" style="font-size: 1rem;">Obavezan unos
        </mat-error>
        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
        <mat-datepicker touchUi #picker2></mat-datepicker>
    </mat-form-field>

    <!--Poslovne jedinice-->
    <!-- <mat-form-field class="w-100" style="text-align: center;">
        <mat-label>Poslovna jedinica</mat-label>
        <mat-select formControlName="pjedID">
            <mat-option *ngIf="(mod.session && mod.session.pjedIDcl == 0) || !mod.session" [value]="'0'">Sve poslovne jedinice</mat-option>
            <mat-option *ngFor="let x of srvTermini.objPosJed" [value]="x.PoslJedID">{{x.NazivPos}}</mat-option>
        </mat-select>
    </mat-form-field> -->

    <!--Grupe-->
    <!-- <mat-form-field class="w-100" style="text-align: center;">
        <mat-label>Grupa</mat-label>
        <mat-select formControlName="grpID">
            <mat-option *ngIf="(mod.session && mod.session.clanVrstaIDtren != 2) || !mod.session" [value]="'0'">Sve grupe</mat-option>
            <mat-option *ngFor="let x of srvGrupe.objGrupe" [value]="x.grpID">{{x.grpOpis}}</mat-option>
        </mat-select>
    </mat-form-field> -->

    <!--Submit-->
    <button type="submit" class="btn mybtn-cancel w-100" data-dismiss="modal" (click)="prikazi()">Prikaži</button>


</form>