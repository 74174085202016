import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Moment } from 'moment';
import { Clan, ClanoviService } from './clanovi.service';
import { Grupa, GrupeService } from './grupe.service';
import { ModulService, PosJedinice } from './modul.service';
import { FrmSend } from './validatori';

export interface Obavijest {
  ObavijestID: number,
  KorClanID: number,
  korVrstaID: number,
  TekstObv: string,
  VrijemeObv: Moment,
  del: boolean
}

export interface VezObavClan {
  vocID: number,
  vocObavID: number,
  vocClanID: number,
  vocDel: number,
  vocStatus: number
}

export interface Vobavijest extends Obavijest, VezObavClan {

}

export interface VobavRez {
  vocID: number, 
  vocStatus: number, 
  VrijemeObv: Moment, 
  TekstObv: string, 
  Ime: string, 
  Prezime: string, 
  Naziv: string
}

@Injectable({
  providedIn: 'root'
})
export class ObavijestiService {

  constructor(
    private frmBuilder: FormBuilder,
    public mod: ModulService,
    private srvClanovi: ClanoviService,
    private srvGrupe: GrupeService,
    private http: HttpClient
  ) { }

  objPosJed: PosJedinice[];
  objGrupe: Grupa[];
  objClanovi: Clan[];

  intVrsta: number; // 1 = primljene obavijesti, 2 = poslane obavijesti, 3 = primljene pozivnice, 4 = poslane pozivnice
  objVobavRez: VobavRez[];
  objSent: Obavijest[];

  frmSend: FormGroup;

  //Nova obavijest
  novaObavijest() {

    if (this.mod.session) {

      let klijentID: number = this.mod.session.KlijentIDtren;
      let ClanIDcl: number = this.mod.session.KoriniskIDtren;
      let vrstaID: number = this.mod.session.clanVrstaIDtren;

      this.srvClanovi.posjedByTrenOper(klijentID, ClanIDcl, vrstaID, (rez2: PosJedinice[]) => {
        this.objPosJed = rez2;
      }); 

    } else {
      this.objPosJed = this.mod.objPosJed;
    }

    //Učitavanje grupa
    this.srvGrupe.fillByKorisnikID((rez: Grupa[]) => {
      this.objGrupe = rez;
    });

    //Učitavanje članova - DEPRECATED
    /* this.srvClanovi.clanoviByKorisnikID((rez: Clan[]) => {
      this.objClanovi = rez;
    }); */

    this.napraviFrmSend();

  }

  //frmSend
  frmSendStep: number;
  napraviFrmSend() {

    this.frmSendStep = 1;
    let vClansID: number = 0;
    let clanID: number = 0;

    if (this.mod.session) {
      vClansID = this.mod.session.vClansID;
      clanID = this.mod.session.KoriniskIDtren;
    } else {
      vClansID = 0;
      clanID = 0;
    }

    this.frmSend = this.frmBuilder.group({
      chkID: [1],
      posJedID: [0],
      grpID: [0],
      klijentID: [this.mod.objPosJedSelected.KlijentIDpos],
      clanID: [clanID],
      vClansID: [vClansID],
      tekst: [, [Validators.required, Validators.maxLength(750)]]
    }, { validators: FrmSend });

  }

  //Nova obavijest - send
  novaObavijestSend(frmSend, callback) {

    let url: string = this.mod.webUrl + '/php/obavijesti-send.php?token=' + this.mod.objKor.token + '&funk=novaObavijestSend';
    frmSend['fingerprint'] = this.mod.fingerprint();
    let obj: string = JSON.stringify(frmSend);

    this.http.post(url, obj).subscribe((rez) => {
      callback(rez);
    });

  }

  //fill by Član ID
  fillByClanID(clID: number, callback) {

    let url: string = this.mod.webUrl + '/php/obavijesti.php?token=' + this.mod.objKor.token + '&funk=fillByClanID';
    let obj: string = JSON.stringify({ vocClanID: clID, fingerprint: this.mod.fingerprint() });

    this.http.post(url, obj).subscribe((rez: VobavRez[]) => {
      callback(rez);
    });

  }

  //fill by fitNet ID
  fillByKorIdPrivatni(korID: number, callback) {

    let url: string = this.mod.webUrl + '/php/obavijesti.php?token=' + this.mod.objKor.token + '&funk=fillByKorIdPrivatni';
    let obj: string = JSON.stringify({ korID: korID, fingerprint: this.mod.fingerprint() });

    this.http.post(url, obj).subscribe((rez: VobavRez[]) => {
      callback(rez);
    });

  }

  //fill by član ID - poslane (treneri i operateri)
  fillByClanIDsent(clID: number, callback) {

    let url: string = this.mod.webUrl + '/php/obavijesti.php?token=' + this.mod.objKor.token + '&funk=fillByClanIDsent';
    let obj: string = JSON.stringify({ vocClanID: clID, fingerprint: this.mod.fingerprint() });

    this.http.post(url, obj).subscribe((rez: VobavRez[]) => {
      callback(rez);
    });

  }

  //fill by korisnik ID privatni - sent
  fillByKorIdPrivSent(korID: number, callback) {

    let url: string = this.mod.webUrl + '/php/obavijesti.php?token=' + this.mod.objKor.token + '&funk=fillByKorIdPrivSent';
    let obj: string = JSON.stringify({ korID: korID, fingerprint: this.mod.fingerprint() });

    this.http.post(url, obj).subscribe((rez: VobavRez[]) => {
      callback(rez);
    });

  }

   //fill by korisnik ID poslovni - sent
   fillByKorIdPoslSent(korID: number, callback) {

    let url: string = this.mod.webUrl + '/php/obavijesti.php?token=' + this.mod.objKor.token + '&funk=fillByKorIdPoslSent';
    let obj: string = JSON.stringify({ korID: korID, fingerprint: this.mod.fingerprint() });

    this.http.post(url, obj).subscribe((rez: VobavRez[]) => {
      callback(rez);
    });

  }

  //Označi obavijest kao pročitanu
  oznProcitano(vocID: number, callback) {

    let url: string = this.mod.webUrl + '/php/obavijesti.php?token=' + this.mod.objKor.token + '&funk=oznProcitano';
    let obj: string = JSON.stringify({ vocID: vocID, fingerprint: this.mod.fingerprint() });

    this.http.post(url, obj).subscribe((rez: string) => {
      callback(rez);
    });

  }

  //Označi obavijest kao Npročitanu
  oznNeprocitano(vocID: number, callback) {

    let url: string = this.mod.webUrl + '/php/obavijesti.php?token=' + this.mod.objKor.token + '&funk=oznNeprocitano';
    let obj: string = JSON.stringify({ vocID: vocID, fingerprint: this.mod.fingerprint() });

    this.http.post(url, obj).subscribe((rez: string) => {
      callback(rez);
    });

  }

}
