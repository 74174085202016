<div class="container" style="padding: 0px; height: 100%;">

    <div class="row" style="margin: 0px !important; padding-top: 50px; height: 100%; max-height: 100%;">

        <!--Lijeva kolona-->
        <div class="d-none d-md-block col-md-4" style="margin: 0px !important; padding: 0px;">

            <!--Prijava člana-->
            <div class="row cardio">
                <!-- <div class="col btn-prijava-sm d-flex justify-content-center"
                    (click)="ucitajNeprijavljene(mod.objPosJedSelected.PoslJedID)">
                    <mat-icon class="lst-icon" style="vertical-align: middle; margin-right: 2px; color: white;">
                        exit_to_app</mat-icon>
                    <span style="padding-top: 2px; color: white; font-size: 1.5rem">Prijava člana</span>
                </div> -->
                <div class="col" style="padding: 10px 5px;">
                    <button class="btn mybtn-success w-100" style="font-size: 1.2rem;" (click)="ucitajNeprijavljene(mod.objPosJedSelected.PoslJedID)"><i class="material-icons"
                        style="text-align:center; vertical-align: middle; color: white; font-size: 2rem;">exit_to_app
                    </i>Prijava člana</button>
                </div>
            </div>

            <!--Poslovne jedinice-->
            <div class="card" *ngIf="mod.objPosJed" style="padding: 0px;">
                <app-pos-jedinice (odabranaPjed)="odabranaPosJed($event)"></app-pos-jedinice>
            </div>

            <!-- Aktivnosti u tijeku -->
            <div class="card" style="text-align: left; padding: 0;">

                <div>
                    <div style="padding: 10px 15px; color: #81af8f;">
                        {{objAktSad ? 'Aktivnosti u tijeku:' : 'Nema aktivnosti u tijeku'}}
                    </div>

                    <div class="row" *ngFor="let x of objAktSad"
                        style="padding: 5px 0px; margin: 0; border-bottom: solid 0.5px #e9dbdb;">

                        <div class="col-9">
                            <div>
                                <span style="font-size: 1.2rem; font-weight: bold;">{{x?.grpOpis}}</span>


                            </div>
                            <div>
                                {{x.PocetakAkt | amDateFormat:'HH:mm'}}h - {{x.KrajAkt |
                                amDateFormat:'HH:mm'}}h <span style="color: grey;">{{'još ' +
                                    srvClanovi.trajanjePosjetaParamsDo(x.KrajAkt)}}</span>
                            </div>
                            <div>
                                <span style="font-weight: bold;">
                                    {{x.opisDvo}}
                                </span>
                            </div>

                            <div class="row" *ngFor="let y of x.posJed" style="margin: 0;">
                                <div class="col" style="padding: 0;">
                                    {{y.NazivPos}} <span style="color: grey;">({{y.count |
                                        sklanjanjeClan}})</span>
                                </div>
                            </div>

                        </div>

                        <div class="col-3 d-flex justify-content-center" style="padding: 0 5px; cursor: pointer;"
                            (click)="clanoviLoginStart(x)">
                            <i class="material-icons" style="font-size: 4rem; color: #7fd3a9;">
                                people
                            </i>
                        </div>


                    </div>

                </div>

                <!-- Prikaži prisutne u grupama -->
                <div *ngIf="objAktSad" (click)="grupaChk()">
                    <div style="cursor: pointer;">
                        <div class="row">
                            <div class="col col-2 d-flex justify-content-center align-items-center"
                                style="font-size: 1.5rem;">
                                <div *ngIf="!chkGrupe" class="fa fa-square-o" style="align-items: center;"
                                    aria-hidden="true">
                                </div>
                                <div *ngIf="chkGrupe" class="fa fa-check-square-o" aria-hidden="true"></div>
                            </div>
                            <div class="col">
                                <div class="d-flex align-items-center" style="font-size: 1.2rem; padding: 5px;">
                                    Prikaži sve prisutne
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>

        <div class="col" style="padding: 0px; max-height: 100%;">

            <div class="d-flex flex-column" style="max-height: 100%; overflow-x: hidden;">

                <div>
                    <div class="d-block d-md-none" *ngIf="mod.objPosJedSelected" data-toggle="modal"
                        data-target="#modalPrisutni">
                        <!-- <i class="material-icons">menu</i>
                    <span><strong> {{mod.NazivPos}}</strong></span> -->
                        <app-pos-jed-hamburger (hamburgerClickEmitt)="openSidebar()"></app-pos-jed-hamburger>
                    </div>

                    <!--Prijava člana-->
                    <div class="row cardio d-flex d-md-none" style="background-color: white;">
                        <div class="col col-3 d-flex align-items-center justify-content-center" style="cursor: pointer;"
                            (click)="bolPretraga = !bolPretraga">
                            <i class="material-icons"
                                style="text-align:center; vertical-align: middle; font-size: 3rem;">search </i>
                        </div>
                        <!-- <div class="col btn-prijava-sm d-flex justify-content-center"
                            (click)="ucitajNeprijavljene(mod.objPosJedSelected.PoslJedID)">
                            <mat-icon class="lst-icon" style="vertical-align: middle; margin-right: 2px; color: white;">
                                exit_to_app</mat-icon>
                            <span style="padding-top: 2px; color: white; font-size: 1.5rem">Prijava člana</span>
                        </div> -->
                        <div class="col" style="padding: 0;">
                            <button class="btn mybtn-success w-100" style="font-size: 1.2rem;" (click)="ucitajNeprijavljene(mod.objPosJedSelected.PoslJedID)"><i class="material-icons"
                                style="text-align:center; vertical-align: middle; color: white; font-size: 2rem;">exit_to_app
                            </i>Prijava člana</button>
                        </div>
                    </div>

                    <div *ngIf="objPrisutni" class="row-pretraga">

                        <!--PRETRAGA-->
                        <div *ngIf="(bolPretraga && innerWidth < 768) || innerWidth > 767" class="row" style="margin: 0px;">
                            <!--Input-->
                            <div class="col w-100 input-slim" style="text-align: center; padding: 5px;">
                                <mat-form-field style="width: 100%;">
                                    <input autocomplete="off" name="pretraga" [(ngModel)]="strPretraga"
                                        (ngModelChange)="filtrirajClanove(strPretraga)" type="text" matInput
                                        placeholder="pretraga..">
                                    <button mat-button *ngIf="strPretraga" matSuffix aria-label="Clear"
                                        (click)="pretragaClear()">X</button>
                                    <button mat-button *ngIf="!strPretraga" matSuffix aria-label="search"
                                        (click)="pretragaClear()"><i class="material-icons"
                                            style="text-align:center; vertical-align: middle; font-size: 1.5rem;">search
                                        </i></button>
                                </mat-form-field>
    
                            </div>
    
                        </div>
    
                    </div>

                </div>

                <div class="flex-grow-1 cardio" style="overflow-y: scroll; margin-right: -15px; background-color: white;" [@listFadeIn]="fltPrisutni?.length">

                    <!-- <div class="cardio" style="background-color: white;" [@listFadeIn]="fltPrisutni?.length"> -->

                        <div class="row" *ngFor="let x of fltPrisutni"
                            style="text-align: center; margin: 0px; vertical-align: middle; padding-right: 20px;">
        
                            <div class="col-3 d-flex align-items-center justify-content-center">
                                <img style="border-radius: 50%; max-height: 90px;"
                                    src="{{'fitNet/assets/clanovi/' + x.clanID + '.png'}}"
                                    onerror="this.onerror=null; this.src='fitNet/assets/clanovi/0.png'"
                                    class="img-fluid p-slika" alt="{Slika nije dostupna}">
                            </div>
        
                            <div class="col-7" style="text-align: left; font-size: 1.2rem;">
                                <div>
                                    <span style="font-weight: bold;">{{x.Ime}} {{x.Prezime}}</span>
                                </div>
        
                                <div *ngIf="x.trenID == mod.session?.trenID" style="border: solid #9aff9a;">
        
                                </div>
        
                                <div>
                                    <span style="font-size: 0.9rem;">{{x.vrijemePrijava | amDateFormat: 'DD.MM.YYYY. HH:mm'}}
                                        h</span>
                                </div>
        
                                <span style="color: rgb(105, 160, 132);">{{trajanjePosjetaParams(x.vrijemePrijava)}}</span>
                                <span style="color: #669fbb;">
                                    {{x.grupa}}
                                </span>
                                <div>
                                    <span style="color: #669fbb;">
                                        {{x.opisDvo}}
                                    </span>
                                </div>
        
                                <div>
                                    <span *ngIf="(x.clanVrstaIDtren == 2 || x.clanVrstaIDtren == 3)" [ngStyle]="{'color': x.clanVrstaIDtren == 2 ? '#00309f' : 'green'}">
                                        {{x.clanVrstaIDtren == 2 ? 'Trener' : 'Operater'}}
                                    </span>
                                </div>
        
                            </div>
        
                            <div *ngIf="(x.clanVrstaIDtren == 0 || x.clanVrstaIDtren == 1) || mod.objKor.vrstaID == 2 || (x.trenID == mod.session?.trenID) ||(mod.session.clanVrstaIDtren == 3)"
                                class="col-2" style="cursor: pointer; align-content: center;"
                                (click)="postaviFrmOdjava(x.trenID, x.Ime + ' ' + x.Prezime, x.vrijemePrijava)"
                                data-toggle="modal" data-target="#modalOdjava">
                                <mat-icon class="lst-icon" style="vertical-align: middle; margin-right: 2px;">logout</mat-icon>
                                <br />
                                <span style="padding-top: 5px;">odjava</span>
                            </div>
        
                            <div *ngIf="((x.clanVrstaIDtren == 2 || x.clanVrstaIDtren == 3) && mod.objKor.vrstaID == 1 && x.trenID != mod.session?.trenID && mod.session.clanVrstaIDtren != 3)"
                                class="col-2 d-flex align-items-center justify-content-center" style="padding: 2px;">
                                <div style="font-size: 1.2rem; color: #52ab8f;">
                                    {{x.clanVrstaIDtren == 2 ? 'Trener' : 'Operater'}}
                                </div>
                            </div>
        
                            <div class="col-12" style="padding: 5px;">
                                <hr style="margin: 2px;" />
                            </div>
        
                        </div>
        
                        <div *ngIf="!objPrisutni" class="alert alert-success" style="text-align: center; margin: 10px;">
                            <span>Nema prisutnih članova</span>
                        </div>
        
                        <div *ngIf="objPrisutni && fltPrisutni?.length == 0" class="alert alert-success"
                            style="text-align: center; margin: 10px;">
                            <span>Nema stavki za prikaz</span>
                        </div>
        
                    <!-- </div> -->

                </div>

                
            </div>

        </div>

    </div>

</div>

<!--SIDEBAR-->
<button hidden="true" id="btnSidebar" data-toggle="modal" data-target="#modalPrisutni"></button>

<div class="modal left fade" id="modalPrisutni" tabindex="-1" role="dialog" aria-labelledby="modalPrisutniLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">

            <!-- <div class="modal-header d-flex justify-content-center bgs-dark" style="border: none;">
                Odabir poslovne jedinice
            </div> -->

            <div class="modal-body bgs-dark" style="padding: 0px;">

                <!--Kontrole-->
                <!-- <div class="row cardio" style="margin: 0px;"> -->

                <!--Prijva člana-->
                <!-- <div class="col col-btn" (click)="ucitajNeprijavljene(mod.pjedID)">
                        <i class="material-icons cp-icon" style="color: rgb(156, 240, 198);">
                            exit_to_app
                        </i>
                        <span style="font-size: 1.5rem;">Prijava člana</span>
                    </div> -->


                <!-- </div> -->

                <!-- <hr /> -->



                <!-- Aktivnosti u tijeku -->
                <div class="card" style="text-align: left; padding: 0;">

                    <div>
                        <div style="padding: 10px 15px; color: #81af8f;">
                            {{objAktSad ? 'Aktivnosti u tijeku:' : 'Nema aktivnosti u tijeku'}}
                        </div>

                        <div class="row" *ngFor="let x of objAktSad"
                            style="padding: 5px 0px; margin: 0; border-bottom: solid 0.5px #e9dbdb;">

                            <div class="col-9">
                                <div>
                                    <span style="font-size: 1.2rem; font-weight: bold;">{{x?.grpOpis}}</span>


                                </div>
                                <div>
                                    {{x.PocetakAkt | amDateFormat:'HH:mm'}}h - {{x.KrajAkt |
                                    amDateFormat:'HH:mm'}}h <span style="color: grey;">{{'još ' +
                                        srvClanovi.trajanjePosjetaParamsDo(x.KrajAkt)}}</span>
                                </div>
                                <div>
                                    <span style="font-weight: bold;">
                                        {{x.opisDvo}}
                                    </span>
                                </div>

                                <div class="row" *ngFor="let y of x.posJed" style="margin: 0;">
                                    <div class="col" style="padding: 0;">
                                        {{y.NazivPos}} <span style="color: grey;">({{y.count |
                                            sklanjanjeClan}})</span>
                                    </div>
                                </div>

                            </div>

                            <div class="col-3 d-flex justify-content-center" style="padding: 0 5px; cursor: pointer;"
                                (click)="clanoviLoginStart(x)">
                                <i class="material-icons" style="font-size: 4rem; color: #7fd3a9;">
                                    people
                                </i>
                            </div>


                        </div>

                    </div>

                    <!-- Prikaži prisutne u grupama -->
                    <div *ngIf="objAktSad" (click)="grupaChk()">
                        <div style="cursor: pointer;">
                            <div class="row">
                                <div class="col col-2 d-flex justify-content-center align-items-center"
                                    style="font-size: 1.5rem;">
                                    <div *ngIf="!chkGrupe" class="fa fa-square-o" style="align-items: center;"
                                        aria-hidden="true">
                                    </div>
                                    <div *ngIf="chkGrupe" class="fa fa-check-square-o" aria-hidden="true"></div>
                                </div>
                                <div class="col">
                                    <div class="d-flex align-items-center" style="font-size: 1.2rem; padding: 5px;">
                                        Prikaži sve prisutne
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                </div>

                <!--Poslovne jedinice-->
                <app-pos-jedinice (odabranaPjed)="odabranaPosJed($event)"></app-pos-jedinice>

            </div>
            <div class="modal-footer" style="padding: 5px;">
                <button type="button" id="btnSidebarClose" class="btn btn-secondary" style="width: 100%;"
                    data-dismiss="modal">Zatvori</button>
            </div>
        </div>
    </div>
</div>

<!--MODAL ODABIR ČLANA-->
<button id="btnModalOdabir" hidden="true" data-toggle="modal" data-target="#modalOdabir"></button>

<div class="modal fade" id="modalOdabir" tabindex="-1" role="dialog" aria-labelledby="modalOdabirLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content" style="border-radius: 10px;">

            <div class="modal-header d-block" style="padding: 10px 10px 0px;">
                <div class="d-flex align-items-center">
                    <h6>Prijava člana</h6>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <!--PRETRAGA-->
                <div class="row-pretraga2">

                    <div class="row" style="margin: 0px;">
                        <!--Input-->
                        <div class="col w-100 input-slim" style="text-align: center; padding: 0;">
                            <!-- <i class="material-icons"
                                style="text-align:center; vertical-align: middle; font-size: 2rem;">search</i> -->
                            <mat-form-field style="width: 100%;">
                                <input autocomplete="off" name="pretraga" [(ngModel)]="strPretraga2"
                                    (ngModelChange)="filtrirajNeprijavljene(strPretraga2)" matInput
                                    placeholder="pretraga..">
                                <button mat-button *ngIf="strPretraga2" matSuffix aria-label="Clear"
                                    (click)="pretragaLoginClear()">X</button>
                                    <button mat-button *ngIf="!strPretraga2" matSuffix aria-label="search"
                                        (click)="pretragaClear()"><i class="material-icons"
                                            style="text-align:center; vertical-align: middle; font-size: 1.5rem;">search
                                        </i></button>
                            </mat-form-field>

                        </div>

                    </div>

                </div>

            </div>

            <div class="modal-body" style="padding: 5px; scrollbar-width: none;">

                <form>

                    <div *ngFor="let x of fltNeprijavljeni" (click)="postaviFrmPrijava(x.clanID, x.Ime + ' ' + x.Prezime)" data-dismiss="modal" data-toggle="modal" data-target="#modalPrijava">

                        <div class="btn-lst-clanovi" *ngIf="mod.objKor.vrstaID == 2 || (mod.objKor.vrstaID == 1 && mod.session?.clanVrstaIDtren == 2 && x.vrstaIDcl == 1) || mod.session?.clanVrstaIDtren == 3">

                            <div class="row">
                                <div class="col d-flex" style="align-items: center;">
                                    <!-- <i class="material-icons"
                                        style="color: rgb(233, 233, 233); font-size: 1.5rem;">exit_to_app</i> -->
                                    <span style="color: rgb(85, 5, 5);">{{x.Ime}} {{x.Prezime}}</span>
                                </div>
                            </div>
    
                            <div style="padding: 0 15px; font-size: 1.2rem;">
                                {{x.Adresa + ' ' + (x.Mjesto ? ', ' + x.Mjesto : '')}}
                            </div>
    
                            <div *ngIf="x.vrstaIDcl == '2'" style="font-size: 1.1rem; color: blue; padding: 0px 15px;">
                                Trener
                            </div>
    
                            <div *ngIf="x.vrstaIDcl == '3'" style="font-size: 1.1rem; color: green; padding: 0px 15px;">
                                Operater
                            </div>

                        </div>

                    </div>

                    <div class="alert alert-success" *ngIf="fltNeprijavljeni?.length == 0">
                        Nema rezultata za prikaz
                    </div>

                </form>

            </div>

            <div class="modal-footer" style="padding: 2px;">
                <button id="btnOdabirDismiss" type="button" class="btn mybtn-cancel"
                    style="width: 100%; font-size: 1.2rem;" data-dismiss="modal">ZATVORI</button>
            </div>

        </div>
    </div>
</div>

<!--MODAL PRIJAVA ČLANA-->
<button id="btnModalPrijava" hidden="true" data-toggle="modal" data-target="#btnModalPrijava"></button>

<div class="modal fade" id="modalPrijava" tabindex="-1" role="dialog" aria-labelledby="modalPrijavaLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content" style="border-radius: 10px;">

            <div class="modal-header">
                <h5>Prijava člana</h5>
            </div>
            <div class="modal-body">

                <p *ngIf="frmPrijava" style="font-size: 1.3rem;">{{frmPrijava.controls['ime'].value}}</p>

                <form *ngIf="frmPrijava" [formGroup]="frmPrijava" style="font-size: 1.3rem; text-align: center;">

                    <div>
                        <div class="row">
                            <div class="col col-5" style="padding: 0;">
                                <!--Datepicker Datum OD-->
                                <mat-form-field style="width: 100%; text-align: center;">
                                    <mat-label>Datum</mat-label>
                                    <input matInput name="dat2" formControlName="priDat" [min]="minDate"
                                        [matDatepicker]="picker1" placeholder="Datum">
                                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                    <mat-datepicker touchUi #picker1></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div class="col" style="padding: 0;">
                                <mat-form-field style="width: 80px; text-align: center;">
                                    <mat-label>Sati</mat-label>
                                    <input matInput type="tel" formControlName="priH" placeholder="Sati" required>
                                </mat-form-field> :

                                <mat-form-field style="width: 80px; text-align: center;">
                                    <mat-label>Min.</mat-label>
                                    <input matInput type="tel" formControlName="priMin" placeholder="Min." required>
                                </mat-form-field>
                            </div>
                        </div>



                    </div>

                    <div>
                        <mat-form-field style="width: 100%;">
                            <mat-label>Dvorana</mat-label>
                            <mat-select formControlName="dvoranaIDtren">
                                <mat-option [value]="'0'">-</mat-option>
                                <mat-option *ngFor="let x of objDvorane" [value]="x.dvoID">
                                    {{x.opisDvo}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div>
                        <mat-error
                            *ngIf="frmPrijava.controls['priDat'].errors || frmPrijava.controls['priH'].errors || frmPrijava.controls['priMin'].errors">
                            Sva polja moraju biti ispravno popunjena</mat-error>
                    </div>

                </form>

            </div>
            <div class="modal-footer d-block" style="padding: 2px;">
                <div class="row" style="margin: 0;">
                    <div class="col col-6" style="padding: 2px;">
                        <button type="submit"
                            class="btn {{frmPrijava && frmPrijava.valid ? 'mybtn-success' : 'mybtn-danger'}}"
                            (click)="prijaviClana()" style="width: 100%; font-size: 1.2rem;">PRIJAVI</button>
                    </div>
                    <div class="col col-6" style="padding: 2px;">
                        <button id="btnPrijavaClose" type="button" class="btn mybtn-cancel"
                            style="width: 100%; font-size: 1.2rem;" data-dismiss="modal">ODUSTANI</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!--MODAL ODJAVA ČLANA-->
<div class="modal fade" id="modalOdjava" tabindex="-1" role="dialog" aria-labelledby="modalOdjavaLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content" style="border-radius: 10px;">

            <div class="modal-header">
                <h5>Odjava člana</h5>
            </div>
            <div class="modal-body">

                <p *ngIf="frmOdjava" style="font-size: 1.3rem;">{{frmOdjava.controls['ime'].value}}</p>
                <span style="margin-left: 10px; font-size: 1.1rem; color: brown;">Prijava:</span>
                <p *ngIf="frmOdjava" style="margin-left: 10px; font-size: 1.3rem;">
                    {{frmOdjava.controls['vrijemePrijava'].value | amDateFormat: 'DD.MM.YYYY. HH:mm'}}</p>

                <span style="margin-left: 10px; font-size: 1.1rem; color: brown;">Odjava:</span>

                <form *ngIf="frmOdjava" [formGroup]="frmOdjava" style="font-size: 1.3rem;">

                    <!--Datepicker Datum DO-->
                    <mat-form-field style="width: 170px; margin-right: 15px; text-align: center;">
                        <mat-label>Datum</mat-label>
                        <input matInput name="dat1" formControlName="odjDat" [min]="minDate" [matDatepicker]="picker"
                            placeholder="Datum">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker touchUi #picker></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field style="width: 60px; text-align: center;">
                        <mat-label>Sati</mat-label>
                        <input matInput type="tel" formControlName="odjH" placeholder="Sati" required>
                    </mat-form-field> :

                    <mat-form-field style="width: 60px; text-align: center;">
                        <mat-label>Min.</mat-label>
                        <input matInput type="tel" formControlName="odjMin" placeholder="Min." required>
                    </mat-form-field>

                    <mat-error
                        *ngIf="(frmOdjava.controls['odjDat'].errors || frmOdjava.controls['odjH'].errors || frmOdjava.controls['odjMin'].errors)">
                        Sva polja moraju biti ispravno popunjena</mat-error>
                    <mat-error
                        *ngIf="frmOdjava.errors?.veci && !frmOdjava.controls['odjDat'].errors && !frmOdjava.controls['odjH'].errors && !frmOdjava.controls['odjMin'].errors">
                        Vrijeme odjave ne može biti manje od vremena prijave
                    </mat-error>
                </form>

                <div *ngIf="frmOdjava && frmOdjava.valid && trajanjePosjeta()">
                    <span style="margin-left: 10px; font-size: 1.1rem; color: brown;">Trajanje: </span>
                    <p style="margin-left: 10px; color: green; font-size: 1.3rem;">{{trajanjePosjeta()}}</p>
                </div>

            </div>
            <div class="modal-footer d-block" style="padding: 2px;">
                <div class="row" style="margin: 0;">
                    <div class="col col-6" style="padding: 2px;">
                        <button type="submit"
                            class="btn {{frmOdjava && frmOdjava.valid ? 'mybtn-success' : 'mybtn-danger'}}"
                            (click)="odjaviClana(frmOdjava.controls['trenID'].value)"
                            style="width: 100%; font-size: 1.2rem;">ODJAVI</button>
                    </div>
                    <div class="col col-6" style="padding: 2px;">
                        <button id="btnOdjavaClose" type="button" class="btn mybtn-cancel"
                            style="width: 100%; font-size: 1.2rem;" data-dismiss="modal">ODUSTANI</button>
                    </div>
                </div>


            </div>
        </div>
    </div>
</div>

<!--Modal Lista članova za prijavu-->
<button hidden="true" id="btnMdlListaClPrijava" data-toggle="modal" data-target="#mdlListaClPrijava"></button>

<div class="modal fade" id="mdlListaClPrijava" tabindex="-1" role="dialog" aria-labelledby="mdlListaClPrijava"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content" style="border-radius: 10px;">

            <div class="modal-header d-block" style="font-size: 1.3rem; color: #1d4c6c; padding: 15px 15px 5px;">
                <div *ngIf="aktivnostTmp">
                    <i *ngIf="bolGrpGost" class="material-icons" style="cursor: pointer;"
                        (click)="bolGostSearch = !bolGostSearch">search</i> {{srvGrupe.objGrupa?.grpOpis + ' ' +
                    (aktivnostTmp.PocetakAkt | amDateFormat:'HH:mm') + ' - ' + (aktivnostTmp.KrajAkt
                    | amDateFormat:'HH:mm')}}
                </div>

                <!--PRETRAGA-->
                <div *ngIf="bolGostSearch" class="row" style="margin: 0px;">
                    <!--Input-->
                    <div class="col w-100" style="text-align: center; padding: 0;">
                        <mat-form-field style="width: 100%;">
                            <input autocomplete="off" type="search" name="pretraga"
                                [(ngModel)]="srvClanovi.strPretraga2"
                                (ngModelChange)="srvClanovi.filtrirajNeprijavljene(srvClanovi.strPretraga2)" matInput
                                placeholder="pretraga..">
                            <!-- <button mat-button *ngIf="srvClanovi.strPretraga2" matSuffix mat-icon-button aria-label="Clear" (click)="pretragaClear()">X</button> -->
                        </mat-form-field>
                    </div>
                </div>

            </div>

            <div class="modal-body" style="padding: 10px 7px; font-size: 1.5rem;">

                <!--članovi grupe-->
                <div *ngIf="!bolGrpGost">
                    <div class="row" *ngFor="let x of srvGrupe.objClanoviZaPrijavu; let i = index"
                        [ngClass]="{'cl-nepr': !x.trenID}" style="cursor: pointer;" (click)="clanChk(x, i)">
                        <div class="col col-2 d-flex justify-content-center align-items-center"
                            style="font-size: 2.5rem;">
                            <div *ngIf="!x.trenID" class="fa fa-square-o" style="align-items: center;"
                                aria-hidden="true">
                            </div>
                            <div *ngIf="x.trenID" class="fa fa-check-square-o" aria-hidden="true"></div>
                        </div>
                        <div class="col d-flex align-items-center">
                            <div>
                                {{x.Ime + ' ' + x.Prezime}}
                            </div>
                            <div class="d-flex align-items-center"
                                style="margin-left: 10px; color:#6ea1ff; font-size: 1.3rem;">
                                {{x.vcgID ? '' : ' - Gost'}}
                            </div>

                        </div>
                    </div>
                </div>

                <!--Gosti-->
                <div *ngIf="bolGrpGost">
                    <div *ngFor="let x of srvClanovi.fltNeprijavljeni" (click)="potvrdiGosta(x)"
                        style="cursor: pointer; border-bottom: solid 0.5px #dbdbdb;">
                        <div class="row" style="margin: 0; padding: 8px;">
                            <div class="col" style="padding: 2px;">
                                {{x.Ime}} {{x.Prezime}}
                            </div>
                        </div>
                    </div>
                </div>


            </div>

            <div class="modal-footer d-block" style="padding: 2px;">

                <button hidden id="btnMdlListaClPrijavaClose" data-dismiss="modal"></button>
                <div *ngIf="!bolGrpGost" class="row" style="margin: 0; padding: 0;">
                    <div class="col col-6" style="padding: 2px;">
                        <button type="button" class="btn btns-lo-grey w-100" style="font-size: 1.3rem;"
                            data-dismiss="modal">Zatvori</button>
                    </div>
                    <div class="col col-6" style="padding: 2px;">
                        <button type="button" class="btn btns-info w-100" style="font-size: 1.3rem;"
                            (click)="prijaviGosta(aktivnostTmp)">Svi članovi</button>
                    </div>
                </div>

                <div *ngIf="bolGrpGost" class="row" style="margin: 0; padding: 0;">
                    <!-- <div class="col col-6" style="padding: 2px;">
                        <button type="button" class="btn btns-info w-100" style="font-size: 1.3rem;"
                            (click)="prijaviGosta(aktivnostTmp)">Prijavi</button>
                    </div> -->
                    <div class="col" style="padding: 2px;">
                        <button type="button" class="btn btns-lo-grey w-100" style="font-size: 1.3rem;"
                            (click)="bolGrpGost = false; bolGostSearch = false; srvClanovi.strPretraga2 = ''">Otkaži</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>