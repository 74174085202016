import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { ClanarineService, VclanarinaGrupa } from '../clanarine.service';
import { ClanoviService } from '../clanovi.service';
import { Grupa, GrupeService } from '../grupe.service';

@Component({
  selector: 'app-clanarine-edit',
  templateUrl: './clanarine-edit.component.html',
  styleUrls: ['./clanarine-edit.component.scss']
})
export class ClanarineEditComponent implements OnInit {

  constructor(
    public srvClanarine: ClanarineService,
    public srvGrupe: GrupeService,
  ) { }

  ngOnInit(): void {

    

  }

  //checkbox plaćanje
  placanjeChk() {

    if (this.srvClanarine.frmClanarina.get('intPlacena').value == 0) {
      this.srvClanarine.frmClanarina.get('intPlacena').setValue(1);
      if (!this.srvClanarine.frmClanarina.get('claDatumUplate').value) {
        this.srvClanarine.frmClanarina.get('claDatumUplate').setValue(moment().toDate());
      }
    } else {
      this.srvClanarine.frmClanarina.get('intPlacena').setValue(0);
    }
  }

  //Odabir grupe
  odabirGrupe(event) {
    console.log(event);
    this.srvGrupe.objGrupe.forEach((val, ind) => {
      if (val.grpID == event) {
        this.srvClanarine.frmClanarina.get('claIznos').setValue(val.grpClanarina);
      }
    });
  }

}
