<div>

    <!-- Članarine -->
    <div class="card" style="padding: 5px;">

        <div *ngIf="srvClanEdit.objVazeceClanarine">

            <div style="color: #898989;">
                Članarina vrijedi do
            </div>

            <div *ngFor="let x of srvClanEdit.objVazeceClanarine" [ngStyle]="{'border-bottom': srvClanEdit.objVazeceClanarine.length > 1 ? 'solid 0.5px #cbc3c3' : 'none'}">
                <div class="d-flex justify-content-center"
                    style="font-size: 1.3rem; padding: 5px 0px; font-weight: bold;">
                    <div [ngStyle]="{'color': srvClanEdit.clrVazeca(x.claVrijediDO)}">
                        {{ (x.claVrijediDO | amDateFormat:'DD.MM.YYYY.')}}
                    </div>
                    <div style="margin-left: 15px;">
                        {{x.grpOpis ? '' : '   ' + x.claIznos + '€'}}
                    </div>
                </div>
                <div class="d-flex justify-content-center">
                    <div style="color: #00003f;">
                        {{x.grpOpis}}
                    </div>
                    <div>
                        
                    </div>
                </div>
            </div>

        </div>

        <div *ngIf="!srvClanEdit.objVazeceClanarine" class="alert alert-warning" style="margin: 0;">
            Nema važeću članarniu
        </div>

        <div style="margin-top: 5px;">
            <button type="button" class="btn mybtn-cancel w-100" routerLink="/clanarine">Pregled članarina</button>
        </div>

    </div>

    <!-- Grupe -->
    <div class="card" style="margin-top: 10px; padding: 5px;">

        <div *ngIf="this.srvClanEdit.objGrupe">
            <div style="color: #898989;">
                Grupe
            </div>
    
            <div *ngFor="let x of this.srvClanEdit.objGrupe">
                <div>
                    {{x.grpOpis}}
                </div>
            </div>
        </div>

        <div *ngIf="!this.srvClanEdit.objGrupe" class="alert alert-success" style="margin: 0;">
            Nema upisanih grupa
        </div>

        <div style="margin-top: 5px;">
            <button type="button" class="btn mybtn-success w-100">Upiši člana u grupu</button>
        </div>

    </div>

</div>