import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'sklanjanjeClan'})
export class SklanjanjeClan implements PipeTransform {
  transform(val: number): string {

    val = Number(val);
    
    let newStr: string;

    if (val == 0) {
        return '0 članova';
    } else if (val == 1) {
        return '1 član';
    } else if (val > 1 && val < 5) {
        return val + ' člana';
    } else {
        return val + ' članova';
    }

  }
}

@Pipe({name: 'sklanjanjeOsoba'})
export class SklanjanjeOsoba implements PipeTransform {
  transform(val: number): string {

    val = Number(val);
    
    let newStr: string;

    if (val == 0) {
        return '0 osoba';
    } else if (val == 1) {
        return '1 osoba';
    } else if (val > 1 && val < 5) {
        return val + ' osobe';
    } else {
        return val + ' osoba';
    }

  }
}

@Pipe({name: "shortName"})
export class ShortNamePipe implements PipeTransform {
  transform(fullName: string): any {
    return fullName
      .split(" ")
      .map(n => n[0])
      .join("");
  }
}
