<!-- msgBox objDialogInfo-->
<div *ngIf="mod.objDlgInfo" class="alert dlg-msg" style="z-index: 99999999; height: 70px;"
    [ngClass]="{'alert-success': mod.objDlgInfo.vrsta == 1, 'alert-danger': mod.objDlgInfo.vrsta == 2, 'alert-info': mod.objDlgInfo.vrsta == 3 }"
    @navMsg>
    <div class="d-flex justify-content-center align-items-center" style="height: 100%;">
        <div style="font-size: 1.3rem;">
            {{mod.objDlgInfo.text}}
        </div>
    </div>
</div>

<!-- <div style="height: 50px; margin-top: -50px; z-index: 1500; background-color: black;">

</div> -->


<div class="app-toolbar shadow ne-print w-100">

    <div class="container" style="padding: 0;">

        <!-- <div class="d-flex align-items-center">

            <button type="button" class="btn btns-lo-grey"><i class="material-icons" routerLink="/naslovnica">home</i> fitNet</button>

            <button type="button" class="btn btns-lo-grey ml-auto"><i class="material-icons">input</i> Prijava</button>
            <button type="button" class="btn btns-lo-grey ml-auto"><i class="material-icons">how_to_reg</i> Registracija</button>  

        </div> -->

        <div class="row" style="margin: 0px; padding: 0;">

            <div class="col col-3 d-flex home-link justify-content-center align-items-center"
                style="margin: 0 2px; padding: 4px 10px;" routerLink="/naslovnica">

                <span class="material-icons">home</span>
                <span style="margin-left: 3px;">fitNet</span>
            </div>

            <div *ngIf="mod.objKor?.vrstaID == 2 || mod.session" class="col home-link"
                style="padding: 2px 10px; margin-left: 10px; max-width: 100px;" (click)="qrScanStart()">
                <button type="button" class="btn mybtn w-100"><i class="fa fa-qrcode" aria-hidden="true"></i></button>
            </div>

            <!-- <div class="col-3 my-auto" routerLink="/naslovnica" style="cursor: pointer;">
                fitNet
            </div> -->

            <div class="col d-flex justify-content-center">
                <button hidden type="button" class="btn mybtn-canceld-flex"
                    style="font-size: 0.5rem; justify-content: center;" (click)="logInfo()"><i class="fa fa-refresh"
                        aria-hidden="true"></i></button>
            </div>

            <div class="col col-3 d-flex login-link justify-content-center align-items-center" style="margin: 0 2px;"
                (click)="loginMain()">

                <!-- LOGIN MODAL from .ts HIDDEN -->
                <button id="btnLogin" #btnLogin [hidden]="true" data-toggle="modal" data-target="#loginModal"></button>

                <span class="text-center {{mod.btnLoginClass}}">{{mod.btnLoginClass}}</span>


            </div>

            <div *ngIf="!mod.objKor" class="col col-4 d-flex login-link justify-content-center align-items-center"
                style="margin: 0 2px; padding: 0;" routerLink="/registracija">
                <span class="Registracija">Registracija</span>
            </div>


        </div>

    </div>

</div>

<div style="height: 100%;">
    <router-outlet style="padding-bottom: 330px;"></router-outlet>
</div>


<!--MODAL LOGIN-->
<div class="modal fade" tabindex="-1" role="dialog" id="loginModal">
    <div class="modal-dialog" role="document">
        <div class="modal-content" style="border-radius: 10px;">

            <div class="modal-header">
                <h5 class="modal-title">Prijava u aplikaciju</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body" style="padding: 10px 2px 2px 2px;">

                <form *ngIf="loginForm" [formGroup]="loginForm" style="padding: 0 5px">

                    <div class="row">
                        <div class="col d-flex justify-content-center" style="align-items: center; padding: 0;">
                            <!-- <i class="material-icons login-material" style="margin-right: 15px;">person</i> -->
                            <mat-form-field style="width: 100%;">
                                <mat-label>Korisničko ime</mat-label>
                                <input matInput type="email" formControlName="kor" placeholder="e-mail adresa" required>
                                <button mat-button matPrefix aria-label="Clear"><i class="material-icons login-material"
                                        style="margin-right: 15px;">person</i></button>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col d-flex justify-content-center" style="align-items: center; padding: 0;">
                            <!-- <i class="material-icons login-material" style="margin-right: 15px;">lock</i> -->
                            <mat-form-field style="width: 100%;">
                                <mat-label>Lozinka</mat-label>
                                <input matInput type="password" formControlName="pass" placeholder="minimalno 4 znaka"
                                    required>
                                <button mat-button matPrefix aria-label="Clear"><i class="material-icons login-material"
                                        style="margin-right: 15px;">lock</i></button>
                            </mat-form-field>
                        </div>
                    </div>


                    <mat-error *ngIf="loginForm && loginForm.errors?.loginFail" style="text-align: center;">
                        {{loginForm.errors.loginFail}}
                    </mat-error>



                    <div class="row w-100">
                        <div class="col" style="padding: 5px;">
                            <button type="submit" class="btn mybtn-success w-100" style="opacity: 0.9;"
                                (click)="loginChk()">
                                Potvrdi
                            </button>
                        </div>
                        <div class="col" style="padding: 5px;">
                            <div id="btnLoginModalClose" class="mybtn-cancel" style="opacity: 0.9;"
                                data-dismiss="modal">
                                Otkaži
                            </div>
                        </div>
                    </div>

                </form>
            </div>

            <div class="modal-footer login-mod-footer">

                <div>

                    <div class="row">
                        <!-- <div class="col-12" style="padding: 5px 15px 5px 15px;">
                            <div class="mybtn-cancel" style="background-color: red; color: white;" (click)="signInWithGoogle()"><i class="fa fa-google" style="margin-right: 5px;" aria-hidden="true"></i> google prijava</div>
                        </div> -->
                        <div class="col-12" style="padding: 15px 0 0;">
                            <app-google-signin (loginWithGoogle)="googleSignin($event)"></app-google-signin>
                            <!-- <div style="padding: 4px; text-align: center;">
                                opcija samo za privatne korisnike!
                            </div> -->
                        </div>
                        <!-- <div class="col-12" style="padding: 5px 15px 5px 15px;">
                            <div class="mybtn-cancel" style="background-color: rgb(17, 38, 160); color: white;" (click)="signInWithFB()"><i class="fa fa-facebook" style="margin-right: 5px;" aria-hidden="true"></i> facebook prijava</div>
                        </div> -->
                    </div>
                    <!-- <div class="mybtn-success" (click)="refreshToken()">token</div> -->
                </div>

            </div>
        </div>
    </div>
</div>

<!--MODAL REGISTRACIJA-->
<!--SKRIVENI GUMB-->
<button id="btnModal " #btnModal [hidden]="true " data-toggle="modal " data-target="#modalKorisnik "></button>

<div class="modal fade" id="modalKorisnik" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content" style="border-radius: 10px;">
            <div class="modal-header">
                <h5 class="modal-title" id="modalKorisnikLabel">Registracija</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <!-- <div *ngIf="mod.objKor " class="modal-body ">
                <app-korisnik></app-korisnik>
            </div> -->
            <div class="modal-footer">
                <button id="btnRegClose" type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary">Save changes</button>
            </div>
        </div>
    </div>
</div>

<!-- Modal odjava trener / operater-->
<button hidden id="btnMdlOdjavaTrenOper" data-toggle="modal" data-target="#mdlOdjavaTrenOper"></button>
<div class="modal fade" id="mdlOdjavaTrenOper" tabindex="-1" role="dialog" aria-labelledby="mdlOdjavaTrenOperLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">

            <div class="modal-header">
                <h5>Odjava</h5>
            </div>
            <div class="modal-body">

                <p *ngIf="srvClanovi.frmOdjava" style="font-size: 1.3rem;">
                    {{srvClanovi.frmOdjava.controls['ime'].value}}</p>
                <span style="margin-left: 10px; font-size: 1.1rem; color: brown;">Prijava:</span>
                <p *ngIf="srvClanovi.frmOdjava" style="margin-left: 10px; font-size: 1.3rem;">
                    {{srvClanovi.frmOdjava.controls['vrijemePrijava'].value | amDateFormat: 'DD.MM.YYYY. HH:mm'}}</p>

                <span style="margin-left: 10px; font-size: 1.1rem; color: brown;">Odjava:</span>

                <form *ngIf="srvClanovi.frmOdjava" [formGroup]="srvClanovi.frmOdjava" style="font-size: 1.3rem;">

                    <mat-form-field style="width: 170px; margin-right: 15px; text-align: center;">
                        <input matInput name="dat1" formControlName="odjDat" [matDatepicker]="picker"
                            placeholder="Datum">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker touchUi #picker></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field style="width: 60px; text-align: center;">
                        <mat-label>Sati</mat-label>
                        <input matInput type="tel" formControlName="odjH" placeholder="Sati" required>
                    </mat-form-field> :

                    <mat-form-field style="width: 60px; text-align: center;">
                        <mat-label>Min.</mat-label>
                        <input matInput type="tel" formControlName="odjMin" placeholder="Min." required>
                    </mat-form-field>

                    <mat-error
                        *ngIf="(srvClanovi.frmOdjava.controls['odjDat'].errors || srvClanovi.frmOdjava.controls['odjH'].errors || srvClanovi.frmOdjava.controls['odjMin'].errors)">
                        Sva polja moraju biti ispravno popunjena</mat-error>
                    <mat-error
                        *ngIf="srvClanovi.frmOdjava.errors?.veci && !srvClanovi.frmOdjava.controls['odjDat'].errors && !srvClanovi.frmOdjava.controls['odjH'].errors && !srvClanovi.frmOdjava.controls['odjMin'].errors">
                        Vrijeme odjave ne može biti manje od vremena prijave
                    </mat-error>
                </form>

                <div *ngIf="srvClanovi.frmOdjava && srvClanovi.frmOdjava.valid && srvClanovi.trajanjePosjeta()">
                    <span style="margin-left: 10px; font-size: 1.1rem; color: brown;">Trajanje: </span>
                    <p style="margin-left: 10px; color: green; font-size: 1.3rem;">{{srvClanovi.trajanjePosjeta()}}</p>
                </div>

            </div>

            <div class="modal-footer d-block" style="padding: 2px;">

                <div class="row" style="margin: 0;">
                    <div class="col col-6" style="padding: 2px;">
                        <button type="submit"
                            class="btn {{srvClanovi.frmOdjava && srvClanovi.frmOdjava.valid ? 'btn-outline-success' : 'btn-outline-danger'}}"
                            (click)="srvClanovi.odjavaTrenOperEnd()" style="width: 100%;">ODJAVI</button>
                    </div>
                    <div class="col col-6" style="padding: 2px;">
                        <button id="btnOdjavaClose" type="button" class="btn btn-secondary" style="width: 100%;"
                            data-dismiss="modal">ODUSTANI</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<!--MODAL lista članova - odabir člana za povezivanje-->
<button hidden id="btnMdlClZahtjev" data-toggle="modal" data-target="#mdlClZahtjev"></button>
<div class="modal fade" id="mdlClZahtjev" tabindex="-1" role="dialog" aria-labelledby="mdlClZahtjevLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content" style="border-radius: 10px;">
            <div class="modal-header">
                <h6 class="modal-title">Lista članova</h6>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body w-100" style="padding: 0;">

                <div *ngIf="this.srvClanovi.frmPovez && this.srvClanovi.frmPovez.get('clanIDvfc').value">
                    <div *ngIf="clTmp" style="padding: 10px;">
                        <div>
                            Potvrdite povezivanje s korisničkim računom:
                        </div>
                        <div style="font-weight: bold;">
                            {{clTmp.Ime + ' ' + clTmp.Prezime}}
                        </div>
                        <div>
                            {{clTmp.Adresa + ' ' + clTmp.Mjesto}}
                        </div>
                    </div>
                    <div class="row" style="margin: 0;">
                        <div class="col col6" style="padding: 2px 5px;">
                            <div class="btn mybtn-success w-100" (click)="poveziClanaEnd()">Potvrdi</div>
                        </div>
                        <div class="col col6" style="padding: 2px 5px;">
                            <div class="btn mybtn-cancel w-100"
                                (click)="this.srvClanovi.frmPovez.get('clanIDvfc').setValue(0); clTmp = null;">Otkaži
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="this.srvClanovi.frmPovez && !this.srvClanovi.frmPovez.get('clanIDvfc').value">
                    <div class="row row-cl-zaht" *ngFor="let x of srvClanovi.fltClanoviZahtjev"
                        (click)="poveziClanaStep2(x); clTmp = x;">
                        <div class="col col-2" style="text-align: center;">
                            <i class="fa fa-user-plus" aria-hidden="true"></i>
                        </div>
                        <div class="col col-10 d-flex">
                            <div>
                                {{x.Ime + ' ' + x.Prezime}}
                            </div>
                            <div *ngIf="x.fitNetID > 0" class="d-flex ml-auto align-items-center"
                                style="color: #7ed1b6; font-size: 0.9rem;">
                                fitNet
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="modal-footer d-block" style="padding: 5px;">
                <button id="btnMdlClZahtjevClose" [attr.hidden]="clTmp" type="button" class="btn mybtn-success w-100"
                    data-dismiss="modal">Zatvori</button>
            </div>
        </div>
    </div>
</div>

<!-- Modal - QR code scaner -->
<button hidden="true" id="btnMdlQrScan" class="btn btn-info" data-toggle="modal" data-target="#mdlQrScan">QR scanner
    start</button>

<div class="modal fade" id="mdlQrScan" tabindex="-1" role="dialog" aria-labelledby="Modal QR scanner"
    aria-hidden="true">
    <div id="mdlQrScan1" class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content" style="border-radius: 10px;">
            <div class="modal-header" style="padding: 10px;">
                <h6 class="modal-title" id="mdlQrScanLabel">QR kod prijava</h6>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            
            <div class="modal-body" style="text-align: center; padding: 5px;">

               <!-- <app-qr-scan></app-qr-scan> -->

            <div>
                <app-qr-scan-zxing></app-qr-scan-zxing>
            </div>

            </div>
            <div class="modal-footer d-block" style="padding: 5px;">
                <div class="row">
                    <!-- <div class="col" style="padding: 2px;">
                        <button type="button" class="btn mybtn-success w-100">SPREMI</button>
                    </div> -->
                    <div class="col" style="padding: 2px;">
                        <!-- <button id="btnMdlQrScanClose" (click)="action.stop()" type="button"
                            class="btn mybtn-cancel w-100" data-dismiss="modal">Zatvori</button> -->
                            <button id="btnMdlQrScanClose" (click)="mod.bolQrScaning = false" type="button"
                            class="btn mybtn-cancel w-100" data-dismiss="modal">Zatvori</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<!--MODAL PRIJAVA ČLANA QR code-->
<button id="btnMdlQrPrijava" hidden="true" data-toggle="modal" data-target="#mdlQrPrijava"></button>

<div class="modal fade" id="mdlQrPrijava" tabindex="-1" role="dialog" aria-labelledby="mdlQrPrijavaLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content" style="border-radius: 10px;">

            <div class="modal-header d-flex justify-content-between align-items-center" style="padding: 5px;">
                <div>
                    Prijava člana
                </div>
                <div style="margin-right: 15px; font-size: 2rem;">
                    <span class="badge rounded-pill text-dark" style="background-color: #e1e1e1;">{{srvClanovi.tjPosjeta}}</span>
                </div>

            </div>
            <div class="modal-body" style="padding: 5px;">

                <div>
                    <app-clan-prijava></app-clan-prijava>
                </div>
                

            </div>

            <div class="modal-footer d-block" style="padding: 0;">

                <div style="padding: 5px;">
                    <button id="btnMdlQrPrijavaClose" type="button" class="btn mybtn-cancel" style="width: 100%;"
                        data-dismiss="modal">ODUSTANI</button>
                </div>

            </div>
        </div>
    </div>
</div>

<!--MODAL ODJAVA ČLANA QR-->
<button id="btnMdlOdjavaQr" hidden="true" data-toggle="modal" data-target="#mdlOdjavaQr"></button>
<div class="modal fade" id="mdlOdjavaQr" tabindex="-1" role="dialog" aria-labelledby="mdlOdjavaQrLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content" style="border-radius: 10px;">

            <div class="modal-header">
                <h5>Odjava člana</h5>
            </div>
            <div class="modal-body">

                <app-clan-odjava></app-clan-odjava>

            </div>
            <div class="modal-footer d-block" style="padding: 2px;">
                <div class="row" style="margin: 0;">
                    <!-- <div class="col col-6" style="padding: 2px;">
                        <button type="submit"
                            class="btn {{srvClanovi.frmOdjava && srvClanovi.frmOdjava.valid ? 'mybtn-success' : 'mybtn-danger'}}"
                            (click)="odjaviClana(srvClanovi.frmOdjava.controls['trenID'].value)"
                            style="width: 100%; font-size: 1.2rem;">ODJAVI</button>
                    </div> -->
                    <div class="col" style="padding: 2px 16px;">
                        <button id="btnMdlOdjavaQrClose" type="button" class="btn mybtn-cancel"
                            style="width: 100%; font-size: 1.2rem;" data-dismiss="modal">ODUSTANI</button>
                    </div>
                </div>


            </div>
        </div>
    </div>
</div>