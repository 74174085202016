// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyC_0WEvbsSNzyWzd8KVIRpbj7CilxcPcqY",
    authDomain: "fitnet-2c155.firebaseapp.com",
    projectId: "fitnet-2c155",
    storageBucket: "fitnet-2c155.appspot.com",
    messagingSenderId: "409796958084",
    appId: "1:409796958084:web:a4d510380ee14ea452afd1",
    vapidKey: "BJZIhq83sX8IcM4BmoMztfm_Ss8BNj-IAPtmh-d3iW4dwIkggAzT_pMCnOPxSnm4LBi04Ovcr3a2U0YsCmZG5VM"
  },
};


