import { Component, OnInit, Output, EventEmitter, DoCheck } from '@angular/core';
import { Clan, ClanoviService, ZahtjevClanFitnet } from '../clanovi.service';
import { ModulService, PosJedinice } from '../modul.service';

@Component({
  selector: 'app-pos-jedinice',
  templateUrl: './pos-jedinice.component.html',
  styleUrls: ['./pos-jedinice.component.scss']
})
export class PosJediniceComponent implements OnInit {

  constructor(
    public mod: ModulService,
    public srvClanovi: ClanoviService
  ) { }

  @Output() odabranaPjed = new EventEmitter<PosJedinice>();

  ngOnInit() {

    /* this.mod.ucitajPosJed(() => {

    }); */

  }

  //btnPosJed click
  btnPosJedClick(obj: PosJedinice) {

    this.mod.objPosJedSelected = obj;
    //localStorage.objPosJedSelected = JSON.stringify(obj);

    this.odabranaPjed.emit(obj);

  }

  //Modal - lista članova / odabir člana za povezivanje
  poveziClana(x: ZahtjevClanFitnet) {

    this.srvClanovi.clanoviByKlID(this.mod.objKor.korisnikID, (rez: Clan[]) => {
      this.srvClanovi.objClanoviZahtjev = rez;
      this.srvClanovi.fltClanoviZahtjev = rez;
      this.srvClanovi.napraviFrmPovez(x.vfcID, 0, (rez) => {
        document.getElementById('btnMdlClZahtjev').click();
      })

    });

  }

}


