import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeHr from '@angular/common/locales/hr';
registerLocaleData(localeHr, 'en-HR');

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NaslovnicaComponent } from './naslovnica/naslovnica.component';
import { CpanelComponent } from './cpanel/cpanel.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatBadgeModule } from '@angular/material/badge';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule, MatListOption, MatSelectionList } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldControl, MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatNativeDateModule } from '@angular/material/core';
import { MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { DateAdapter } from '@angular/material/core';
import { MatMenuModule } from '@angular/material/menu';

import { KorisnikComponent } from './korisnik/korisnik.component';
import { AktivacijaComponent } from './aktivacija/aktivacija.component';
import { RegistracijaComponent } from './registracija/registracija.component';
import { ChartLineComponent } from './chart-line/chart-line.component';
import { ChartBarComponent } from './chart-bar/chart-bar.component';
import { QRCodeModule } from 'angularx-qrcode';
import { PrisutniComponent } from './prisutni/prisutni.component';
import { PosJediniceComponent } from './pos-jedinice/pos-jedinice.component';
import { ClanoviComponent } from './clanovi/clanovi.component';
import { ClanLoginComponent } from './clan-login/clan-login.component';
import { AppDateAdapter, APP_DATE_FORMATS } from './dateformats';
import { ClanEditComponent } from './clan-edit/clan-edit.component';
import { DatumShort, DatumMySql, DanNazivMySql } from './pipe-datum';
import { MomentModule } from 'ngx-moment';
import { ObavijestiPanelComponent } from './obavijesti-panel/obavijesti-panel.component';
import { GrupeComponent } from './grupe/grupe.component';
import { GrupaClanoviComponent } from './grupa-clanovi/grupa-clanovi.component';
import { KalendarComponent } from './kalendar/kalendar.component';
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { UploadComponent } from './upload/upload.component';
import { PosJedHamburgerComponent } from './pos-jed-hamburger/pos-jed-hamburger.component';
import { ClanstvaComponent } from './clanstva/clanstva.component';
import { ChartPieComponent } from './chart-pie/chart-pie.component';
import { GoogleChartsModule } from 'angular-google-charts';
import { ObavijestSendComponent } from './obavijest-send/obavijest-send.component';

import { ShortNamePipe, SklanjanjeClan, SklanjanjeOsoba } from './my-pipes';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ObavijestiSidebarComponent } from './obavijesti-sidebar/obavijesti-sidebar.component';
import { ClanarineComponent } from './clanarine/clanarine.component';
import { ClanarineEditComponent } from './clanarine-edit/clanarine-edit.component';
import { ClanarineSidebarComponent } from './clanarine-sidebar/clanarine-sidebar.component';

import { SocialLoginModule, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import { GoogleLoginProvider, FacebookLoginProvider } from '@abacritt/angularx-social-login';
import { PozivnicaComponent } from './pozivnica/pozivnica.component';
import { TerminiPregledComponent } from './termini-pregled/termini-pregled.component';
import { TerminiPregledSidebarComponent } from './termini-pregled-sidebar/termini-pregled-sidebar.component';
import { ClanarinePregledComponent } from './clanarine-pregled/clanarine-pregled.component';
import { ClanarinePregledSidebarComponent } from './clanarine-pregled-sidebar/clanarine-pregled-sidebar.component';
import { StatKlijentComponent } from './stat-klijent/stat-klijent.component';
import { StatKlPosjeteComponent } from './stat-kl-posjete/stat-kl-posjete.component';
import { StatKlijentSidebarComponent } from './stat-klijent-sidebar/stat-klijent-sidebar.component';

import { messaging } from "../configs/firebase.config";
import { GoogleSigninComponent } from "./google-signin/google-signin.component";
import { AktivnostEditComponent } from './aktivnost-edit/aktivnost-edit.component';
import { LOAD_WASM, NgxScannerQrcodeModule } from 'ngx-scanner-qrcode';
import { SafePipe } from './safe.pipe';
import { QrScanComponent } from './qr-scan/qr-scan.component';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { QrScanZxingComponent } from './qr-scan-zxing/qr-scan-zxing.component';
import { ClanPrijavaComponent } from './clan-prijava/clan-prijava.component';
import { ClanOdjavaComponent } from './clan-odjava/clan-odjava.component';
import { ClanEditSidebarComponent } from './clan-edit-sidebar/clan-edit-sidebar.component';

// Necessary to solve the problem of losing internet connection
LOAD_WASM().subscribe();

/* let config = new SocialAuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider("53342455670-k2mpb5lr8jip4q3glg7l68hq6dukpj6p.apps.googleusercontent.com")
  },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider("208548350497006")
  }
]);

export function provideConfig() {
  return config;
} */

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
    declarations: [
        AppComponent,
        NaslovnicaComponent,
        CpanelComponent,
        KorisnikComponent,
        AktivacijaComponent,
        RegistracijaComponent,
        ChartLineComponent,
        ChartBarComponent,
        PrisutniComponent,
        PosJediniceComponent,
        ClanoviComponent,
        ClanLoginComponent,
        ClanEditComponent,
        DatumShort,
        DatumMySql,
        ObavijestiPanelComponent,
        GrupeComponent,
        GrupaClanoviComponent,
        KalendarComponent,
        DanNazivMySql,
        UploadComponent,
        PosJedHamburgerComponent,
        ClanstvaComponent,
        ChartPieComponent,
        ObavijestSendComponent,
        SklanjanjeClan,
        SklanjanjeOsoba,
        ShortNamePipe,
        ObavijestiSidebarComponent,
        ClanarineComponent,
        ClanarineEditComponent,
        ClanarineSidebarComponent,
        PozivnicaComponent,
        TerminiPregledComponent,
        TerminiPregledSidebarComponent,
        ClanarinePregledComponent,
        ClanarinePregledSidebarComponent,
        StatKlijentComponent,
        StatKlPosjeteComponent,
        StatKlijentSidebarComponent,
        SafePipe,
        QrScanComponent,
        QrScanZxingComponent,
        ClanPrijavaComponent,
        ClanOdjavaComponent,
        ClanEditSidebarComponent
    ],
    providers: [
        { provide: LOCALE_ID, useValue: 'en-HR' },
        { provide: MAT_DATE_LOCALE, useValue: 'hr-HR' },
        { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
        /* { provide: AuthServiceConfig, useFactory: provideConfig} */
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                autoLogin: false,
                providers: [
                    {
                        id: GoogleLoginProvider.PROVIDER_ID,
                        provider: new GoogleLoginProvider('229743405443-6dnvpltv8es0rdo6a6gnhct4qtct2et1.apps.googleusercontent.com', {oneTapEnabled:false, prompt:'consent'})
                    },
                    {
                        id: FacebookLoginProvider.PROVIDER_ID,
                        provider: new FacebookLoginProvider('208548350497006')
                    }
                ]
            } as SocialAuthServiceConfig,
        },
        { provide: 'messaging', useValue: messaging }
    ],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        MatSidenavModule,
        MatIconModule,
        MatFormFieldModule,
        MatInputModule,
        MatCardModule,
        MatButtonModule,
        MatAutocompleteModule,
        MatBadgeModule,
        MatSelectModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatSnackBarModule,
        MatTableModule,
        MatMenuModule,
        QRCodeModule,
        MomentModule,
        MatMomentDateModule,
        GoogleChartsModule,
        MatRadioModule,
        MatListOption,
        MatSelectionList,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the app is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        }),
        SocialLoginModule,
        GoogleSigninComponent,
        AktivnostEditComponent,
        NgxScannerQrcodeModule,
        ZXingScannerModule
    ]
})
export class AppModule { }
