<form *ngIf="srvPozivnice.frmPozivnica" [formGroup]="srvPozivnice.frmPozivnica">

    <mat-form-field class="w-100">
        <mat-label>Primatelji</mat-label>
        <mat-select formControlName="chkID">
            <mat-option (click)="cmbCheck(1)" [value]="1">Svi članovi</mat-option>
            <mat-option (click)="cmbCheck(2)" [value]="2">Određena poslovna jedinica</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="srvPozivnice.frmPozivnica.get('chkID').value == 2" class="w-100">
        <mat-label>Odaberite poslovnu jedinicu</mat-label>
        <mat-select formControlName="posJedID">
            <mat-option *ngFor="let x of objPosJed" (click)="cmbCheck(x.PoslJedID)" [value]="1">{{x.NazivPos}}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field>
        <input matInput formControlName="VrijediDo" [matDatepicker]="picker"
            placeholder="Poziv vrijedi do">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker touchUi #picker></mat-datepicker>
    </mat-form-field>

    <mat-form-field class="w-100">
        <textarea matInput type="text" formControlName="TekstPoz" placeholder="tekst poruke - max 750 znakova"
            required> </textarea>
        <!-- <mat-error *ngIf="srvObavijesti.frmSend.controls['TekstPoz'].errors?.required">Niste upisali tekst poruke
        </mat-error>
        <mat-error *ngIf="srvObavijesti.frmSend.controls['TekstPoz'].errors?.maxlength">Duljina poruke je maksimalno 750 znakova
        </mat-error> -->
    </mat-form-field>

    <mat-form-field class="w-100">
        <input matInput type="tel" formControlName="limit" placeholder="Maksimalni broj polaznika">
        <!-- <mat-error *ngIf="srvObavijesti.frmSend.controls['TekstPoz'].errors?.required">Niste upisali tekst poruke
        </mat-error>
        <mat-error *ngIf="srvObavijesti.frmSend.controls['TekstPoz'].errors?.maxlength">Duljina poruke je maksimalno 750 znakova
        </mat-error> -->
    </mat-form-field>

</form>