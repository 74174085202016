<div class="container" style="padding: 0px;">

    <div class="row" style="margin-top:10px; opacity:0.8; padding-top: 40px;">

        <!-- Što je fitNET-->
        <div class="card col-md-4" style="padding-top:4px">
            <img class="card-img-top" src="fitNet/assets/main7.jpg" alt="Card image cap">
            <div class="card-img-overlay text-light">
                <h1 class="card-title">fitNET</h1>
            </div>
            <div class="card-body">
                <h5 class="card-title">Što je fitNET?!</h5>
                <p class="card-text text-justify">
                    fitNET je mreža namijenjena fitness ili sličnim centrima pomoću koje možete jednostavno, bilo kada i s bilo kojeg mjesta pratiti stanje u centru (evidencije članova / članarina, jednostavna autorizacija članova putem RF čip kartice ili mobilne aplikacije,
                    trenutni broj posjetitelja, izdavanje opreme...).
                </p>
                <a href="#" class="btn btn-outline-dark">Više...</a>
            </div>
        </div>

        <!-- Poslovni korisnici -->
        <div class="col-md-4 card" style="padding-top:4px">
            <img class="card-img-top" src="fitNet/assets/main6.jpg" alt="Card image cap">
            <div class="card-img-overlay text-light">
                <h1 class="card-title">fitNET</h1>
            </div>
            <div class="card-body">
                <h5 class="card-title">Poslovni korisnici</h5>
                <p class="card-text text-justify">
                    fitNET je mreža namijenjena fitness ili sličnim centrima pomoću koje možete jednostavno, bilo kada i s bilo kojeg mjesta pratiti stanje u centru (evidencije članova / članarina, jednostavna autorizacija članova putem RF čip kartice ili mobilne aplikacije,
                    trenutni broj posjetitelja, izdavanje opreme...).
                </p>
                <a href="#" class="btn btn-outline-dark">Više...</a>
            </div>
        </div>

        <!-- Privatni korisnici -->
        <div class="col-md-4 card" style="padding-top:4px">
            <img class="card-img-top" src="fitNet/assets/main5.jpg" alt="Card image cap">
            <div class="card-img-overlay text-light">
                <h1 class="card-title">fitNET</h1>
            </div>
            <div class="card-body">
                <h5 class="card-title">Privatni korisnici</h5>
                <p class="card-text text-justify">
                    fitNET je mreža namijenjena fitness ili sličnim centrima pomoću koje možete jednostavno, bilo kada i s bilo kojeg mjesta pratiti stanje u centru (evidencije članova / članarina, jednostavna autorizacija članova putem RF čip kartice ili mobilne aplikacije,
                    trenutni broj posjetitelja, izdavanje opreme...).
                </p>
                <a href="#" class="btn btn-outline-dark">Više...</a>
            </div>
        </div>

    </div>


</div>

<button hidden="true" id="btnPoslano" class="btn btn-info" data-toggle="modal" data-target="#modalPoslanEmail">Modal poslan e-mail</button>

<!--MODAL POSLAN e-mail-->
<div class="modal fade" id="modalPoslanEmail" tabindex="-1" role="dialog" aria-labelledby="Modal Poslan email" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content" style="border-radius: 10px;">
            <div class="modal-header">
                <h6 class="modal-title" id="modalPoslanEmailLabel">Provjerite e-mail!</h6>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
            </div>
            <div class="modal-body">
                <p>
                    Na e-mail adresu {{mod.objLogin.kor}} vam je poslan link za aktivaciju računa. Nakon što aktivirate račun možete početi s korištenjem aplikacije!
                </p>
                <span>Ugodan rad Vam želimo!</span>
            </div>
            <div class="modal-footer">
                <button id="btnPolsanMailClose" type="button" class="btn btn-outline-success btn-sm btn-block" data-dismiss="modal">Zatvori</button>
            </div>
        </div>
    </div>
</div>