<div>
    <div *ngIf="mod.session || mod.objKor.vrstaID == 2">
        <button type="button" class="btn mybtn-success" style="width: 100%;" (click)="novaObavijestStart()">Nova obavijest</button>
        <hr>
    </div>
    
    <div *ngIf="mod.objKor && mod.objKor.vrstaID == 1" style="padding: 2px 0;">
        <button type="button" class="btn mybtn-cancel" style="width: 100%;" (click)="primljene()"><i *ngIf="srvObavijesti.intVrsta == 1" class="fa fa-circle" style="font-size: 1.2rem; color: #00cd00; margin-right: 5px;" aria-hidden="true"></i>Primljene</button>
    </div>
    <div style="padding: 2px 0;">
        <button type="button" class="btn mybtn-cancel" style="width: 100%;" (click)="poslane()"><i *ngIf="srvObavijesti.intVrsta == 2" class="fa fa-circle" style="font-size: 1.2rem; color: #00cd00; margin-right: 5px;" aria-hidden="true"></i>Poslane</button>
    </div>
    <hr>
    <div>
        <button type="button" class="btn mybtn-cancel" style="width: 100%;" (click)="novaPozivnica()"><i *ngIf="srvObavijesti.intVrsta == 3" class="fa fa-circle" style="font-size: 1.2rem; color: #00cd00; margin-right: 5px;" aria-hidden="true"></i>Pozivnice u grupu</button>
    </div>
</div>