import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { GrupeService, Grupa } from '../grupe.service';
import { ClanoviService, Clan } from '../clanovi.service';
import { ModulService, PosJedinice } from '../modul.service';
import { ObavijestiService } from '../obavijesti.service';

@Component({
  selector: 'app-obavijest-send',
  templateUrl: './obavijest-send.component.html',
  styleUrls: ['./obavijest-send.component.css']
})
export class ObavijestSendComponent implements OnInit {

  constructor(
    public srvObavijesti: ObavijestiService,
    public mod: ModulService
  ) { }

  ngOnInit(): void {

    

  }

  //Sljedeći korak
  nastavi() {

    this.srvObavijesti.frmSendStep = 2;

  }

  //Pošalji poruku
  posalji(){
  
    this.srvObavijesti.novaObavijestSend(this.srvObavijesti.frmSend.value, (rez) => {
      if (rez == 'ok') {
        document.getElementById('btnMdlNovaObavijestClose').click();
        this.mod.msgBox(1, "Obavijest je uspješno poslana!", 3000);
      } else {
        this.mod.msgBox(2, "Greška prilikom slanja! Pokušajte ponovno!", 4000);
      } 
    });

  }

  grpChange() {
    
  }

}
