import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModulService, PosJedinice } from './modul.service';

@Injectable({
  providedIn: 'root'
})
export class PosJediniceService {

  constructor(
    private mod: ModulService,
    private router: Router,
    public frmBuilder: FormBuilder
  ) { }

  frmPosJed: FormGroup

  //napravi frmPosJed
  napraviFrmPosJed(val: PosJedinice, callback) {

    this.frmPosJed = this.frmBuilder.group({
      PoslJedID: [0],
      KlijentIDpos: [0],
      NazivPos: [],
      AdresaPos: [],
      MjestoIDpos: [0],
      Mjesto: [],
      kapacitet: [5, [Validators.min(1), Validators.max(1000)]],
      aktivna: [0]
    });

    if (val) {
      this.frmPosJed.patchValue(val);
    }

    callback(this.frmPosJed.value);

  }

}
